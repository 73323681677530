import { userType, UserSleepType } from "../../DTO";
import { Api } from "../api";

export const UserSleep = (
    user: userType,
    userId: number,
    WeekNumber: number,
) => {
    return new Promise<{ sleep: UserSleepType[] }>((resolve, reject) => {
        const api = new Api({
            path: `elementor/progress/sleep/user/${userId}/week/${WeekNumber}`,
            method: "get",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    sleep: convertToUserSleepTypeList(data.data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};

export const convertToUserSleepTypeList = (data: any[]): UserSleepType[] => {
    return data.map((element) => convertToUserSleepType(element));
};

export const convertToUserSleepType = (data: any): UserSleepType => {
    let out: UserSleepType = {
        id: data?.id,
        startDate: data?.startDate,
        endDate: data?.endDate,
        timezoneOffset: data?.timezone_offset,
    };

    return out;
};
