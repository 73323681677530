import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

import { AppUserType, ProgressReportType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { AppUserApi } from "../../api";
import { useAppLocation } from "../../Router/hook";

export const UserDetail: FC<{
    pageId: number;
    progressReport?: ProgressReportType;
}> = ({ pageId, progressReport }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [appUser, setAppUser] = useState<AppUserType>();

    const user = useAppSelector(selectUser);

    const { pathname } = useAppLocation();

    const fetchSingleUser = () => {
        setLoader(true);

        AppUserApi.ShowAppUser(user, pageId)
            .then((data) => {
                setAppUser(data.user);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleUser();
    }, []);

    return (
        <>
            {loader ? (
                <div className="w-100 p-5 d-flex flex-row justify-content-center align-items-center">
                    <Spinner animation="border" />
                </div>
            ) : pathname.includes("detail") && appUser ? (
                <div className="rounded col-12 col-md-6 col-lg-4 p-3 shadow bg-custom-white d-flex flex-column position-relative">
                    <FontAwesomeIcon
                        className="mx-2 userImageSiteLayout"
                        size="5x"
                        icon={faUserCircle}
                    />
                    <div className={"d-flex flex-row gap-2"}>
                        <span>Full Name:</span>
                        <strong>
                            {appUser.firstName && appUser.lastName
                                ? `${appUser.firstName} ${appUser.lastName}`
                                : "-"}
                        </strong>
                    </div>
                    <div className={"d-flex flex-row gap-2"}>
                        <span>Email:</span>
                        <strong>{appUser.email}</strong>
                    </div>
                    <div className={"d-flex flex-row gap-2"}>
                        <span>Phone:</span>
                        <strong>{appUser.phone ? appUser.phone : "-"}</strong>
                    </div>
                    <div className={"d-flex flex-row gap-2"}>
                        <span>Register Date:</span>
                        <strong>{appUser.emailVerifiedAt?.slice(0, 10)}</strong>
                    </div>
                </div>
            ) : (pathname.includes("report") ||
                  pathname.includes("overview")) &&
              appUser ? (
                <div className="p-3 w-100 d-flex flex-column position-relative">
                    <div className={"d-flex flex-row flex-wrap w-100"}>
                        <div
                            className={
                                "d-flex flex-row gap-2 col-12 col-md-6 col-lg-3 p-2"
                            }
                        >
                            <span>Full Name:</span>
                            <strong>
                                {appUser.firstName && appUser.lastName
                                    ? `${appUser.firstName} ${appUser.lastName}`
                                    : "-"}
                            </strong>
                        </div>
                        <div
                            className={
                                "d-flex flex-row gap-2 col-12 col-md-6 col-lg-3 p-2"
                            }
                        >
                            <span>Email:</span>
                            <strong>{appUser.email}</strong>
                        </div>
                        <div
                            className={
                                "d-flex flex-row gap-2 col-12 col-md-6 col-lg-3 p-2"
                            }
                        >
                            <span>Phone:</span>
                            <strong>
                                {appUser.phone ? appUser.phone : "-"}
                            </strong>
                        </div>
                        {pathname.includes("report") && (
                            <div
                                className={
                                    "d-flex flex-row gap-2 col-12 col-md-6 col-lg-3 p-2"
                                }
                            >
                                <span>Current Week:</span>
                                <strong>
                                    {progressReport?.currentWeek} UTC
                                </strong>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
