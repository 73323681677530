import { userType } from "../../DTO";
import { chatListTypeSingle } from "../../DTO/chat";
import { Api } from "../api";

export const FetchChatList = (user: userType) => {
    return new Promise<{ data: chatListTypeSingle[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "chat/list",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export function convertToChatListType(item: any): chatListTypeSingle {
    const returnUser = {};
    return item;
}
