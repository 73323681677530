import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    Loading,
    SendNotification,
    UserDetail,
    WeekList,
} from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { ProgressDetail } from "../../../DTO";
import { AppUserApi } from "../../../api";
import "./style.scss";
import { selectWeek } from "../../../redux-config/entities/week";

export const UserProgressDetail: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [progressDetail, setProgressDetail] = useState<ProgressDetail[]>([]);

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const weekSelected = useAppSelector(selectWeek);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user" },
        { name: "Detail " + id, href: "#" },
    ];

    const fetchProgressDetail = () => {
        setLoading(true);
        AppUserApi.UserDetail(user, Number(id), weekSelected.index)
            .then((data) => {
                setProgressDetail(data.detail);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        if (weekSelected && weekSelected.index > 0) {
            fetchProgressDetail();
        }
    }, [weekSelected]);

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>D2Type | Progress Detail</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">Progress Detail Table</h4>
                        <div className="d-flex flex-row flex-wrap gap-3">
                            <WeekList pageId={Number(id)} />
                            <SendNotification
                                fetchData={fetchProgressDetail}
                                user={user}
                                userId={Number(id)}
                            />
                        </div>
                    </div>
                    <div className={"d-flex flex-row flex-wrap w-100 my-2"}>
                        <UserDetail pageId={Number(id)} />
                    </div>
                    <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Label</th>
                                    <th>Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {progressDetail.length > 0 &&
                                    progressDetail.map((item, index) => (
                                        <tr
                                            key={item.id}
                                            className="align-middle"
                                        >
                                            <td>{index + 1}</td>
                                            <td>{item.label}</td>
                                            <td>{item.type}</td>
                                            <td>
                                                <button
                                                    disabled={
                                                        item.isEnough === false
                                                    }
                                                    className="text-white btn btn-sm btn-success"
                                                    onClick={() => {
                                                        navigate(
                                                            `/app-user/${Number(
                                                                id,
                                                            )}/progress/report?type=${
                                                                item.type
                                                            }`,
                                                        );
                                                    }}
                                                >
                                                    Report
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
