import {
    AppUserType,
    PaginationType,
    UserDescriptionAnswer,
    UserDescriptionQuestionType,
    UserFormAnswerType,
    UserFormDescriptionItemsType,
    UserFormDescriptionType,
    UserPlanOrderType,
    userType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertAdminUser } from "../admin-user";

export const FetchUserAppList = (
    user: userType,
    page: number,
    filterFirstName?: string,
    filterLastName?: string,
    filterEmail?: string,
    filterStatus?: "active" | "inactive",
    filterState?: string,
) => {
    return new Promise<{ user: AppUserType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "users",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page,
                    "filter[first_name]": filterFirstName,
                    "filter[last_name]": filterLastName,
                    "filter[email]": filterEmail,
                    "filter[status]": filterStatus,
                    "filter[register_state]": filterState,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        user: convertAppUserList(data.data),
                        pagination: convertToPaginationType(data.meta),
                    });
                })
                .catch((err) => {
                    return reject(err);
                });
        },
    );
};

export const convertAppUserList = (data: any[]): AppUserType[] => {
    return data.map((element) => convertAppUser(element));
};

export const convertAppUser = (data: any): AppUserType => {
    let out: AppUserType = {
        adminAccept: data?.admin_accept,
        id: data?.id,
        email: data?.email,
        firstName: data?.first_name,
        lastName: data?.last_name,
        phone: data?.phone,
        insuranceMemberId: data?.insurance_member_id,
        birthday: data?.birthday,
        city: data?.city,
        diseaseType: data?.disease_type,
        username: data?.username,
        postalCode: data?.postal_code,
        registerState: data?.register_state,
        state: data?.state,
        userInformation: data?.user_information && data?.user_information,
        avatar: data?.avatar,
        status: data?.status,
        phoneVerifiedAt: data?.phone_verified_at,
        emailVerifiedAt: data?.email_verified_at,
        formAnswers:
            data?.form_answers && convertFormAnswerList(data?.form_answers),
        planeOrder: data?.plan_order && convertUserPlaneOrder(data?.plan_order),
        coachId: data?.coachId && data?.coachId,
        createdAt: data?.created_at && data?.created_at,
        deletedAt: data?.deleted_at && data?.deleted_at,
        emailSent: data?.email_sent && data?.email_sent,
        fcmToken: data?.fcm_token && data?.fcmToken,
        identityCode: data?.identity_code && data?.identity_code,
        lastLogin: data?.last_login && data?.last_login,
        mongoUuid: data?.mongo_uuid && data?.mongo_uuid,
        name: data?.name && data?.name,
        peerGroup: data?.peerGroup && data?.peerGroup,
        peerGroupId: data?.peer_group_id && data?.peer_group_id,
        point: data?.point && data?.point,
        type: data?.type && data?.type,
        updatedAt: data?.updated_at && data?.updated_at,
        unit: data?.unit,
        coach: data?.coach ? convertAdminUser(data?.coach) : undefined,
    };

    return out;
};

export const convertFormAnswerList = (data: any[]): UserFormAnswerType[] => {
    return data.map((element) => convertFormAnswer(element));
};

export const convertFormAnswer = (data: any): UserFormAnswerType => {
    let out: UserFormAnswerType = {
        id: data?._id,
        description: convertDescriptionList(data?.description),
        createdAt: data?.created_at,
        type: data?.type,
        updatedAt: data?.updated_at,
        userId: data?.user_id,
    };

    return out;
};

export const convertDescriptionList = (
    data: any[],
): UserFormDescriptionType[] => {
    return data.map((element) => convertDescription(element));
};

export const convertDescription = (data: any): UserFormDescriptionType => {
    let out: UserFormDescriptionType = {
        id: data?.id ? data?.id : null,
        items: data?.items ? convertDescriptionItemList(data?.items) : null,
    };

    return out;
};

export const convertDescriptionItemList = (
    data: any[],
): UserFormDescriptionItemsType[] => {
    return data.map((element) => convertDescriptionItem(element));
};

export const convertDescriptionItem = (
    data: any,
): UserFormDescriptionItemsType => {
    let out: UserFormDescriptionItemsType = {
        id: data?.id,
        value: data?.value,
        unit: data?.unit,
    };

    return out;
};

export const convertDescriptionQuestion = (
    data: any,
): UserDescriptionQuestionType => {
    let out: UserDescriptionQuestionType = {
        id: data?.id,
        title: data?.title,
    };

    return out;
};

export const convertDescriptionAnswerList = (
    data: any[],
): UserDescriptionAnswer[] => {
    return data.map((element) => convertDescriptionAnswer(element));
};

export const convertDescriptionAnswer = (data: any): UserDescriptionAnswer => {
    let out: UserDescriptionAnswer = {
        title: data?.title,
        id: data?.id ? data?.id : undefined,
    };

    return out;
};

export const convertUserPlaneOrder = (data: any): UserPlanOrderType => {
    let out: UserPlanOrderType = {
        createdAt: data?.created_at,
        deletedAt: data?.deleted_at,
        expiredAt: data?.expired_at,
        id: data?.id,
        peerGroupId: data?.peer_group_id,
        period: data?.period,
        planId: data?.plan_id,
        point: data?.point,
        price: data?.price,
        startedAt: data?.started_at,
        status: data?.status,
        updatedAt: data?.updated_at,
        userId: data?.user_id,
    };

    return out;
};
