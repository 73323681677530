import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";

import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { PeerGroupApi } from "../../../api";
import { Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { DataPostType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import Swal from "sweetalert2";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import "./style.scss";
import { NotFound } from "../../../assets/images";
export const PostShow: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [messageLoading, setMessageLoading] = useState<boolean>(false);
    const [post, setPost] = useState<DataPostType>();
    const [commentTitle, setCommentTitle] = useState<string>("");
    const [commentDescription, setCommentDescription] = useState<string>("");

    const { id, postId } = useAppParams();

    useEffect(() => {
        fetchSinglePeerGroupPost();
    }, [postId, id]);

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "/peer-group/list" },
        { name: "Post list", href: `/peer-group/info/${id}` },
        { name: "Info " + id, href: "#" },
    ];

    const fetchSinglePeerGroupPost = () => {
        setLoading(true);

        PeerGroupApi.PostApi.ShowPost(user, Number(postId))
            .then((data) => {
                setPost(data.post);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleCreateCommentInPost = () => {
        setMessageLoading(true);

        PeerGroupApi.PostApi.CreateComment(
            user,
            Number(postId),
            commentTitle,
            commentDescription,
        )
            .then(() => {
                setCommentDescription("");
                setCommentTitle("");
                setMessageLoading(false);
                fetchSinglePeerGroupPost();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Post Show</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column gap-3">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div
                    className="bg-custom-white p-3 rounded-5 align-self-center"
                    style={{ width: "780px" }}
                >
                    {post && (
                        <div className="d-flex flex-column w-100 gap-3 px-3">
                            <div
                                className={
                                    "d-flex flex-row gap-2 align-items-center"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faCircleUser}
                                    className="fa-3x"
                                />
                                <strong
                                    style={{ fontSize: "1.5rem" }}
                                    className={"text-secondary"}
                                >
                                    {post.author?.firstName}{" "}
                                    {post.author?.lastName}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row justify-content-between align-items-center"
                                }
                            >
                                <div
                                    className={
                                        "d-flex flex-row gap-2 align-items-center"
                                    }
                                >
                                    <span
                                        className={"text1"}
                                        style={{ fontSize: "1.4rem" }}
                                    >
                                        {post.title}
                                    </span>
                                    <span className={"text5"}>
                                        {post.createdAt.slice(0, 10)}{" "}
                                        {post.createdAt.slice(11, 19)}
                                    </span>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <Carousel
                                    style={{ height: "300px" }}
                                    className="rounded-4"
                                >
                                    {post?.attachments &&
                                    post?.attachments?.length > 0 ? (
                                        post?.attachments.map((mediaItem) => (
                                            <Carousel.Item>
                                                <img
                                                    className="d-block w-100"
                                                    src={mediaItem.path}
                                                    alt={
                                                        mediaItem.attachableType
                                                    }
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "300px",
                                                    }}
                                                />
                                            </Carousel.Item>
                                        ))
                                    ) : (
                                        <img
                                            className="d-block w-100"
                                            src={NotFound}
                                            alt={"not found"}
                                            style={{
                                                objectFit: "cover",
                                                height: "300px",
                                            }}
                                        />
                                    )}
                                </Carousel>
                            </div>
                            <span className="text-secondary text-break my-1">
                                {post.context}
                            </span>
                            <div
                                className={
                                    "d-flex flex-row align-items-center border border-1 rounded-5 p-2"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faCircleUser}
                                    className="fa-2x"
                                />
                                <div className="custom-send-container d-flex">
                                    <input
                                        placeholder={
                                            "Please Enter Your Comment..."
                                        }
                                        value={commentDescription}
                                        onChange={(e) => {
                                            setCommentDescription(
                                                e.target.value,
                                            );
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTelegram}
                                        className="fa-2x"
                                        width={30}
                                        height={30}
                                        style={{ cursor: "pointer" }}
                                        onClick={handleCreateCommentInPost}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 ">
                                <strong>Comments</strong>
                                <div
                                    className=" p-3 rounded-3 overflow-y-auto"
                                    style={{ maxHeight: "20rem" }}
                                >
                                    {post.comments?.map((item) => (
                                        <div className="d-flex flex-column gap-2">
                                            <div
                                                className="d-flex flex-row w-100 "
                                                key={item.id}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faUserCircle}
                                                    width={55}
                                                    height={55}
                                                    className="fa-2x"
                                                />
                                                <div className="d-flex flex-column w-100 gap-2">
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <strong>
                                                            {
                                                                item.author
                                                                    ?.firstName
                                                            }{" "}
                                                            {
                                                                item.author
                                                                    ?.lastName
                                                            }
                                                        </strong>
                                                        <small className="text-secondary">
                                                            {item.createdAt.slice(
                                                                0,
                                                                10,
                                                            )}{" "}
                                                            {item.createdAt.slice(
                                                                11,
                                                                19,
                                                            )}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <strong className="align-items-center text-break">
                                                {item.context}
                                            </strong>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
