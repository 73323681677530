import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { CustomPagination, Loading, CustomBreadCrumb } from "../../components";
import { PaginationType, PeerGroupType } from "../../DTO";
import { useAppNavigate, useAppPagination } from "../../Router/hook";
import { PeerGroupApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";

export const PeerGroupList: FC = () => {
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());
    const [peerGroups, setPeerGroups] = useState<PeerGroupType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "#" },
    ];

    const fetchPeerGroupList = (page: number) => {
        setLoading(true);

        PeerGroupApi.ListPeerGroup(user, page)
            .then((data) => {
                setPeerGroups(data.peerGroup);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleActivatePeerGroup = (peerGroupId: number) => {
        PeerGroupApi.ActivatePeerGroup(user, peerGroupId)
            .then(() => {
                Swal.fire(
                    "Acitvate!",
                    "Peer Group has been activate.",
                    "success",
                );
                fetchPeerGroupList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    const handleDeactivatePeerGroup = (peerGroupId: number) => {
        PeerGroupApi.DeactivatePeerGroup(user, peerGroupId)
            .then(() => {
                Swal.fire(
                    "Deacitvate!",
                    "Peer Group has been deactivate.",
                    "success",
                );
                fetchPeerGroupList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    useEffect(() => {
        fetchPeerGroupList(pagination.current_page);
    }, []);

    return (
        <>
            <Helmet>
                <title>D2Type | Peer Group List</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading ? (
                    <Loading />
                ) : (
                    <div className="d-flex flex-column bg-custom-white rounded p-3">
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                            <h4 className="text-nowrap">Peer Group Table</h4>
                            <div className="d-flex flex-row gap-2">
                                <Link
                                    className="text-white btn btn-success"
                                    to={"/peer-group/new"}
                                >
                                    Peer Group Create+
                                </Link>{" "}
                            </div>
                        </div>
                        <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Coach Name</th>
                                        <th>Coach Phone</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {peerGroups.length > 0 &&
                                        peerGroups.map(
                                            (item: PeerGroupType, index) => (
                                                <tr
                                                    key={item.id}
                                                    className="align-middle"
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {item?.coach?.firstName}{" "}
                                                        {item?.coach?.lastName}
                                                    </td>
                                                    <td>
                                                        {item?.coach?.phone}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                            <Link
                                                                to={`/peer-group/info/${item.id}`}
                                                                className="text-white btn btn-sm btn-info btn-sm"
                                                            >
                                                                info
                                                            </Link>
                                                            <Link
                                                                to={`/peer-group/edit/${item.id}`}
                                                                className="text-dark btn btn-warning btn-sm"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                />
                                                            </Link>
                                                            <Form.Switch
                                                                checked={
                                                                    item.status ===
                                                                    "active"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .checked
                                                                    ) {
                                                                        handleActivatePeerGroup(
                                                                            item.id,
                                                                        );
                                                                    } else {
                                                                        handleDeactivatePeerGroup(
                                                                            item.id,
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <button
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/peer-group/${item.id}/post/new`,
                                                                    )
                                                                }
                                                                className="text-white btn btn-secondary btn-sm"
                                                            >
                                                                Assign To Post
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                </tbody>
                            </Table>
                            <CustomPagination
                                total={pagination.total}
                                perPage={pagination.per_page}
                                currentPage={pagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/peer-group/list?current_page=${pageNum}`,
                                    );
                                    fetchPeerGroupList(pageNum);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
