import { ContentType, userType } from "../../DTO";
import { Api } from "../api";

export const Content = (file: File, user: userType) => {
    const formData = new FormData();

    formData.append("attachment", file);
    return new Promise<{ content: ContentType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "upload",
            body: formData,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "multipart/form-data",
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    content: convertContent(data.data),
                });
            })
            .catch((err) => {
                return reject(err.message);
            });
    });
};

export const convertContent = (data: any): ContentType => {
    let out: ContentType = {
        attachableId: data?.attachable_id,
        attachableType: data?.attachable_type,
        createdAt: data?.created_at,
        deletedAt: data?.deleted_at,
        id: data?.id,
        path: `${process.env.REACT_APP_API_IMAGE_URL}${data?.path}`,
        updatedAt: data?.updated_at,
    };

    return out;
};
