import { FoodLogType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToAttachmentsTypeList } from "../peer-group/post";

export const ListFoodLog = (user: userType, userId: number, date: string) => {
    return new Promise<{ food: FoodLogType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `food-log`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                user_id: userId,
                date,
            },
        });

        return api
            .call()
            .then((data: any) => resolve({ food: convertToFoodTypeList(data) }))
            .catch((err) => reject(err.data.message));
    });
};

export const convertToFoodTypeList = (data: any[]): FoodLogType[] => {
    return data.map((element) => convertToFoodType(element));
};

export const convertToFoodType = (data: any): FoodLogType => {
    let out: FoodLogType = {
        id: data?.id,
        type: data?.type,
        after_midnight: data?.after_midnight,
        attachments: convertToAttachmentsTypeList(data?.attachments),
        created_at: data?.created_at,
        daily_task_id: data?.daily_task_id,
        deleted_at: data?.deleted_at,
        duration_time: data?.duration_time,
        healthiness: data?.healthiness,
        hungry: data?.hungry,
        on_time: data?.on_time,
        plan_order_id: data?.plan_order_id,
        portion: data?.portion,
        sort: data?.sort,
        updated_at: data?.updated_at,
        want_to_eat: data?.want_to_eat,
    };

    return out;
};
