import { PeerGroupType, userType } from "../../DTO";
import { convertAdminUser } from "../admin-user";
import { Api } from "../api";

export const CreatePeerGroup = (
    user: userType,
    name: string,
    coachId: number,
) => {
    return new Promise<{ peerGroup: PeerGroupType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "peer_group",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                coach_id: coachId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    peerGroup: convertToPeerGroupType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToPeerGroupType = (data: any): PeerGroupType => {
    let out: PeerGroupType = {
        coachId: data?.coach_id,
        createdAt: data?.created_at,
        id: data?.id,
        name: data?.name,
        status: data?.status,
        updatedAt: data?.updated_at,
        userCounts: data?.user_counts,
        coach: data?.coach && convertAdminUser(data?.coach),
    };

    return out;
};
