import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
    faFileArrowDown,
    faPollH,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./style.scss";
import { AppUserType, ProgressAllTime, UserFormAnswerType } from "../../../DTO";
import { useAppParams } from "../../../Router/hook";
import { AppUserApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { ExperimentUser, Loading } from "../../../components";
import { findQuestionByAnswers } from "../../../helper/element";

export const InfoUser: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [appUser, setAppUser] = useState<AppUserType>();
    const [showModalDoc, setShowModalDoc] = useState<boolean>(false);
    const [modalDocData, setModalDocData] = useState<UserFormAnswerType>();
    // const [allTime, setAllTime] = useState<ProgressAllTime[]>([]);
    const [showModalPicture, setShowModalPicture] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>("");

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user" },
        { name: "Info " + id, href: "#" },
    ];

    // const fetchProgressAllTime = () => {
    //     setLoading(true);
    //     AppUserApi.UserAllTime(user, Number(id))
    //         .then((data) => {
    //             setAllTime(data.allTime);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             Swal.fire({
    //                 icon: "error",
    //                 text: err,
    //             });
    //         });
    // };

    const fetchSingleUser = () => {
        setLoading(true);

        AppUserApi.ShowAppUser(user, Number(id))
            .then((data) => {
                // if (data.user.planeOrder && data.user.planeOrder.id > 0) {
                //     fetchProgressAllTime();
                // }
                setAppUser(data.user);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleUser();
        // fetchProgressAllTime()
    }, [id]);

    const handleAcceptPlan = () => {
        setLoading(true);
        AppUserApi.AcceptPlan(user, Number(id))
            .then(() => {
                setShowModalDoc(false);
                setLoading(false);
                fetchSingleUser();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleRejectPlan = () => {
        setLoading(true);
        AppUserApi.RejectPlan(user, Number(id))
            .then(() => {
                setShowModalDoc(false);
                setLoading(false);
                fetchSingleUser();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | User info</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {appUser && (
                    <div className="d-flex flex-row flex-wrap rounded px-3">
                        <div className="col-12  col-md-6 col-lg-4 p-2">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="5x"
                                    icon={faUserCircle}
                                />
                                <span className="text-center mt-2">
                                    {appUser.firstName && appUser.lastName
                                        ? `${appUser.firstName} ${appUser.lastName}`
                                        : "-"}
                                </span>
                                <span className="text-center mt-2">
                                    {appUser.email}
                                </span>
                                <hr />
                                <div>
                                    <span>phone:</span>
                                    <span className="mx-1">
                                        {appUser.phone ? appUser.phone : "-"}
                                    </span>
                                </div>
                                <div>
                                    <span>register date:</span>
                                    <span className="mx-1">
                                        {appUser.emailVerifiedAt?.slice(0, 10)}
                                    </span>
                                </div>
                                <div
                                    className="position-absolute info-dot-icon"
                                    style={{ top: "2px", right: "12px" }}
                                >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </div>
                                <div
                                    className="position-absolute border bg-custom-white rounded info-edit-part"
                                    style={{ right: 10 }}
                                >
                                    <Link
                                        className="p-2"
                                        to={`/app-user/edit/${id}`}
                                    >
                                        <small>Edit profile </small>
                                    </Link>
                                    <div className="w-100 border"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12  col-md-6 col-lg-8 p-2 ">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                                <div className="d-flex flex-row flex-wrap col-12">
                                    <div className="col-4">
                                        <small>Insurance Member Id:</small>
                                        <span className="mx-1">
                                            {appUser.insuranceMemberId}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <small>Birthdate:</small>
                                        <span className="mx-1">
                                            {appUser.birthday?.slice(0, 10)}
                                        </span>
                                    </div>{" "}
                                    <div className="col-4">
                                        <small>City:</small>
                                        <span className="mx-1">
                                            {appUser.city}
                                        </span>
                                    </div>{" "}
                                    <div className="col-4">
                                        <small>Postal Code:</small>
                                        <span className="mx-1">
                                            {appUser.postalCode}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <small>Disease Type:</small>
                                        <span className="mx-1">
                                            {appUser.diseaseType == "type2"
                                                ? "Diabetes Type2"
                                                : appUser.diseaseType ==
                                                    "preDiabetes"
                                                  ? "preDiabetes"
                                                  : appUser.diseaseType ==
                                                      "highRisk"
                                                    ? "High Risk"
                                                    : ""}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <small>Unit:</small>
                                        <span className="mx-1">
                                            {appUser.unit}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <small>Register State:</small>
                                        <strong
                                            className={`badge mx-1 ${
                                                appUser.registerState ===
                                                "pending"
                                                    ? "bg-warning text-custom-black"
                                                    : appUser.registerState ===
                                                        "accepted"
                                                      ? "bg-info"
                                                      : appUser.registerState ===
                                                          "finished"
                                                        ? "bg-success"
                                                        : appUser.registerState ===
                                                            "questionnaires"
                                                          ? "bg-primary"
                                                          : appUser.registerState ===
                                                              "plan_purchased"
                                                            ? "bg-danger text-white"
                                                            : appUser.registerState ===
                                                                  "preparing" &&
                                                              "bg-secondary text-custom-light"
                                            }`}
                                        >
                                            {appUser.registerState}
                                        </strong>
                                    </div>
                                    <div className={"col-4"}>
                                        <small>Coach Name:</small>
                                        <strong className={"mx-1"}>
                                            {appUser.coach?.firstName}{" "}
                                            {appUser.coach?.lastName}
                                        </strong>
                                    </div>
                                </div>
                                {appUser.planeOrder?.id &&
                                    appUser.planeOrder?.id > 0 && (
                                        <>
                                            <hr />
                                            <div className="d-flex flex-column gap-1">
                                                <h4>Plan Order</h4>
                                                <div className="d-flex flex-row flex-wrap col-12">
                                                    <div className="d-flex flex-row gap-1 col-4">
                                                        <small>Period:</small>
                                                        <span>
                                                            {appUser.planeOrder
                                                                ?.period + " "}
                                                            Week
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-row gap-1 col-4">
                                                        <small>Point:</small>
                                                        <span>
                                                            {
                                                                appUser
                                                                    .planeOrder
                                                                    ?.point
                                                            }
                                                        </span>
                                                    </div>
                                                    {/*<div className="d-flex flex-row gap-1 col-4">*/}
                                                    {/*    <span>Price:</span>*/}
                                                    {/*    <span>*/}
                                                    {/*        $*/}
                                                    {/*        {*/}
                                                    {/*            appUser*/}
                                                    {/*                .planeOrder*/}
                                                    {/*                ?.price*/}
                                                    {/*        }*/}
                                                    {/*    </span>*/}
                                                    {/*</div>*/}
                                                    <div className="d-flex flex-row gap-1 col-4">
                                                        <small>
                                                            Started At:
                                                        </small>
                                                        <span>
                                                            {appUser.planeOrder?.startedAt?.slice(
                                                                0,
                                                                10,
                                                            )}{" "}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-row gap-1 col-4">
                                                        <small>
                                                            Expired At:
                                                        </small>
                                                        <span>
                                                            {appUser.planeOrder?.expiredAt?.slice(
                                                                0,
                                                                10,
                                                            )}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                <hr />
                                {appUser?.formAnswers &&
                                    appUser?.formAnswers.length > 0 && (
                                        <>
                                            <h4>User Docs</h4>
                                            <div className="d-flex flex-row flex-wrap">
                                                {appUser?.formAnswers.map(
                                                    (formItem) => (
                                                        <div
                                                            style={{
                                                                width: "150px",
                                                            }}
                                                            className={
                                                                "d-flex flex-column border-end border-1 p-2"
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    "border-bottom border-1 text-center"
                                                                }
                                                            >
                                                                {formItem.type ==
                                                                "personal"
                                                                    ? "Personal"
                                                                    : "Questionnaires"}
                                                            </span>
                                                            <FontAwesomeIcon
                                                                size="5x"
                                                                className={`mx-2 mt-3 ${
                                                                    formItem.type ===
                                                                        "personal" &&
                                                                    appUser.adminAccept ===
                                                                        "accepted-by-admin"
                                                                        ? "text-success"
                                                                        : formItem.type ===
                                                                                "personal" &&
                                                                            appUser.adminAccept ===
                                                                                "rejected-by-admin"
                                                                          ? "bg-danger"
                                                                          : "-"
                                                                }`}
                                                                icon={faPollH}
                                                                onClick={() => {
                                                                    setShowModalDoc(
                                                                        true,
                                                                    );
                                                                    setModalDocData(
                                                                        formItem,
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="col-12 p-2">
                            <ExperimentUser
                                userId={Number(id)}
                                setShowModal={setShowModalPicture}
                                setImageUrl={setImageUrl}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Modal
                centered
                size="lg"
                show={showModalDoc}
                onHide={() => setShowModalDoc(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <strong className="fa-2xl">{modalDocData?.type}</strong>
                </Modal.Header>
                <Modal.Body
                    className="overflow-auto"
                    style={{ maxHeight: "500px" }}
                >
                    {appUser?.unit &&
                        modalDocData?.description.map((descriptionItem) => (
                            <>
                                {findQuestionByAnswers(
                                    modalDocData.type,
                                    appUser.unit,
                                    Number(descriptionItem.id),
                                )[0]?.title.length > 0 && (
                                    <>
                                        <div
                                            className={
                                                "d-flex flex-column gap-2"
                                            }
                                        >
                                            <h4>
                                                {
                                                    findQuestionByAnswers(
                                                        modalDocData.type,
                                                        appUser.unit,
                                                        Number(
                                                            descriptionItem.id,
                                                        ),
                                                    )[0]?.title
                                                }
                                            </h4>
                                            {findQuestionByAnswers(
                                                modalDocData.type,
                                                appUser.unit,
                                                Number(descriptionItem.id),
                                            )[0]?.answer.map(
                                                (answerItem, index) => {
                                                    let type =
                                                        findQuestionByAnswers(
                                                            modalDocData.type,
                                                            appUser.unit,
                                                            Number(
                                                                descriptionItem.id,
                                                            ),
                                                        )[0]?.type;
                                                    return (
                                                        <>
                                                            {type === "text" ||
                                                            type ===
                                                                "textFiled" ||
                                                            type ===
                                                                "textFiledUnit" ||
                                                            type ===
                                                                "textFiledButton" ||
                                                            type ===
                                                                "datePicker" ? (
                                                                <div
                                                                    className={
                                                                        "d-flex flex-row gap-2"
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            "mx-2"
                                                                        }
                                                                    >
                                                                        {
                                                                            answerItem?.label
                                                                        }
                                                                        :
                                                                    </span>
                                                                    <strong
                                                                        className={
                                                                            "mx-2"
                                                                        }
                                                                    >
                                                                        {type ===
                                                                        "datePicker"
                                                                            ? String(
                                                                                  descriptionItem.items?.find(
                                                                                      (
                                                                                          valueItem,
                                                                                      ) =>
                                                                                          valueItem.id ==
                                                                                          answerItem.id,
                                                                                  )
                                                                                      ?.value,
                                                                              ).slice(
                                                                                  0,
                                                                                  10,
                                                                              )
                                                                            : descriptionItem.items?.find(
                                                                                  (
                                                                                      valueItem,
                                                                                  ) =>
                                                                                      valueItem.id ==
                                                                                      answerItem.id,
                                                                              )
                                                                                  ?.value}
                                                                    </strong>
                                                                </div>
                                                            ) : type ===
                                                              "radioButton" ? (
                                                                <Form.Group className="d-flex flex-column gap-2">
                                                                    <Form.Check
                                                                        inline
                                                                        checked={
                                                                            String(
                                                                                descriptionItem.items?.find(
                                                                                    (
                                                                                        valueItem,
                                                                                    ) =>
                                                                                        valueItem.id ==
                                                                                        answerItem.id,
                                                                                )
                                                                                    ?.value,
                                                                            ) ==
                                                                            String(
                                                                                answerItem.label,
                                                                            )
                                                                        }
                                                                        value={String(
                                                                            descriptionItem.items?.find(
                                                                                (
                                                                                    valueItem,
                                                                                ) =>
                                                                                    valueItem.id ==
                                                                                    answerItem.id,
                                                                            )
                                                                                ?.value,
                                                                        )}
                                                                        disabled={
                                                                            descriptionItem.items?.find(
                                                                                (
                                                                                    valueItem,
                                                                                ) =>
                                                                                    valueItem.id ==
                                                                                    answerItem.id,
                                                                            )
                                                                                ?.value !=
                                                                            answerItem.label
                                                                        }
                                                                        label={
                                                                            answerItem.label
                                                                        }
                                                                        name={`group ${descriptionItem.id}`}
                                                                        type={
                                                                            "radio"
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                            ) : type ===
                                                              "file" ? (
                                                                descriptionItem.items?.find(
                                                                    (
                                                                        valueItem,
                                                                    ) =>
                                                                        valueItem.id ==
                                                                        answerItem.id,
                                                                )?.value && (
                                                                    <img
                                                                        src={`${
                                                                            process
                                                                                .env
                                                                                .REACT_APP_API_IMAGE_URL
                                                                        }${String(
                                                                            descriptionItem.items?.find(
                                                                                (
                                                                                    valueItem,
                                                                                ) =>
                                                                                    valueItem.id ==
                                                                                    answerItem.id,
                                                                            )
                                                                                ?.value,
                                                                        )}`}
                                                                        width={
                                                                            200
                                                                        }
                                                                        className="align-self-center"
                                                                        height={
                                                                            200
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            setShowModalPicture(
                                                                                true,
                                                                            );
                                                                            setImageUrl(
                                                                                `${
                                                                                    process
                                                                                        .env
                                                                                        .REACT_APP_API_IMAGE_URL
                                                                                }${String(
                                                                                    descriptionItem.items?.find(
                                                                                        (
                                                                                            valueItem,
                                                                                        ) =>
                                                                                            valueItem.id ==
                                                                                            answerItem.id,
                                                                                    )
                                                                                        ?.value,
                                                                                )}`,
                                                                            );
                                                                        }}
                                                                    />
                                                                )
                                                            ) : type ===
                                                                  "checkBoxButton" ||
                                                              type ===
                                                                  "checkBoxTextButton" ? (
                                                                <Form.Group className="d-flex flex-column gap-2">
                                                                    <Form.Check
                                                                        inline
                                                                        checked={
                                                                            descriptionItem.items?.find(
                                                                                (
                                                                                    valueItem,
                                                                                ) =>
                                                                                    valueItem.id ==
                                                                                    answerItem.id,
                                                                            )
                                                                                ?.value ==
                                                                            true
                                                                        }
                                                                        disabled={
                                                                            descriptionItem.items?.find(
                                                                                (
                                                                                    valueItem,
                                                                                ) =>
                                                                                    valueItem.id ==
                                                                                    answerItem.id,
                                                                            )
                                                                                ?.value ==
                                                                            false
                                                                        }
                                                                        label={
                                                                            answerItem.label
                                                                        }
                                                                        // name={`group ${descriptionItem.id}`}
                                                                        type={
                                                                            "checkbox"
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                            ) : type ===
                                                              "sliderString" ? (
                                                                <div className="d-flex flex-row gap-3 flex-wrap">
                                                                    {Array.isArray(
                                                                        answerItem.label,
                                                                    ) &&
                                                                        [
                                                                            ...Array(
                                                                                answerItem
                                                                                    .label
                                                                                    .length,
                                                                            ),
                                                                        ].map(
                                                                            (
                                                                                value,
                                                                            ) => (
                                                                                <span
                                                                                    className={`${
                                                                                        descriptionItem.items?.find(
                                                                                            (
                                                                                                valueItem,
                                                                                            ) =>
                                                                                                valueItem.id ==
                                                                                                answerItem.id,
                                                                                        )
                                                                                            ?.value ==
                                                                                        value
                                                                                            ? "border border-2 rounded-3 p-2"
                                                                                            : ""
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </span>
                                                                            ),
                                                                        )}
                                                                </div>
                                                            ) : type ===
                                                              "province" ? (
                                                                <strong className="mx-2">
                                                                    {
                                                                        descriptionItem.items?.find(
                                                                            (
                                                                                valueItem,
                                                                            ) =>
                                                                                valueItem.id ==
                                                                                answerItem.id,
                                                                        )?.value
                                                                    }
                                                                </strong>
                                                            ) : type ===
                                                              "instruction" ? (
                                                                <span>
                                                                    {
                                                                        answerItem.label
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    );
                                                },
                                            )}
                                        </div>
                                        <hr />
                                    </>
                                )}
                            </>
                        ))}
                </Modal.Body>
                {modalDocData?.type === "personal" && (
                    <Modal.Footer>
                        <Button variant="success" onClick={handleAcceptPlan}>
                            Accept
                        </Button>
                        <Button variant="danger" onClick={handleRejectPlan}>
                            Reject
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
            <Modal
                show={showModalPicture}
                onHide={() => setShowModalPicture(false)}
                keyboard={true}
                size="xl"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={imageUrl} width={"100%"} height={"100%"} />
                </Modal.Body>
            </Modal>
        </>
    );
};
