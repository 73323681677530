import { getLocalItems } from "../../hooks";
import { weekStoreType } from "./type";

const weekSelected = getLocalItems("weekSelected");

export const initialState: weekStoreType = {
    value: {
        endDate: weekSelected ? weekSelected.endDate : "",
        title: weekSelected ? weekSelected.title : "",
        index: weekSelected ? weekSelected.index : 0,
        id: weekSelected ? weekSelected.id : 0,
        isCurrentWeek: weekSelected ? weekSelected.isCurrentWeek : false,
        startDate: weekSelected ? weekSelected.startDate : "",
    },
};
