import { userType } from "../../DTO";
import { rolesType } from "../../DTO/roles";
import { Api } from "../api";
import { convertToPermissionsGroupType } from "./list";

export const GetSingle = (user: userType, rolesId: number) => {
    return new Promise<{ data: rolesType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `roles/${rolesId}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToSingleRoleType(data.data),
                });
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export function convertToSingleRoleType(data: any): rolesType {
    const Roles = {
        id: data.id,
        name: data.name,
        status: data.status,
        permissions: convertToPermissionsGroupType(data.permissions),
    };
    return Roles;
}
