import { userType, RecipeType } from "../../DTO";
import { Api } from "../api";
import { convertToRecipeType } from "./create";

export const EditRecipe = (
    user: userType,
    recipeId: number,
    title: string,
    description: string,
    attachments: number[],
    tags: string[],
) => {
    return new Promise<{ recipe: RecipeType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `recipe/${recipeId}/edit`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                attachment_ids: attachments,
                tags,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    recipe: convertToRecipeType(data),
                });
            })
            .catch((err) => reject(err.data.message));
    });
};
