import React, { useEffect, useState } from "react";
import "./style.scss";
import { loginUserAction, selectUser } from "../../redux-config/entities/user";
import { useAppDispatch, useAppSelector } from "../../redux-config/hooks";

export const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const user = useAppSelector(selectUser);

    const handleLogin = (e: any) => {
        e.preventDefault();
        if (email.length > 3 && password.length > 1) {
            dispatch(loginUserAction({ email, password })).then((data) => {});
        }
    };

    useEffect(() => {
        if (user.access_token && user.access_token.length > 1) {
            window.location.href = "/";
        }
    }, [user]);

    return (
        <form
            className="loginContainer px-3"
            onSubmit={(e: any) => {
                handleLogin(e);
            }}
        >
            <div className="container bg-white shadow-lg rounded p-3 p-sm-5 ">
                <div className="row p-lg-5">
                    <div className="col-sm-6 d-sm-flex d-none">
                        <img
                            src="./image/login-image.webp"
                            id="login-img"
                            className="w-100"
                            style={{ objectFit: "contain" }}
                            alt=""
                        />
                    </div>
                    <div className="col-sm-6 p-3">
                        <div>
                            <div>
                                <h4>Welcome</h4>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    dir="ltr"
                                    type="text"
                                    className="form-control"
                                    placeholder="example@example.com"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="password">Password</label>
                                <input
                                    id="password"
                                    dir="ltr"
                                    type="password"
                                    className="form-control"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-check mt-2">
                                <input
                                    id="law"
                                    className="form-check-input"
                                    type="checkbox"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="law"
                                >
                                    Remember me
                                </label>
                            </div>

                            <hr />
                            <button
                                className="text-white btn btn-info text-white  w-100 mt-3"
                                type="submit"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
