import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../redux-config/entities/user";
import { useAppNavigate, useAppParams } from "../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../components";
import { RecipeForm } from "../../form";
import { RecipeAPi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { RecipeType } from "../../DTO/recipe";

export const RecipeEdit: FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentTags, setCurrentTags] = useState<string[]>([]);
    const [recipe, setRecipe] = useState<RecipeType>();

    const user = useAppSelector(selectUser);

    const { id } = useAppParams();

    const navigate = useAppNavigate();

    useEffect(() => {
        fetchSingleRecipe();
    }, []);

    const fetchSingleRecipe = () => {
        setLoading(true);

        RecipeAPi.ShowRecipe(user, Number(id))
            .then((data) => {
                setRecipe(data.recipe);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const callbackTags = (tags: string[]) => {
        setCurrentTags(tags);
    };

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: `Recipe List `, href: "/recipe/list" },
        { name: `Create`, href: "#" },
    ];

    return (
        <div>
            <Helmet>
                <title>D2Type | Recipe Edit</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">Recipe Edit</h4>
                        {recipe && (
                            <Formik<RecipeForm.FormValues>
                                validationSchema={RecipeForm.ValidationSchema}
                                initialValues={{
                                    description: recipe.description,
                                    title: recipe.title,
                                    tag: "",
                                    tags: recipe.tags,
                                    content: recipe.attachments,
                                }}
                                onSubmit={(event) => {
                                    const imagesId: number[] =
                                        event.content.map((element) =>
                                            Number(element.id),
                                        );
                                    setLoading(true);
                                    RecipeAPi.EditRecipe(
                                        user,
                                        Number(id),
                                        event.title,
                                        event.description,
                                        imagesId,
                                        currentTags,
                                    )
                                        .then(() => {
                                            setLoading(false);
                                            navigate("/recipe/list");
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <RecipeForm.RecipeCreateEdit
                                        submitTxt={"Submit"}
                                        callbackTags={callbackTags}
                                        {...props}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
