import React, { FC, useEffect, useState } from "react";

import "./style.scss";
import { ContentApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";

export const ContentUpload: FC<{
    contentFile: File;
    getDataFromServer: Function;
}> = ({ contentFile, getDataFromServer }) => {
    const [preview, setPreview] = useState<string>("");
    const [uploadLoader, setUploadLoader] = useState(true);
    const [uploadSending, setUploadSending] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        createPreviews();
        uploadToApi();
    }, []);

    const createPreviews = () => {
        if (!contentFile) {
            setPreview("");
            return;
        }
        const objectUrl = URL.createObjectURL(contentFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    };

    const user = useAppSelector(selectUser);

    const uploadToApi = () => {
        if (!uploadSending) {
            setUploadSending(true);
            ContentApi.Content(contentFile, user).then((data) => {
                getDataFromServer(data.content);
                setUploadLoader(false);
            });
        }
    };

    if (isError) {
        return <></>;
    }

    return (
        <div
            className=" m-1 position-relative overflow-hidden"
            style={{ width: "100px", height: "100px" }}
        >
            {uploadLoader && (
                <div className="w-100 h-100 position-absolute text-white contents-upload-image d-flex justify-content-center align-items-center">
                    <small>Uploading</small>
                </div>
            )}
            <img
                className="p-1 col-12"
                style={{ objectFit: "cover" }}
                src={preview}
            />
        </div>
    );
};
