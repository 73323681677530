import {
    CommentType,
    ContentType,
    DataPostType,
    PostType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertAppUser } from "../../app-user";
import { convertContent } from "../../content";
import { convertToPeerGroupType } from "../create";
import { convertToCommentType } from "./comment/create";

export const CreatePost = (
    user: userType,
    peerGroupId: number,
    title: string,
    context: string,
    attachments: number[],
) => {
    return new Promise<{ post: PostType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `peer_group/${peerGroupId}/post`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                context,
                attachment_ids: attachments,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    post: data.data,
                });
            })
            .catch((error: any) => {
                reject(error.data.message);
            });
    });
};

export const convertToPostType = (data: any): PostType => {
    let out: PostType = {
        peerGroup: convertToPeerGroupType(data?.peer_group),
        data: convertToDataPostTypeList(data?.data?.data),
    };

    return out;
};

export const convertToDataPostTypeList = (data: any[]): DataPostType[] => {
    return data.map((element) => convertToDataPostType(element));
};

export const convertToDataPostType = (data: any): DataPostType => {
    let out: DataPostType = {
        authorId: data?.author_id,
        context: data?.context,
        createdAt: data?.created_at,
        id: data?.id,
        peerGroupId: data?.peer_group_id,
        title: data?.title,
        updatedAt: data?.updated_at,
        attachments: data?.attachments
            ? convertToAttachmentsTypeList(data?.attachments)
            : [],
        author: convertAppUser(data?.author),
        comments: convertToCommentTypeList(data?.comments),
        status: data?.status,
    };

    return out;
};

export const convertToAttachmentsTypeList = (data: any[]): ContentType[] => {
    return data.map((element) => convertContent(element));
};

export const convertToCommentTypeList = (data: any[]): CommentType[] => {
    return data.map((element) => convertToCommentType(element));
};
