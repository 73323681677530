import { userType, RecipeType } from "../../DTO";
import { Api } from "../api";
import { convertToAttachmentsTypeList } from "../peer-group/post";

export const CreateRecipe = (
    user: userType,
    title: string,
    description: string,
    attachments: number[],
    tags: string[],
) => {
    return new Promise<{ recipe: RecipeType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "recipe",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                attachment_ids: attachments,
                tags,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    recipe: convertToRecipeType(data),
                });
            })
            .catch((err) => reject(err.data.message));
    });
};

export const convertToRecipeType = (data: any): RecipeType => {
    let out: RecipeType = {
        createdAt: data?.created_at,
        description: data?.description,
        id: data?.id,
        tags: data?.tags,
        status: data?.status,
        title: data?.title,
        updatedAt: data?.updated_at,
        attachments:
            data?.attachments &&
            convertToAttachmentsTypeList(data?.attachments),
    };

    return out;
};
