import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AdminUserType } from "../../DTO";
import { useAppNavigate, useAppParams } from "../../Router/hook";
import { Helmet } from "react-helmet";
import { Loading } from "../../components";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { AdminApi, RolesApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { rolesType } from "../../DTO/roles";

export const InfoAdmin: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [admin, setAdmin] = useState<AdminUserType>();
    const [roleId, setRoleId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [roleList, setRoleList] = useState<rolesType[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(true);

    const { id } = useAppParams();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Admin list", href: "/admin-user/list" },
        { name: "Info " + id, href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const fetchRoleList = () => {
        setLoading(true);
        RolesApi.GetList(user, 1)
            .then((data) => {
                setRoleList(data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchSingleAdmin = () => {
        setLoading(true);
        AdminApi.ShowAdmin(user, Number(id))
            .then((data) => {
                setAdmin(data.admin);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const navigate = useAppNavigate();

    const handleAssignRole = () => {
        setLoading(true);

        RolesApi.Assign(user, Number(admin?.id), Number(roleId))
            .then((data) => {
                setLoading(false);
                setShowModal(false);
                fetchSingleAdmin();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({ icon: "error", text: err });
            });
    };

    useEffect(() => {
        fetchRoleList();
        fetchSingleAdmin();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>D2Type | Admin Info</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-row align-self-end"></div>
                {/*{admin &&*/}
                {/*    admin.roles &&*/}
                {/*    admin.roles.filter((element) => element.name === "Coach")*/}
                {/*        .length > 0 &&*/}
                {/*    admin.status == "inactive" && (*/}
                {/*        <div className="d-flex flex-row col-12 px-4">*/}
                {/*            <Alert*/}
                {/*                className="my-2 align-items-center col-12 justify-content-center"*/}
                {/*                show={showAlert}*/}
                {/*                variant="danger"*/}
                {/*            >*/}
                {/*                <Alert.Heading>Coach Alert</Alert.Heading>*/}
                {/*                <p>*/}
                {/*                    You must complete extra info to activate*/}
                {/*                    coach*/}
                {/*                </p>*/}
                {/*                <hr />*/}
                {/*                <div className="d-flex justify-content-end">*/}
                {/*                    <Button*/}
                {/*                        onClick={() => {*/}
                {/*                            navigate(*/}
                {/*                                `/admin-user/edit/${admin?.id}?role=coach`,*/}
                {/*                            );*/}
                {/*                            setShowAlert(false);*/}
                {/*                        }}*/}
                {/*                        variant="outline-danger"*/}
                {/*                    >*/}
                {/*                        Upload Content*/}
                {/*                    </Button>*/}
                {/*                </div>*/}
                {/*            </Alert>*/}
                {/*        </div>*/}
                {/*    )}*/}
                <div className="d-flex my-2 flex-row flex-wrap rounded px-3">
                    <div className="col-12 col-md-6 col-lg-4 h-100 px-2">
                        {admin && (
                            <>
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                                    <div className="d-flex col-12 flex-row justify-content-between align-items-center align-self-center">
                                        <div className="d-flex flex-column justify-content-start gap-2">
                                            <FontAwesomeIcon
                                                className="mx-2 userImageSiteLayout"
                                                size="5x"
                                                icon={faUser}
                                            />
                                            <strong
                                                className={
                                                    admin.status === "active"
                                                        ? "badge bg-success align-items-center align-self-center"
                                                        : "badge bg-danger align-items-center align-self-center"
                                                }
                                            >
                                                {admin.status}
                                            </strong>
                                            <strong className="text-center">
                                                {admin?.username}
                                            </strong>
                                        </div>
                                        <button
                                            className="text-white btn btn-info"
                                            onClick={() => setShowModal(true)}
                                        >
                                            Assign Role
                                        </button>
                                    </div>
                                    <hr />
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex flex-row gap-2">
                                            <span>Full Name:</span>
                                            <strong>
                                                {admin?.firstName}{" "}
                                                {admin?.lastName}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row gap-2">
                                            <span>Email:</span>
                                            <strong>{admin?.email}</strong>
                                        </div>
                                        <div className="d-flex flex-row gap-2">
                                            <span>Phone Number:</span>
                                            <strong>{admin?.phone}</strong>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-row gap-2 flex-wrap ">
                                            <span>Role:</span>
                                            {admin?.roles &&
                                                admin?.roles.map(
                                                    (elementRole) => (
                                                        <strong
                                                            key={elementRole.id}
                                                            className="text-primary text-capitalize text-center"
                                                        >
                                                            {elementRole.name}
                                                        </strong>
                                                    ),
                                                )}
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-row gap-2">
                                            <span>Last Login:</span>
                                            <strong>{admin.lastLogin}</strong>
                                        </div>
                                        {/*<div className="d-flex flex-row gap-2">*/}
                                        {/*    <span>Position:</span>*/}
                                        {/*    <strong>{admin.position}</strong>*/}
                                        {/*</div>*/}
                                        <div className="d-flex flex-row gap-2">
                                            <span>Created At:</span>
                                            <strong>
                                                {admin?.createdAt.slice(0, 10)}{" "}
                                                {admin?.createdAt.slice(11, 19)}
                                            </strong>
                                        </div>
                                        {/*<div className="d-flex flex-row gap-2">*/}
                                        {/*    <span>Email Verified At:</span>*/}
                                        {/*    <strong>*/}
                                        {/*        {String(*/}
                                        {/*            admin?.emailVerifiedAt,*/}
                                        {/*        ).slice(0, 10)}{" "}*/}
                                        {/*        {String(*/}
                                        {/*            admin?.emailVerifiedAt,*/}
                                        {/*        ).slice(11, 19)}*/}
                                        {/*    </strong>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setRoleId(Number(e.target.value))}
                    >
                        {roleList.map((element) => (
                            <option key={element.id} value={element.id}>
                                {element.name}
                            </option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </Button>
                    <Button variant="success" onClick={handleAssignRole}>
                        Save Change
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
