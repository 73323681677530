import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { Form, Table } from "react-bootstrap";
import { CustomPagination, Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Link } from "react-router-dom";
import { ChatApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { chatListTypeSingle } from "../../../DTO/chat";
import { PaginationType } from "../../../DTO";
import { useAppPagination } from "../../../Router/hook";

export const TicketList = () => {
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [data, setData] = useState<chatListTypeSingle[]>([]);

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "message list", href: "#" },
    ];

    useEffect(() => {
        setLoading(true);
        ChatApi.FetchChatList(user)
            .then((data) => {
                setLoading(false);
                setData(data.data);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <Helmet>
                <title>D2Type | Message List</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex flex-column bg-custom-white rounded p-3">
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">message table</h4>
                            </div>
                            {/* <input
                                type="checkbox"
                                id="showAdminSearchInput"
                            ></input>
                            <div className="adminSearchBox  p-2 flex -row justify-content-between align-items-center">
                                <div>
                                    <Form className="d-flex flex-row flex-wrap">
                                        <Form.Group
                                            className="mx-2"
                                            style={{ minWidth: "230px" }}
                                        >
                                            <Form.Label className="mb-0">
                                                Id
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="number"
                                                placeholder="Enter id"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mx-2"
                                            style={{ minWidth: "230px" }}
                                        >
                                            <Form.Label className="mb-0">
                                                user name
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                placeholder="Enter user name"
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <button className="text-white btn btn-sm btn-success mx-1 text-nowrap">
                                    <span>Search</span>
                                    <FontAwesomeIcon
                                        className="mx-1"
                                        icon={faSearch}
                                    />
                                </button>
                            </div> */}
                            <div
                                className={
                                    "my-lg-3 rounded-3 border border-2 p-3"
                                }
                            >
                                <h5 className={"col-12"}>Search User</h5>
                                <form
                                    // onSubmit={() => {}}
                                    className={
                                        "d-flex flex-row justify-content-between"
                                    }
                                >
                                    <div className="d-flex flex-row flex-wrap gap-2 align-items-center ">
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter Email"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                value={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter First Name"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                value={lastName}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter Last Name"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div
                                        className={
                                            "d-flex flex-row gap-2 align-items-center"
                                        }
                                    >
                                        <button
                                            className="text-white btn btn-sm btn-success text-nowrap align-self-end"
                                            type="submit"
                                        >
                                            <span>Search</span>
                                            <FontAwesomeIcon
                                                className="mx-1"
                                                icon={faSearch}
                                            />
                                        </button>
                                        <button
                                            className="text-white btn btn-sm btn-danger text-nowrap align-self-end"
                                            // onClick={handleRestState}
                                        >
                                            <span>Clear</span>
                                            <FontAwesomeIcon
                                                className="mx-1"
                                                icon={faTrash}
                                            />
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="p-4 text-center text-nowrap">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>User name</th>
                                            <th>Last message</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data)
                                            ? data?.map(
                                                  (
                                                      item: chatListTypeSingle,
                                                      index,
                                                  ) => (
                                                      <tr
                                                          key={index}
                                                          className="align-middle"
                                                      >
                                                          <td>
                                                              {index +
                                                                  1 +
                                                                  (pagination.current_page -
                                                                      1) *
                                                                      pagination.per_page}
                                                          </td>
                                                          <td>
                                                              {item.user?.email
                                                                  ? item.user
                                                                        ?.email
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {item.last_message.slice(
                                                                  0,
                                                                  50,
                                                              )}
                                                          </td>
                                                          <td>
                                                              <span
                                                                  className={
                                                                      item.client
                                                                          ? "badge bg-danger"
                                                                          : "badge bg-success"
                                                                  }
                                                              >
                                                                  {item.client
                                                                      ? " waiting"
                                                                      : "responded"}
                                                              </span>
                                                          </td>
                                                          <td>
                                                              <Link
                                                                  to={`${item.user_id}`}
                                                                  className="text-white btn btn-sm btn-info"
                                                              >
                                                                  info
                                                              </Link>
                                                          </td>
                                                      </tr>
                                                  ),
                                              )
                                            : Object.values(data).map(
                                                  (item: any, index) => (
                                                      <tr>
                                                          <td>
                                                              {item.user_id}
                                                          </td>
                                                          <td>
                                                              {item.user?.email
                                                                  ? item.user
                                                                        ?.email
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {item.last_message.slice(
                                                                  0,
                                                                  50,
                                                              )}
                                                          </td>
                                                          <td>
                                                              <span
                                                                  className={
                                                                      item.client
                                                                          ? "badge bg-danger"
                                                                          : "badge bg-success"
                                                                  }
                                                              >
                                                                  {item.client
                                                                      ? " waiting"
                                                                      : "responded"}
                                                              </span>
                                                          </td>
                                                          <td>
                                                              <Link
                                                                  to={`${item.user_id}`}
                                                                  className="text-white btn btn-sm btn-info"
                                                              >
                                                                  info
                                                              </Link>
                                                          </td>
                                                      </tr>
                                                  ),
                                              )}
                                    </tbody>
                                </Table>
                                <CustomPagination
                                    total={pagination.total}
                                    perPage={pagination.per_page}
                                    currentPage={pagination.current_page}
                                    changePage={(pageNum: number) => {
                                        setPagination({
                                            current_page: pageNum,
                                            per_page: pagination.per_page,
                                            total: pagination.total,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
