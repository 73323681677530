import { FC, useState } from "react";
import Swal from "sweetalert2";
import { Form, Modal, Spinner } from "react-bootstrap";

import { NotificationApi } from "../../api";
import { userType } from "../../DTO";

export const SendNotification: FC<{
    userId: number;
    user: userType;
    fetchData: Function;
    styleClassName?: string;
}> = ({ userId, user, fetchData, styleClassName }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [notification, setNotification] = useState<{
        userId: number;
        title: string;
        body: string;
    }>({
        body: "",
        title: "",
        userId: userId,
    });
    const [showModalNotification, setShowModalNotification] =
        useState<boolean>(false);

    const handleSendNotification = () => {
        setLoading(true);

        NotificationApi.NotificationUser(
            user,
            notification.userId,
            notification.title,
            notification.body,
        )
            .then(() => {
                fetchData();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <button
                className={`text-white btn btn-third ${styleClassName}`}
                onClick={() => {
                    setShowModalNotification(true);
                }}
            >
                Send Notification
            </button>
            <Modal
                show={showModalNotification}
                onHide={() => setShowModalNotification(false)}
                keyboard={true}
                size="lg"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-2">
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                value={notification.title}
                                onChange={(e) =>
                                    setNotification({
                                        ...notification,
                                        title: e.target.value,
                                    })
                                }
                                type="string"
                                placeholder="Please Enter Title"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Body</Form.Label>
                            <Form.Control
                                as={"textarea"}
                                cols={3}
                                rows={8}
                                value={notification.body}
                                onChange={(e) =>
                                    setNotification({
                                        ...notification,
                                        body: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex flex-row gap-2">
                        <button
                            className="btn btn-danger"
                            onClick={() => setShowModalNotification(false)}
                        >
                            Close
                        </button>
                        <button
                            onClick={handleSendNotification}
                            className="btn btn-success"
                            disabled={
                                notification.title.length <= 0 ||
                                notification.body.length <= 0 ||
                                loading
                            }
                        >
                            Send
                            {loading && (
                                <Spinner
                                    animation="border"
                                    className="mx-1"
                                    size="sm"
                                />
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
