import { DashboardType, userType } from "../../DTO";
import { Api } from "../api";

export const DashboardHome = (user: userType) => {
    return new Promise<{ dashboard: DashboardType }>((resolve, reject) => {
        const api = new Api({
            path: "dashboard/info",
            method: "get",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    dashboard: convertDashboardHome(data),
                });
            })
            .catch((err) => {
                return reject(err.message);
            });
    });
};

export const convertDashboardHome = (data: any): DashboardType => {
    let out: DashboardType = {
        users: data?.users,
        coaches: data?.coaches,
        planOrders: data?.plan_orders,
        peerGroups: data?.peer_groups,
    };

    return out;
};
