import { AdminUserType, AppUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertAppUser, convertUserPlaneOrder } from "../app-user";

export const EditUser = (
    user: userType,
    // username: string,
    firstName: string,
    lastName: string,
    phone: string,
    postalCode: string,
    insuranceMemberId: string,
    birthday: string,
    email: string,
    userId: number,
) => {
    return new Promise<{ user: AppUserType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `users/edit/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            body: {
                // username,
                email,
                first_name: firstName,
                last_name: lastName,
                birthday,
                phone,
                insurance_member_id: insuranceMemberId,
                postal_code: postalCode,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    user: convertAppUser(data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};
