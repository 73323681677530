import { userType } from "../../DTO";
import { Api } from "../api";

export const NotificationUser = (
    user: userType,
    userId: number,
    title: string,
    body: string,
) => {
    return new Promise<{ notification: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "notification/user",
            header: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.access_token}`,
            },
            body: JSON.stringify({
                user_id: userId,
                title,
                body,
            }),
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    notification: data.data,
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};
