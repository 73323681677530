import { FC, useEffect, useState } from "react";
import { ExperimentType } from "../../DTO";
import { AppUserApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";

export const ExperimentUser: FC<{
    userId: number;
    setImageUrl: Function;
    setShowModal: Function;
}> = ({ userId, setImageUrl, setShowModal }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [experiment, setExperiment] = useState<ExperimentType[]>([]);

    const user = useAppSelector(selectUser);

    const fetchExperimentList = () => {
        setLoading(true);

        AppUserApi.UserExperiment(user, userId)
            .then((data) => {
                setExperiment(data.experiment);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchExperimentList();
    }, [userId]);

    return (
        <>
            {loading ? (
                <div className="d-flex flex-row justify-content-center align-items-center p-5 w-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className={"w-100 my-2 bg-custom-white p-2 rounded-2"}>
                    <h4 className="text-center">User Experiment</h4>
                    <div className="d-flex flex-row flex-wrap w-100">
                        {experiment.map((experimentItem, index) => (
                            <div
                                style={{
                                    width: "250px",
                                }}
                                className={
                                    "d-flex my-2 flex-column border-end border-1 p-2"
                                }
                            >
                                <span
                                    className={
                                        "border-bottom border-1 text-center"
                                    }
                                >
                                    {experimentItem.title}
                                </span>
                                {experimentItem.attachments.map(
                                    (attachmentItem, index) => (
                                        <img
                                            onClick={() => {
                                                setImageUrl(
                                                    attachmentItem.path,
                                                );
                                                setShowModal(true);
                                            }}
                                            className="mx-2 mt-3 align-self-center g-cursor-pointer"
                                            src={attachmentItem.path}
                                            width={150}
                                            height={150}
                                            title={`${experimentItem}-${index}`}
                                            loading="lazy"
                                        />
                                    ),
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
