import React from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import DatePicker from "react-multi-date-picker";
import gregorian_en from "react-date-object/locales/gregorian_en";
import gregorian from "react-date-object/calendars/gregorian";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import { FormValues, PropType } from "./type";
import { convertDateFormat } from "../../utils";

export const UserEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        setBirthDay,
        birthday,
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    {/*<Form.Group*/}
                    {/*    controlId="user-username"*/}
                    {/*    className="position-relative col-12 col-sm-6 p-2"*/}
                    {/*>*/}
                    {/*    <Form.Label>Username</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        aria-label="username"*/}
                    {/*        className="h3"*/}
                    {/*        type="string"*/}
                    {/*        name="username"*/}
                    {/*        placeholder={"Please Enter Username..."}*/}
                    {/*        onBlur={handleBlur}*/}
                    {/*        value={values.username}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        isInvalid={touched.username && !!errors.username}*/}
                    {/*    />*/}
                    {/*    <Form.Text className="text-danger">*/}
                    {/*        {errors.username}*/}
                    {/*    </Form.Text>*/}
                    {/*</Form.Group>*/}
                    <Form.Group
                        controlId="user-first-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            aria-label="fist-name"
                            className="h3"
                            type="string"
                            name="firstName"
                            placeholder={"Please Enter First Name..."}
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Text className="text-danger">
                            {errors.firstName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-last-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            aria-label="last name"
                            className="h3"
                            type="string"
                            name="lastName"
                            placeholder={"Please Enter Last Name..."}
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Text className="text-danger">
                            {errors.lastName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-email"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            aria-label="email"
                            className="h3"
                            type="string"
                            name="email"
                            placeholder={"Please Enter Email..."}
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Text className="text-danger">
                            {errors.email}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-phone"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            aria-label="phone"
                            className="h3"
                            type="string"
                            name="phone"
                            placeholder={"Please Enter Phone..."}
                            onBlur={handleBlur}
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={touched.phone && !!errors.phone}
                        />
                        <Form.Text className="text-danger">
                            {errors.phone}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-postal-code"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            aria-label="postalCode"
                            className="h3"
                            type="string"
                            name="postalCode"
                            placeholder={"Please Enter Postal Code..."}
                            onBlur={handleBlur}
                            value={values.postalCode}
                            onChange={handleChange}
                            isInvalid={
                                touched.postalCode && !!errors.postalCode
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.postalCode}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-insurance-member-id"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Insurance Member Id</Form.Label>
                        <Form.Control
                            aria-label="insuranceMemberId"
                            className="h3"
                            type="string"
                            name="insuranceMemberId"
                            placeholder={"Please Enter Insurance Member Id..."}
                            onBlur={handleBlur}
                            value={values.insuranceMemberId}
                            onChange={handleChange}
                            isInvalid={
                                touched.insuranceMemberId &&
                                !!errors.insuranceMemberId
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.insuranceMemberId}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-birthday"
                        className="position-relative h-3 d-flex flex-column col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Birth Date</Form.Label>
                        <DatePicker
                            calendarPosition={"bottom-center"}
                            inputClass={"p-1 rounded w-100"}
                            value={birthday}
                            onChange={(array: any) => {
                                setBirthDay(
                                    convertDateFormat(array.toString?.()),
                                );
                            }}
                            calendar={gregorian}
                            locale={gregorian_en}
                            format="YYYY/MM/DD"
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={birthday.length <= 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
