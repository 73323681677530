import { DataSlideType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToDataSlideType } from "./create";

export const ShowSlide = (user: userType, slideId: number) => {
    return new Promise<{ slide: DataSlideType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `lesson/slides/${slideId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    slide: convertToDataSlideType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
