import { userType } from "../../DTO";
import { Api } from "../api";

export const EditPeerGroup = (
    user: userType,
    peerGroupId: number,
    name: string,
    coachId: number,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `peer_group/${peerGroupId}/edit`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                coach_id: coachId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data,
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
