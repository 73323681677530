import React, { useState } from "react";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { Helmet } from "react-helmet";

import { AdminForm } from "../../../form";
import { useAppNavigate } from "../../../Router/hook";
import { AdminApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { CustomBreadCrumb, Loading } from "../../../components";

export const NewAdmin: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Admin list", href: "/AdminUsers" },
        { name: "New", href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>D2Type | Admin New</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<AdminForm.FormValues>
                        validationSchema={AdminForm.ValidationSchema}
                        initialValues={{
                            username: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                            password: "",
                            passwordConfirm: "",
                            phone: "",
                            // position: "position",
                        }}
                        onSubmit={(event) => {
                            setLoading(true);

                            AdminApi.CreateAdmin(
                                user,
                                event.username,
                                event.firstName,
                                event.lastName,
                                event.email,
                                event.phone,
                                event.password,
                                event.passwordConfirm,
                            )
                                .then(() => {
                                    setLoading(false);
                                    navigate("/admin-user/list");
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    Swal.fire({
                                        icon: "error",
                                        text: err,
                                    });
                                });
                        }}
                        component={(props: any) => (
                            <AdminForm.AdminCreateEdit
                                submitTxt={"Submit"}
                                {...props}
                                role={[]}
                                position=""
                                setPosition={() => {}}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
