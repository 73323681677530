import {
    AdminUserRoleType,
    AdminUserType,
    PaginationType,
    userType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const ListAdmin = (
    user: userType,
    currentPage: number,
    email?: string,
    role?: string,
) => {
    return new Promise<{ admin: AdminUserType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "auth/index",
                params: {
                    page: currentPage,
                    email,
                    role,
                },
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        admin: convertAdminUserList(data.data),
                        pagination: convertToPaginationType(data),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};

export const convertAdminUserList = (data: any[]): AdminUserType[] => {
    return data.map((element) => convertAdminUser(element));
};

export const convertAdminUser = (data: any): AdminUserType => {
    let out: AdminUserType = {
        createdAt: data?.created_at,
        deletedAt: data?.deleted_at,
        email: data?.email,
        emailVerifiedAt: data?.email_verified_at,
        firstName: data?.first_name,
        id: data?.id,
        lastLogin: data?.last_login,
        lastName: data?.last_name,
        phone: data?.phone,
        phoneVerifiedAt: data?.phone_verified_at,
        roles: data?.roles && convertAdminUserRoleList(data?.roles),
        status: data?.status,
        updatedAt: data?.updated_at,
        userId: data?.userId,
        username: data?.username,
        position: data?.position ? data?.position : null,
    };

    return out;
};

export const convertAdminUserRoleList = (data: any[]): AdminUserRoleType[] => {
    return data.map((element) => convertToAdminUserRole(element));
};

export const convertToAdminUserRole = (data: any): AdminUserRoleType => {
    let out: AdminUserRoleType = {
        id: data?.id,
        createdAt: data?.created_at,
        guardName: data?.guard_name,
        name: data?.name,
        pivot: {
            modelId: data?.pivot?.model_id,
            roleId: data?.pivot?.role_id,
        },
        status: data?.status,
        updatedAt: data?.updated_at,
    };

    return out;
};
