import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { LessonForm } from "../../../form";
import { LessonApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";

export const LessonSlideCreate = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useAppNavigate();

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Slide list", href: `/lesson/info/${id}` },
        { name: "New", href: "#" },
    ];

    const handleCreateSlide = (
        title: string,
        description: string,
        attachments: number[],
        timeToRead: number,
    ) => {
        setLoading(true);

        LessonApi.SlideApi.CreateSlide(
            user,
            title,
            description,
            attachments,
            timeToRead,
            Number(id),
        )
            .then(() => {
                navigate(`/lesson/info/${id}`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Slide New</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<LessonForm.LessonSlideForm.FormValues>
                        validationSchema={LessonForm.LessonSlideForm.ValidationSchema()}
                        initialValues={{
                            title: "",
                            description: "",
                            content: [],
                            timeToRead: 0,
                        }}
                        onSubmit={(event) => {
                            const imagesId: number[] = event.content.map(
                                (element) => Number(element.id),
                            );
                            handleCreateSlide(
                                event.title,
                                event.description,
                                imagesId,
                                event.timeToRead,
                            );
                        }}
                        component={(props: any) => (
                            <LessonForm.LessonSlideForm.LessonSlideCreateOrEdit
                                submitTxt={"Submit"}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
