import React, { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import _ from "lodash";
import Swal from "sweetalert2";

import { AdminApi } from "../../../api";
import { FormValues, PropType } from "./type";
import { AdminUserType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { Shimmer } from "../../../components";

export const PeerGroupCreateOrEdit = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const [coachName, setCoachName] = useState(values.coachName);
    const [coachList, setCoachList] = useState<AdminUserType[]>([]);
    const [selectedCoach, setSelectedCoach] = useState(values.coachId);
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const fetchCoachList = (query: string) => {
        setLoading(true);
        AdminApi.ListAdmin(user, 1, query, "Coach")
            .then((data) => {
                setCoachList(data.admin);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.message,
                });
            });
    };

    useEffect(() => {
        values.coachId = selectedCoach;
    }, [selectedCoach]);

    useEffect(() => {
        values.coachName = coachName;
    }, [coachName]);

    const throttledSearchCoach = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchCoachList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchCoach.cancel();
        };
    }, [throttledSearchCoach]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="peer-group-name"
                        className="position-relative col-12 col-sm-6 px-2"
                    >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={"Please Enter Name"}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <div className="col-12 col-md-6 position-relative">
                        <Form.Group
                            controlId="peer-group-coach"
                            className="col-12"
                        >
                            <Form.Label>Coach Name Or Email</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                aria-label="coach-name"
                                className="h3"
                                type="string"
                                name="coachName"
                                placeholder={"Please Enter Coach Email"}
                                onFocus={() => {
                                    setLoading(true);
                                }}
                                onBlur={handleBlur}
                                value={coachName}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setCoachName(e.target.value);
                                    throttledSearchCoach(e);
                                }}
                                isInvalid={
                                    touched.coachName && !!errors.coachName
                                }
                            />
                            {coachList.length > 0 ? (
                                <div
                                    style={{ zIndex: 100 }}
                                    className="position-absolute d-flex bg-custom-white flex-column border rounded-2 p-2 border-2 w-100"
                                >
                                    {coachList.map((item, index) => (
                                        <div
                                            className="text-center"
                                            onClick={() => {
                                                setSelectedCoach(
                                                    Number(item.id),
                                                );
                                                setCoachName(
                                                    `${item.firstName} ${item.lastName} | ${item.email}`,
                                                );
                                                setCoachList([]);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <strong
                                                key={index}
                                                className="p-2 text-center"
                                            >
                                                {`${item.firstName} ${item.lastName} | ${item.email}`}
                                            </strong>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                selectedCoach <= 0 &&
                                loading && (
                                    <div className="text-center border rounded-2 p-2 border-2">
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                            <Form.Text className="text-danger">
                                {errors.coachName}
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    style={{ zIndex: 1 }}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
