import { userType } from "../../DTO";
import { Api } from "../../api";

export const DeactivateLesson = (user: userType, lessonId: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "patch",
            path: `lesson/${lessonId}/deactivate`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({ data });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
