import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { CustomBreadCrumb } from "../../../components";
import { LessonForm } from "../../../form";
import { LessonApi } from "../../../api";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { Loading } from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { DataSlideType, SlideType } from "../../../DTO";

export const LessonSlideEdit: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [slide, setSlide] = useState<DataSlideType>();
    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const { id, slideId } = useAppParams();

    const handleSlideEdit = (
        title: string,
        description: string,
        attachments: number[],
        timeToRead: number,
    ) => {
        setLoading(true);
        LessonApi.SlideApi.EditSlide(
            user,
            Number(slideId),
            title,
            description,
            timeToRead,
            attachments,
        )
            .then((data) => {
                navigate(`/lesson/info/${id}`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleLesson();
    }, [id]);

    const fetchSingleLesson = () => {
        setLoading(true);

        LessonApi.SlideApi.ShowSlide(user, Number(slideId))
            .then((data) => {
                setSlide(data.slide);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Lesson list", href: `/lesson/info/${slideId}` },
        { name: "Edit" + slideId, href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>D2Type | Slide Edit</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {slide && (
                        <Formik<LessonForm.LessonSlideForm.FormValues>
                            validationSchema={
                                LessonForm.LessonSlideForm.ValidationSchema
                            }
                            initialValues={{
                                title: slide.title,
                                content: slide.attachments
                                    ? slide.attachments
                                    : [],
                                description: slide.description,
                                timeToRead: slide.timeToRead,
                            }}
                            onSubmit={(event) => {
                                const imagesId: number[] = event.content.map(
                                    (element) => Number(element.id),
                                );
                                handleSlideEdit(
                                    event.title,
                                    event.description,
                                    imagesId,
                                    event.timeToRead,
                                );
                            }}
                            component={(props: any) => (
                                <LessonForm.LessonSlideForm.LessonSlideCreateOrEdit
                                    submitTxt={"Submit"}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
