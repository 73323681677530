import { userType } from "../../DTO";
import { Api } from "../api";

export const DeleteUser = (user: userType, userId: number) => {
    return new Promise<{ user: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            path: `users/${userId}`,
        });

        return api
            .call()
            .then((data: any) => resolve({ user: data.data }))
            .catch((err) => reject(err.data.message));
    });
};
