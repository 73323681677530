import { PaginationType, PeerGroupType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToPeerGroupType } from "./create";

export const ListPeerGroup = (user: userType, currentPage: number) => {
    return new Promise<{
        peerGroup: PeerGroupType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "peer_group",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    pagination: convertToPaginationType(data.meta),
                    peerGroup: convertToPeerGroupTypeList(data.meta.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToPeerGroupTypeList = (data: any[]): PeerGroupType[] => {
    return data.map((element) => convertToPeerGroupType(element));
};
