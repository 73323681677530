import React from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { FormValues, PropType } from "./type";
import { useAppLocation, useAppQuery } from "../../Router/hook";

export const AdminCreateEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        role,
    } = props;

    const queryString = useAppQuery();

    const coachRole = queryString.get("role");

    const { pathname } = useAppLocation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="admin-username"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            aria-label="username"
                            className="h3"
                            type="string"
                            name="username"
                            placeholder={"Please Enter Username..."}
                            onBlur={handleBlur}
                            value={values.username}
                            onChange={handleChange}
                            isInvalid={touched.username && !!errors.username}
                        />
                        <Form.Text className="text-danger">
                            {errors.username}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-first-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            aria-label="fist-name"
                            className="h3"
                            type="string"
                            name="firstName"
                            placeholder={"Please Enter First Name..."}
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Text className="text-danger">
                            {errors.firstName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-last-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            aria-label="last name"
                            className="h3"
                            type="string"
                            name="lastName"
                            placeholder={"Please Enter Last Name..."}
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Text className="text-danger">
                            {errors.lastName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-email"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            aria-label="email"
                            className="h3"
                            type="string"
                            name="email"
                            placeholder={"Please Enter Email..."}
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Text className="text-danger">
                            {errors.email}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-phone"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            aria-label="phone"
                            className="h3"
                            type="string"
                            name="phone"
                            placeholder={"Please Enter Phone..."}
                            onBlur={handleBlur}
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={touched.phone && !!errors.phone}
                        />
                        <Form.Text className="text-danger">
                            {errors.phone}
                        </Form.Text>
                    </Form.Group>
                    {/*{(coachRole &&*/}
                    {/*    coachRole.length > 0 &&*/}
                    {/*    coachRole === "coach") ||*/}
                    {/*role.filter((element) => element.name === "Coach").length >*/}
                    {/*    0 ? (*/}
                    {/*    <Form.Group*/}
                    {/*        controlId="admin-position"*/}
                    {/*        className="position-relative col-12 col-sm-6 p-2"*/}
                    {/*    >*/}
                    {/*        <Form.Label>Position</Form.Label>*/}
                    {/*        <Form.Control*/}
                    {/*            aria-label="position"*/}
                    {/*            className="h3"*/}
                    {/*            type="string"*/}
                    {/*            name="position"*/}
                    {/*            placeholder={"Please Enter Position..."}*/}
                    {/*            onBlur={handleBlur}*/}
                    {/*            value={values.position}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            isInvalid={*/}
                    {/*                touched.position && !!errors.position*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*        <Form.Text className="text-danger">*/}
                    {/*            {errors.position}*/}
                    {/*        </Form.Text>*/}
                    {/*    </Form.Group>*/}
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}
                    <Form.Group
                        controlId="admin-password"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            aria-label="password"
                            className="h3"
                            type="password"
                            name="password"
                            placeholder={"Please Enter Password"}
                            onBlur={handleBlur}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Text className="text-danger">
                            {errors.password}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-password-confirm"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control
                            aria-label="password-confirm"
                            className="h3"
                            type="password"
                            name="passwordConfirm"
                            placeholder={"Please Enter Password Confirm"}
                            onBlur={handleBlur}
                            value={values.passwordConfirm}
                            onChange={handleChange}
                            isInvalid={
                                touched.passwordConfirm &&
                                !!errors.passwordConfirm
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.passwordConfirm}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
