import { userType } from "../../DTO";
import { Api } from "../api";

export const Assign = (user: userType, adminId: number, roleId: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "roles/assign",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            body: {
                admin_id: adminId,
                role_id: roleId,
            },
        });
        return api
            .call()
            .then((data) => {
                return resolve({
                    data: data,
                });
            })
            .catch((err) => {
                return reject({ err });
            });
    });
};
