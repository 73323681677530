import { userType } from "../../DTO";
import { createRoleType } from "../../DTO/roles";
import { Api } from "../api";

export const Edit = (
    user: userType,
    roleId: number,
    rolesList: createRoleType,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `roles/${roleId}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            body: {
                name: rolesList?.name,
                permissions: rolesList?.permissions,
            },
        });
        return api
            .call()
            .then((data) => {
                return resolve({
                    data: data,
                });
            })
            .catch((err) => {
                return reject({ err });
            });
    });
};
