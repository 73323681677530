import { userType } from "../../DTO";
import { Api } from "../api";

export const AcceptPlan = (user: userType, userId: number) => {
    return new Promise<{ plan: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `plan/accept/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    plan: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};

export const RejectPlan = (user: userType, userId: number) => {
    return new Promise<{ plan: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `plan/reject/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    plan: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
