import { ActivityLogType, userType } from "../../DTO";
import { Api } from "../api";

export const ActivityLog = (
    user: userType,
    userId: number,
    weekNumber: number,
) => {
    return new Promise<{ activity: ActivityLogType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `elementor/progress/activity-log/user/${userId}/week/${weekNumber}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    activity: convertToActivityLogTypeList(data.data.data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const convertToActivityLogTypeList = (
    data: any[],
): ActivityLogType[] => {
    return data.map((element) => convertToActivityLogType(element));
};

export const convertToActivityLogType = (data: any): ActivityLogType => {
    let out: ActivityLogType = {
        createdAt: data?.created_at,
        durationTime: data?.duration_time,
        id: data?.id,
        steps: data?.steps,
        timezoneOffset: data?.timezone_offset,
        title: data?.title,
        type: data?.type,
        totalDurationTime: data?.total_duration_time,
        totalSteps: data?.total_steps,
    };

    return out;
};
