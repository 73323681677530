import React, { FC, useEffect, useState } from "react";
import { ReviewLogType } from "../../DTO";
import { selectUser } from "../../redux-config/entities/user";
import { useAppSelector } from "../../redux-config/hooks";
import { AppUserApi } from "../../api";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

export const StressMood: FC<{ userId: number; currentDate: string }> = ({
    userId,
    currentDate,
}) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [reviewLog, setReviewLog] = useState<ReviewLogType[]>([]);

    const user = useAppSelector(selectUser);

    const fetchReviewLogList = () => {
        setLoader(true);

        AppUserApi.ReviewLog(user, Number(userId), currentDate)
            .then((data) => {
                setReviewLog(data.reviewLog);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        if (currentDate.length > 0) {
            fetchReviewLogList();
        }
    }, [currentDate]);

    return (
        <>
            {loader ? (
                <div className="d-flex flex-row justify-content-center align-items-center p-5 w-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    <div className="d-flex flex-row flex-wrap">
                        {reviewLog.length > 0 ? (
                            <div className="p-2 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="border rounded-5 p-4 d-flex flex-column gap-3">
                                    <div className="d-flex flex-row gap-2">
                                        <span>Stress:</span>
                                        <strong>{reviewLog[0]?.stress}</strong>
                                    </div>
                                    <div className="d-flex flex-row gap-2">
                                        <span>Mood:</span>
                                        <strong>{reviewLog[0]?.mood}</strong>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <strong className="text-danger">
                                Stress and Mood is Empty!
                            </strong>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
