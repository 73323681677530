import { PlanDayType, userType } from "../../DTO";
import { Api } from "../api";

export const PlanDays = (
    user: userType,
    userId: number,
    weekNumber: number,
) => {
    return new Promise<{ planDays: PlanDayType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `plan/user/${userId}/weeks/${weekNumber}/days`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    planDays: convertToPlanDaysTypeList(data.data.data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const convertToPlanDaysTypeList = (data: any[]): PlanDayType[] => {
    return data.map((element) => convertToPlanDaysType(element));
};

export const convertToPlanDaysType = (data: any): PlanDayType => {
    let out: PlanDayType = {
        id: data?.id,
        name: data?.name,
        date: data?.date,
        isCurrentDay: data?.isCurrentDay,
        isLock: data?.isLock,
        percentage: data?.percentage,
    };

    return out;
};
