import React, { useEffect, useState } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { FormikProps } from "formik";
import { FormValues, PropType } from "./type";
import { Contents } from "../../components/contents";
import { ContentType } from "../../DTO";

export const RecipeCreateEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        callbackTags,
    } = props;
    const [contents, setContents] = useState<ContentType[]>(values.content);
    const [tag, setTag] = useState<string>(values.tag);
    const [tags, setTags] = useState<string[]>(values.tags);

    useEffect(() => {
        if (tag !== "") {
            setTags([...tags, tag]);
            callbackTags(tags);
        }
        values.tag = "";
    }, [tag]);

    useEffect(() => {
        values.tags = tags;
        callbackTags(tags);
    }, [tags]);

    useEffect(() => {
        values.content = contents;
    }, [contents]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="recipe-title"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            aria-label="title"
                            className="h3"
                            type="string"
                            name="title"
                            placeholder={"Please Enter Title"}
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Text className="text-danger">
                            {errors.title}
                        </Form.Text>
                    </Form.Group>

                    <div className="col-12 col-sm-6 ">
                        <Form.Group
                            controlId={`recipe-tag`}
                            className="position-relative d-flex flex-column gap-2"
                        >
                            <Form.Label>Tag</Form.Label>
                            <div className="flex-row d-flex flex-wrap">
                                <Form.Control
                                    aria-label="tag"
                                    className="col px-2"
                                    type={"string"}
                                    name="tag"
                                    placeholder={"Please Enter Tag Name..."}
                                    onBlur={handleBlur}
                                    value={values.tag}
                                    onChange={handleChange}
                                    isInvalid={
                                        touched.tag &&
                                        !!errors.tag &&
                                        tags.length === 0
                                    }
                                />
                                <Button
                                    variant="info"
                                    onClick={() => {
                                        !errors.tag && setTag(values.tag);
                                    }}
                                    className="align-self-center col-2 px-2"
                                >
                                    {"Add Tag"}
                                </Button>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                {errors.tag}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex flex-wrap">
                            {tags.length >= 1 &&
                                tags.map((item: string) => (
                                    <div
                                        key={item}
                                        className=" my-3 mx-2 border border-1 position-relative rounded-4 border-warning p-3"
                                    >
                                        <strong>{item}</strong>
                                        <Badge
                                            style={{
                                                cursor: "pointer",
                                                right: 0,
                                                zIndex: 10,
                                                top: -5,
                                                position: "absolute",
                                            }}
                                            pill
                                            bg="danger"
                                            onClick={() =>
                                                setTags(
                                                    tags.filter(
                                                        (element) =>
                                                            element !== item,
                                                    ),
                                                )
                                            }
                                        >
                                            ×
                                        </Badge>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <Form.Group
                        controlId="recipe-description"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>description</Form.Label>
                        <Form.Control
                            as={"textarea"}
                            rows={5}
                            cols={5}
                            aria-label="description"
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={"Please Enter Description"}
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={
                                touched.description && !!errors.description
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.description}
                        </Form.Text>
                    </Form.Group>
                    <Contents
                        contentLabel="License Recipe Upload"
                        defaultValue={values.content}
                        formPrefix={"image-upload"}
                        setContents={setContents}
                        yupError={"Image is required!"}
                        inputValue={"image/png, image/jpeg, image/jpg"}
                    />
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={contents.length <= 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
