//@ts-nocheck
import React, { FC } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

import { ActivityLogType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectDarkMode } from "../../redux-config/entities/dark-mode";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const ReportActivityChart: FC<{ activity: ActivityLogType[] }> = ({
    activity,
}) => {
    const darkMode = useAppSelector(selectDarkMode);

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const chartData = activity.map((activityItem, index) => ({
        durationTime: Number(activityItem.totalDurationTime),
        label: daysOfWeek[new Date(activityItem.createdAt).getDay()],
        y: [0, Number(activityItem.totalSteps)],
    }));

    if (chartData.length > 0) {
        const options = {
            theme: darkMode ? "dark2" : "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "Weekly Average Activity Chart",
            },
            axisX: {
                title: "Day of Week",
            },
            axisY: {
                title: "Average Activity Steps",
                includeZero: true,
                interval: 10000,
                valueFormatString: "0",
            },
            data: [
                {
                    type: "rangeColumn",
                    markerSize: 15,
                    indexLabel: "{y[#index]}",
                    // xValueFormatString: "DD MMM YYYY",
                    toolTipContent:
                        "<strong>{label}</strong> <br/> Total Steps: {y[1]} <br /> Duration Time: {durationTime} sec",
                    dataPoints: chartData,
                },
            ],
        };
        return <CanvasJSChart options={options} />;
    }
};
