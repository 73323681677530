import { FC, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { UserSleepType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectWeek } from "../../redux-config/entities/week";
import { selectUser } from "../../redux-config/entities/user";
import { AppUserApi } from "../../api";
import { ReportSleepChart } from "../chart/sleep_chart";
import { convertTimeSecondsToHours, convertToPDTByLibrary } from "../../utils";

export const SleepReport: FC<{ userId: number }> = ({ userId }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [sleep, setSleep] = useState<UserSleepType[]>([]);

    const week = useAppSelector(selectWeek);

    const user = useAppSelector(selectUser);

    const fetchProgressSleepReport = () => {
        setLoader(true);

        AppUserApi.UserSleep(user, Number(userId), week.index)
            .then((data) => {
                setSleep(data.sleep);
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchProgressSleepReport();
    }, [week]);

    return (
        <>
            {loader ? (
                <div className="d-flex flex-row justify-content-center align-items-center p-5 w-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className={"w-100 my-2 bg-custom-white p-2"}>
                    <Table hover responsive className={"text-center"}>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Time Zone Offset (UTC)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sleep.length > 0 &&
                                sleep.map((item, index) => (
                                    <tr key={item.id} className="align-middle">
                                        <td>{index + 1}</td>
                                        <td>
                                            <div
                                                className={
                                                    "d-flex flex-column gap-2"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "d-flex flex-row gap-2 justify-content-center"
                                                    }
                                                >
                                                    <small>
                                                        {convertToPDTByLibrary(
                                                            item.startDate,
                                                        ).slice(0, 10)}
                                                    </small>
                                                    <strong
                                                        className={
                                                            "text-danger"
                                                        }
                                                    >
                                                        {convertToPDTByLibrary(
                                                            item.startDate,
                                                        ).slice(11, 19)}
                                                    </strong>
                                                    PDT
                                                </div>
                                                <div
                                                    className={
                                                        "d-flex flex-row gap-2 justify-content-center"
                                                    }
                                                >
                                                    <small>
                                                        {item.startDate.slice(
                                                            0,
                                                            10,
                                                        )}
                                                    </small>
                                                    <strong
                                                        className={
                                                            "text-danger"
                                                        }
                                                    >
                                                        {item.startDate.slice(
                                                            11,
                                                            19,
                                                        )}
                                                    </strong>
                                                    UTC
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    "d-flex flex-column gap-2"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "d-flex flex-row gap-2 justify-content-center"
                                                    }
                                                >
                                                    <small>
                                                        {convertToPDTByLibrary(
                                                            item.endDate,
                                                        ).slice(0, 10)}
                                                    </small>
                                                    <strong
                                                        className={
                                                            "text-danger"
                                                        }
                                                    >
                                                        {convertToPDTByLibrary(
                                                            item.endDate,
                                                        ).slice(11, 19)}
                                                    </strong>
                                                    PDT
                                                </div>
                                                <div
                                                    className={
                                                        "d-flex flex-row gap-2 justify-content-center"
                                                    }
                                                >
                                                    <small>
                                                        {item.endDate.slice(
                                                            0,
                                                            10,
                                                        )}
                                                    </small>
                                                    <strong
                                                        className={
                                                            "text-danger"
                                                        }
                                                    >
                                                        {item.endDate.slice(
                                                            11,
                                                            19,
                                                        )}
                                                    </strong>
                                                    UTC
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {item.timezoneOffset &&
                                                convertTimeSecondsToHours(
                                                    item.timezoneOffset,
                                                )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <ReportSleepChart sleep={sleep} />
                </div>
            )}
        </>
    );
};
