import { ReviewLogType, userType } from "../../DTO";
import { Api } from "../api";

export const ReviewLog = (user: userType, userId: number, date: string) => {
    return new Promise<{ reviewLog: ReviewLogType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `review-log`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                user_id: userId,
                date: date,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    reviewLog: convertToReviewLogTypeList(data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const convertToReviewLogTypeList = (data: any[]): ReviewLogType[] => {
    if (data && data.length > 0) {
        return data.map((element) => convertToReviewLogType(element));
    } else return [];
};

export const convertToReviewLogType = (data: any): ReviewLogType => {
    let out: ReviewLogType = {
        id: data.id,
        mood: data?.mood,
        stress: data?.stress,
    };

    return out;
};
