import { ProgressOverview, ProgressReportType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToReport } from "./report_detail";

export const UserOverview = (
    user: userType,
    userId: number,
    type:
        | "engagement_detail"
        | "food_detail"
        | "activity_detail"
        | "sleep_detail"
        | "progress_detail"
        | "all_time"
        | "stress_detail"
        | "weight_detail"
        | "blood_detail"
        | string,
    period: "3m" | "6m" | "1y" | string,
) => {
    return new Promise<{ overview: ProgressReportType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `elementor/progress/report/user/${userId}/overview`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                period,
                type,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    overview: convertToReport(data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};
