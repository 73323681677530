import { userType } from "../../DTO";
import { Api } from "../api";

export const DeactivatePeerGroup = (user: userType, peerGroupId: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "patch",
            path: `peer_group/${peerGroupId}/deactivate`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    data,
                }),
            )
            .catch((error) => reject(error.data.message));
    });
};
