import { LessonType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToLessonType } from "./create";

export const ListLesson = (user: userType, currentPage: number) => {
    return new Promise<{ lesson: LessonType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "lesson",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) =>
                    resolve({
                        lesson: convertToLessonTypeList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    }),
                )
                .catch((error: any) => reject(error.data.message));
        },
    );
};

export const convertToLessonTypeList = (data: any[]): LessonType[] => {
    return data.map((element) => convertToLessonType(element));
};
