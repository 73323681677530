import { DataSlideType, SlideType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAttachmentsTypeList } from "../../peer-group/post";
import { convertToLessonType } from "../create";

export const CreateSlide = (
    user: userType,
    title: string,
    description: string,
    attachments: number[],
    timeToRead: number,
    lessonId: number,
) => {
    return new Promise<{ slide: DataSlideType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `lesson/slide`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                attachment_ids: attachments,
                time_to_read: timeToRead,
                lesson_id: lessonId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    slide: convertToDataSlideType(data.data),
                });
            })
            .catch((error: any) => {
                reject(error.data.message);
            });
    });
};

export const convertToSlideType = (data: any): SlideType => {
    let out: SlideType = {
        lesson: convertToLessonType(data?.lesson),
        data: convertToDataSlideTypeList(data?.data?.data),
    };

    return out;
};

export const convertToDataSlideTypeList = (data: any[]): DataSlideType[] => {
    return data.map((element) => convertToDataSlideType(element));
};

export const convertToDataSlideType = (data: any): DataSlideType => {
    let out: DataSlideType = {
        description: data?.description,
        lessonId: data?.lesson_id,
        timeToRead: data?.time_to_read,
        createdAt: data?.created_at,
        id: data?.id,
        title: data?.title,
        updatedAt: data?.updated_at,
        attachments: data?.attachments
            ? convertToAttachmentsTypeList(data?.attachments)
            : [],
        status: data?.status,
    };

    return out;
};
