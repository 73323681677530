import React from "react";
import { Provider } from "react-redux";

import { RouterPage } from "./Router/index";
import { store } from "./redux-config/store";
import "./index.scss";

function App() {
    return (
        <Provider store={store}>
            <RouterPage />
        </Provider>
    );
}

export default App;
