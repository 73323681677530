import { userType } from "../../DTO";
import { Api } from "../api";

export const deleteRole = (user: userType, roleID: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `roles/${roleID}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data) => {
                return resolve({
                    data,
                });
            })
            .catch((err) => {
                return reject({
                    err,
                });
            });
    });
};
