import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { Card, Carousel, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import {
    useAppNavigate,
    useAppPagination,
    useAppParams,
} from "../../Router/hook";
import { Loading, CustomBreadCrumb, CustomPagination } from "../../components";
import { PaginationType, SlideType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { LessonApi } from "../../api";
import { NotFound } from "../../assets/images";

export const LessonShow: FC = () => {
    const [lesson, setLesson] = useState<SlideType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [slidePagination, setSlidePagination] =
        useState<PaginationType>(useAppPagination());

    useEffect(() => {
        fetchSingleLesson(slidePagination.current_page);
    }, []);

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Lesson list", href: "/peer-group/list" },
        { name: "Info " + id, href: "#" },
    ];

    const fetchSingleLesson = (page: number) => {
        setLoading(true);

        LessonApi.SlideApi.ListSlide(user, Number(id), page)
            .then((data) => {
                setLesson(data.slides);
                setSlidePagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Lesson Show</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex my-2 flex-row flex-wrap rounded  px-md-3">
                    <div className="col-12 col-md-4  my-1 px-md-2">
                        <div className="p-4 shadow h-100 text-center text-nowrap rounded-4 shadow bg-custom-white ">
                            <Carousel>
                                {lesson?.lesson?.attachments &&
                                lesson?.lesson?.attachments?.length > 0 ? (
                                    lesson?.lesson?.attachments.map(
                                        (mediaItem) => (
                                            <Carousel.Item>
                                                <img
                                                    className="d-block w-100"
                                                    src={mediaItem.path}
                                                    alt={
                                                        mediaItem.attachableType
                                                    }
                                                    style={{
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </Carousel.Item>
                                        ),
                                    )
                                ) : (
                                    <img
                                        className="d-block w-100"
                                        src={NotFound}
                                        alt={"not found"}
                                        style={{
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 my-1  px-md-2">
                        <div className="rounded-4 p-3 shadow bg-custom-white h-100 d-flex gap-1 flex-column position-relative">
                            <strong
                                style={{ top: 10, right: -10 }}
                                className={`badge position-absolute ${
                                    lesson?.lesson.status === "active"
                                        ? "bg-success"
                                        : "bg-danger"
                                }`}
                            >
                                {lesson?.lesson.status}
                            </strong>
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout align-self-center text-center"
                                size="5x"
                                icon={faPeopleGroup}
                            />
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <strong className="text-center mt-2">
                                    {lesson?.lesson?.title}
                                </strong>

                                <div className="d-flex flex-row">
                                    <span>Created At:</span>
                                    <strong className="mx-1">
                                        {lesson?.lesson.createdAt.slice(0, 10)}{" "}
                                        {lesson?.lesson.createdAt.slice(11, 19)}
                                    </strong>
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex flex-row">
                                <span>Description:</span>
                                <strong className="mx-1 text-break">
                                    {lesson?.lesson.description}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="col-12 my-2 px-md-2 border-top border-3 p-1">
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex flex-row my-2 justify-content-between">
                                <h3 className="text-dark">Lesson Slides</h3>
                                <Link
                                    to={`/lesson/${id}/slide/new`}
                                    className="text-white btn btn-lg btn-success"
                                >
                                    Create Slide +
                                </Link>
                            </div>
                            <div className={"d-flex flex-row flex-wrap"}>
                                {lesson?.data &&
                                    lesson.data.length > 0 &&
                                    lesson.data.map((item, index: number) => (
                                        <div
                                            key={index}
                                            className={
                                                "col-12 col-md-6 col-lg-3 p-2"
                                            }
                                        >
                                            <Card
                                                className={
                                                    "h-100 position-relative bg-custom-white shadow rounded-4 p-md-4"
                                                }
                                            >
                                                <strong
                                                    className={`${
                                                        item.status === "active"
                                                            ? "bg-success"
                                                            : "bg-danger"
                                                    } badge  position-absolute`}
                                                    style={{
                                                        top: 10,
                                                        right: -10,
                                                    }}
                                                >
                                                    {item?.status}
                                                </strong>
                                                <Card.Body
                                                    className={
                                                        "d-flex flex-column gap-2"
                                                    }
                                                >
                                                    <div className={"col-12"}>
                                                        <Carousel
                                                            style={{
                                                                height: "200px",
                                                            }}
                                                        >
                                                            {item?.attachments &&
                                                            item?.attachments
                                                                ?.length > 0 ? (
                                                                item?.attachments.map(
                                                                    (
                                                                        mediaItem,
                                                                    ) => (
                                                                        <Carousel.Item>
                                                                            <img
                                                                                className="d-block w-100"
                                                                                src={
                                                                                    mediaItem.path
                                                                                }
                                                                                alt={
                                                                                    mediaItem.attachableType
                                                                                }
                                                                                style={{
                                                                                    objectFit:
                                                                                        "cover",
                                                                                    height: "200px",
                                                                                }}
                                                                            />
                                                                        </Carousel.Item>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={
                                                                        "/image/notfound.png"
                                                                    }
                                                                    alt={
                                                                        "not found"
                                                                    }
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                        height: "200px",
                                                                    }}
                                                                />
                                                            )}
                                                        </Carousel>
                                                    </div>
                                                    <div
                                                        className={`
                              : 'd-flex mt-2 flex-column gap-2 col-12'
                          `}
                                                    >
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <strong className="text-primary fa-2x">
                                                                {item?.title}
                                                            </strong>
                                                        </div>
                                                        <p
                                                            className={
                                                                "text-break text-custom-dark"
                                                            }
                                                        >
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                                <Card.Footer
                                                    className={
                                                        "bg-custom-white"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            "d-flex flex-row gap-2 justify-content-center align-items-center align-self-center"
                                                        }
                                                    >
                                                        <Link
                                                            to={`/lesson/${id}/slide/edit/${item.id}`}
                                                            className={`text-dark btn btn-warning`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                            />
                                                        </Link>
                                                        <Link
                                                            to={`/lesson/${id}/slide/info/${item.id}`}
                                                            className={`text-white btn bg-info`}
                                                        >
                                                            Info
                                                        </Link>
                                                        <button
                                                            className={`text-white btn ${
                                                                item.status ===
                                                                "active"
                                                                    ? "btn-danger"
                                                                    : "btn-success"
                                                            }`}
                                                            onClick={() => {
                                                                if (
                                                                    item.status ===
                                                                    "active"
                                                                ) {
                                                                    LessonApi.SlideApi.DeactivateSlide(
                                                                        user,
                                                                        Number(
                                                                            id,
                                                                        ),
                                                                        Number(
                                                                            item.id,
                                                                        ),
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                Swal.fire(
                                                                                    "Deacitvate!",
                                                                                    "Slide has been deactivate.",
                                                                                    "success",
                                                                                );
                                                                                fetchSingleLesson(
                                                                                    slidePagination.current_page,
                                                                                );
                                                                            },
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err,
                                                                            ) =>
                                                                                Swal.fire(
                                                                                    {
                                                                                        icon: "error",
                                                                                        text: err,
                                                                                    },
                                                                                ),
                                                                        );
                                                                } else {
                                                                    LessonApi.SlideApi.ActivateSlide(
                                                                        user,
                                                                        Number(
                                                                            id,
                                                                        ),
                                                                        Number(
                                                                            item.id,
                                                                        ),
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                Swal.fire(
                                                                                    "Acitvate!",
                                                                                    "Slide has been activate.",
                                                                                    "success",
                                                                                );
                                                                                fetchSingleLesson(
                                                                                    slidePagination.current_page,
                                                                                );
                                                                            },
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err,
                                                                            ) =>
                                                                                Swal.fire(
                                                                                    {
                                                                                        icon: "error",
                                                                                        text: err,
                                                                                    },
                                                                                ),
                                                                        );
                                                                }
                                                            }}
                                                        >
                                                            {item.status ===
                                                            "active"
                                                                ? "Deactivate"
                                                                : "Activate"}
                                                        </button>
                                                    </div>
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                    ))}
                            </div>
                            <CustomPagination
                                total={slidePagination.total}
                                perPage={slidePagination.per_page}
                                currentPage={slidePagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/lesson/info/${id}?slide_current_page=${pageNum}`,
                                    );
                                    fetchSingleLesson(pageNum);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
