import { userType } from "../../DTO";
import { convertAdminUserRoleList } from "../admin-user";
import { Api } from "../api";

export const FetchUserLogin = (email: string, password: string) => {
    return new Promise<{ data: userType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "auth/login",
            body: {
                email: email,
                password: password,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToUserType(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export function convertToUserType(item: any): userType {
    const returnUser: userType = {
        id: item?.id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        username: item?.username,
        phone: item?.phone,
        email: item?.email,
        phone_verified_at: item?.phone_verified_at,
        email_verified_at: item?.email_verified_at,
        last_login: item?.last_login,
        status: item?.status,
        access_token: item?.access_token,
        expires_in: item?.expires_in,
        permissions: item?.permissions,
        userId: item?.user,
        role: convertAdminUserRoleList(item?.role),
    };
    return returnUser;
}
