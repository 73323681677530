import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

import { PlanDayType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectWeek } from "../../redux-config/entities/week";
import { AppUserApi } from "../../api";
import { selectUser } from "../../redux-config/entities/user";

export const DayDetail: FC<{
    userId: number;
    currentDay: string;
    setCurrentDay: Function;
}> = ({ userId, currentDay, setCurrentDay }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [days, setDays] = useState<PlanDayType[]>([]);

    const week = useAppSelector(selectWeek);
    const user = useAppSelector(selectUser);

    const fetchCurrentWeekDays = () => {
        setLoader(true);

        AppUserApi.PlanDays(user, userId, week.index)
            .then((data) => {
                setDays(data.planDays);
                setCurrentDay(data.planDays[0].date.slice(0, 10));
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchCurrentWeekDays();
    }, []);

    const today = new Date().toISOString().slice(0, 10);

    return (
        <>
            {loader ? (
                <div className="d-flex flex-row p-5 justify-content-center align-items-center">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className="d-flex flex-row flex-wrap gap-3 w-100 p-2">
                    {days.map((item, index) => {
                        const isFutureDay = item.date.slice(0, 10) > today;
                        return (
                            <div
                                key={index}
                                onClick={() =>
                                    !isFutureDay &&
                                    setCurrentDay(item.date.slice(0, 10))
                                }
                                className={`rounded p-2 d-flex flex-column bg-custom-light gap-1 g-cursor-pointer ${
                                    currentDay === item.date.slice(0, 10)
                                        ? "border border-5"
                                        : ""
                                } ${
                                    isFutureDay
                                        ? "cursor-not-allowed opacity-50"
                                        : ""
                                }`}
                                style={{
                                    pointerEvents: isFutureDay
                                        ? "none"
                                        : "auto",
                                }}
                            >
                                <p>Day: {item.name}</p>
                                <p>Date: {item.date.slice(0, 10)}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
