import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { weekStoreType } from "./type";
import { PlanWeekType } from "../../../DTO";
import { setLocalItems } from "../../hooks";

export const WeekSlice = createSlice({
    initialState: initialState,
    name: "Week/slice",
    reducers: {
        setWeek: (
            state: weekStoreType,
            action: PayloadAction<PlanWeekType>,
        ) => {
            state.value = action.payload;
            setLocalItems("weekSelected", action.payload);
        },
    },
});

export const { setWeek } = WeekSlice.actions;

export const WeekReducer = WeekSlice.reducer;
