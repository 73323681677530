import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

import "./style.scss";
import {
    CustomPagination,
    Loading,
    CustomBreadCrumb,
} from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { RolesApi } from "../../../api";
import { PaginationType, rolesType } from "../../../DTO";
import { useAppPagination } from "../../../Router/hook";

export const UserAccessList = () => {
    const [rolesList, setRolesList] = useState<rolesType[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());

    const navigate = useNavigate();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Access list", href: "#" },
    ];

    useEffect(() => {
        fetchData(pagination.current_page);
    }, []);

    const fetchData = (page: number) => {
        setLoading(true);
        RolesApi.GetList(user, page)
            .then((data) => {
                setRolesList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Helmet>
                <title>D2Type | AccessList</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">Role table</h4>
                                <div className="d-flex flex-row ">
                                    <Link
                                        to={"new"}
                                        className="text-white btn btn-success mx-1 text-nowrap"
                                    >
                                        <span>Add Role</span>
                                        <FontAwesomeIcon
                                            className={"mx-1"}
                                            icon={faAdd}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="p-4 text-center text-nowrap">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rolesList.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="align-middle"
                                            >
                                                <td>
                                                    {index +
                                                        1 +
                                                        (pagination.current_page -
                                                            1) *
                                                            pagination.per_page}
                                                </td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <strong
                                                        className={`badge ${
                                                            item.status ===
                                                            "active"
                                                                ? "bg-success"
                                                                : "bg-danger"
                                                        }`}
                                                    >
                                                        {item.status}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                        <span
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: "Are you sure?",
                                                                    text: "You won't be able to revert this!",
                                                                    icon: "warning",
                                                                    showCancelButton:
                                                                        true,
                                                                    confirmButtonColor:
                                                                        "#3085d6",
                                                                    cancelButtonColor:
                                                                        "#d33",
                                                                    confirmButtonText:
                                                                        "Yes, delete it!",
                                                                }).then(
                                                                    (
                                                                        result,
                                                                    ) => {
                                                                        if (
                                                                            result.isConfirmed
                                                                        ) {
                                                                            RolesApi.deleteRole(
                                                                                user,
                                                                                item.id,
                                                                            ).then(
                                                                                (
                                                                                    data,
                                                                                ) => {
                                                                                    Swal.fire(
                                                                                        "Deleted!",
                                                                                        "Role has been deleted.",
                                                                                        "success",
                                                                                    );
                                                                                    fetchData(
                                                                                        pagination.current_page,
                                                                                    );
                                                                                },
                                                                            );
                                                                        }
                                                                    },
                                                                );
                                                            }}
                                                            className="text-white btn btn-sm btn-danger "
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                            />
                                                        </span>
                                                        <Link
                                                            to={`edit/${item.id}`}
                                                            className="text-dark btn btn-sm btn-warning"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                            />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <CustomPagination
                                    total={pagination.total}
                                    perPage={pagination.per_page}
                                    currentPage={pagination.current_page}
                                    changePage={(pageNum: number) => {
                                        navigate(
                                            `/UserAccess?current_page=${pageNum}`,
                                        );
                                        fetchData(pageNum);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
