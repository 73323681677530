import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { permissionsType } from "../../../DTO";
import { RolesApi } from "../../../api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux-config/entities/user";
import { Loading } from "../../../components";
import { Button, Form } from "react-bootstrap";

export const EditRole: React.FC = () => {
    const { id } = useParams();

    const user = useSelector(selectUser);
    const [permissionsList, setPermissionsList] = useState<permissionsType[]>(
        [],
    );
    const [loading, setLoading] = useState(false);
    const [selectedPermissionsId, setSelectedPermissionsId] = useState<
        number[]
    >([]);
    const [isAllCheck, setIsAllCheck] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const navigate = useNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Role list", href: "/UserAccess" },
        { name: "Edit ", href: "#" },
    ];

    useEffect(() => {
        fetchApi();
        fetchRoleID();
    }, []);

    const fetchRoleID = () => {
        if (id) {
            RolesApi.GetSingle(user, Number(id))
                .then((data) => {
                    setLoading(false);
                    setName(data.data.name);
                    const list: number[] = [];
                    data.data.permissions.map((mainItem) => {
                        mainItem.permissions.map((item) => [
                            list.push(item.id),
                        ]);
                    });

                    setSelectedPermissionsId(list);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        const allList = handleGetAllPermissionId();
        if (
            allList.length === selectedPermissionsId.length &&
            selectedPermissionsId.length !== 0
        ) {
            setIsAllCheck(true);
        } else {
            setIsAllCheck(false);
        }
    }, [selectedPermissionsId]);

    const fetchApi = () => {
        setLoading(true);
        RolesApi.GetPermissionList(user)
            .then((data) => {
                setPermissionsList(data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleCheckBoxChange = (id: number) => {
        if (selectedPermissionsId.indexOf(id) >= 0) {
            let list: number[] = selectedPermissionsId;
            const deleteList = list.filter((data) => data != id);
            setSelectedPermissionsId(deleteList);
        } else {
            setSelectedPermissionsId([...selectedPermissionsId, id]);
        }
    };

    const handleGetAllPermissionId = (): number[] => {
        let list: number[] = [];
        for (var i = 0; i < permissionsList.length; i++) {
            var permissions = permissionsList[i].permissions;
            for (var j = 0; j < permissions.length; j++) {
                var id = permissions[j].id;
                list.push(id);
            }
        }
        return list;
    };

    const handleAllClick = () => {
        let list = handleGetAllPermissionId();
        if (
            list.length !== selectedPermissionsId.length ||
            selectedPermissionsId.length === 0
        ) {
            setSelectedPermissionsId(list);
        } else {
            setSelectedPermissionsId([]);
        }
    };

    const handleIsCheck = (id: number): boolean => {
        if (selectedPermissionsId.indexOf(id) >= 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleCreateNewRoles = () => {
        if (id) {
            const data = {
                name: name,
                permissions: selectedPermissionsId.map((item) => {
                    return { id: item };
                }),
            };
            setLoading(true);
            RolesApi.Edit(user, Number(id), data)
                .then((data) => {
                    setLoading(false);
                    Swal.fire({
                        text: "Your Role was changed",
                        icon: "success",
                    }).then(() => {
                        navigate("/UserAccess");
                    });
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>D2Type | New Role</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Form className="d-flex flex-row flex-wrap">
                            <Form.Group
                                className="mx-2"
                                style={{ minWidth: "230px" }}
                            >
                                <Form.Label className="mb-0">Name</Form.Label>
                                <Form.Control
                                    disabled={name === "Coach"}
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                />
                            </Form.Group>
                        </Form>
                        <Form.Group className="d-flex flex-row justify-content-between">
                            <Form.Label
                                style={{ cursor: "pointer" }}
                                htmlFor={"selectAll"}
                            >
                                Select All
                            </Form.Label>
                            <Form.Check
                                name="group1"
                                type={"checkbox"}
                                className="mx-2"
                                id={"selectAll"}
                                onChange={() => {
                                    handleAllClick();
                                    setIsAllCheck(!isAllCheck);
                                }}
                                checked={isAllCheck}
                            />
                        </Form.Group>
                    </div>
                    <div className="d-flex flex-row flex-wrap ">
                        {permissionsList.map((item, index) => (
                            <div
                                className="d-flex flex-column col-12 col-md-6 col-lg-4 p-2"
                                key={index}
                            >
                                <strong>{item.group}</strong>
                                <div className="d-flex flex-column">
                                    {item?.permissions.map((data, index) => (
                                        <div
                                            className="d-flex flex-row gap-3"
                                            key={index}
                                        >
                                            <Form.Check
                                                style={{ cursor: "pointer" }}
                                                name="group1"
                                                type={"checkbox"}
                                                id={String(data.id)}
                                                onChange={(e) => {
                                                    handleCheckBoxChange(
                                                        data.id,
                                                    );
                                                }}
                                                checked={handleIsCheck(data.id)}
                                            />
                                            <Form.Group>
                                                <Form.Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    htmlFor={String(data.id)}
                                                >
                                                    {data.name}
                                                </Form.Label>
                                            </Form.Group>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <Button
                            disabled={
                                selectedPermissionsId.length <= 0 ||
                                name.length <= 1
                            }
                            onClick={() => {
                                handleCreateNewRoles();
                            }}
                        >
                            submit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
