import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { CustomPagination, Loading, CustomBreadCrumb } from "../../components";
import { LessonType, PaginationType, PeerGroupType } from "../../DTO";
import { useAppNavigate, useAppPagination } from "../../Router/hook";
import { LessonApi, PeerGroupApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";

export const LessonList: FC = () => {
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());
    const [lessons, setLessons] = useState<LessonType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Lesson list", href: "#" },
    ];

    const fetchLessonList = (page: number) => {
        setLoading(true);

        LessonApi.ListLesson(user, page)
            .then((data) => {
                setLessons(data.lesson);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleActivateLesson = (lessonId: number) => {
        LessonApi.ActivateLesson(user, lessonId)
            .then(() => {
                Swal.fire("Acitvate!", "Lesson has been activate.", "success");
                fetchLessonList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    const handleDeactivateLesson = (lessonId: number) => {
        LessonApi.DeactivateLesson(user, lessonId)
            .then(() => {
                Swal.fire(
                    "Deacitvate!",
                    "Lesson has been deactivate.",
                    "success",
                );
                fetchLessonList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    useEffect(() => {
        fetchLessonList(pagination.current_page);
    }, []);

    return (
        <>
            <Helmet>
                <title>D2Type | Lesson List</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">Lesson Table</h4>
                                <div className="d-flex flex-row gap-2">
                                    <Link
                                        className="text-white btn btn-success"
                                        to={"/lesson/new"}
                                    >
                                        Lesson Create+
                                    </Link>{" "}
                                </div>
                            </div>
                            <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th></th>
                                            <th>Title</th>
                                            <th>Time To Read</th>
                                            <th>Created At</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                        {lessons.length > 0 &&
                                            lessons.map((item, index) => (
                                                <tr
                                                    key={item.id}
                                                    className="align-middle"
                                                >
                                                    <td>
                                                        {index +
                                                            1 +
                                                            (pagination.current_page -
                                                                1) *
                                                                pagination.per_page}
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={
                                                                item.attachments
                                                                    .length > 0
                                                                    ? item
                                                                          .attachments[0]
                                                                          .path
                                                                    : ""
                                                            }
                                                            width={75}
                                                            height={75}
                                                            alt={
                                                                item.attachments
                                                                    .length > 0
                                                                    ? item
                                                                          .attachments[0]
                                                                          .attachableType
                                                                    : ""
                                                            }
                                                        />
                                                    </td>
                                                    <td>{item.title}</td>
                                                    <td>{item.timeToRead}</td>
                                                    <td>
                                                        {item?.createdAt.slice(
                                                            0,
                                                            10,
                                                        )}{" "}
                                                        {item?.createdAt.slice(
                                                            11,
                                                            19,
                                                        )}
                                                    </td>
                                                    <td>
                                                        <strong
                                                            className={`badge ${
                                                                item.status ===
                                                                "active"
                                                                    ? "bg-success"
                                                                    : "bg-danger"
                                                            }`}
                                                        >
                                                            {item.status}
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                            <Link
                                                                to={`/lesson/info/${item.id}`}
                                                                className="text-white btn btn-sm btn-info btn-sm"
                                                            >
                                                                Info
                                                            </Link>
                                                            <Link
                                                                to={`/lesson/edit/${item.id}`}
                                                                className="text-dark btn btn-warning btn-sm"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                />
                                                            </Link>
                                                            <Form.Switch
                                                                checked={
                                                                    item.status ===
                                                                    "active"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .checked
                                                                    ) {
                                                                        handleActivateLesson(
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                        );
                                                                    } else {
                                                                        handleDeactivateLesson(
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <button
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/lesson/${item.id}/slide/new`,
                                                                    )
                                                                }
                                                                className="text-white btn btn-secondary btn-sm"
                                                            >
                                                                Assign To Slide
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                                <CustomPagination
                                    total={pagination.total}
                                    perPage={pagination.per_page}
                                    currentPage={pagination.current_page}
                                    changePage={(pageNum: number) => {
                                        navigate(
                                            `/lesson/list?current_page=${pageNum}`,
                                        );
                                        fetchLessonList(pageNum);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
