import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { useAppNavigate } from "../../Router/hook";
import { Loading } from "../../components";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { PeerGroupForm } from "../../form";
import { PeerGroupApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";

export const PeerGroupCreate = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "/peer-group/list" },
        { name: "New", href: "#" },
    ];

    const handleCreatePeerGroup = (peerGroupName: string, coachId: number) => {
        setLoading(true);

        PeerGroupApi.CreatePeerGroup(user, peerGroupName, coachId)
            .then(() => {
                navigate(`/peer-group/list`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Peer Group New</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<PeerGroupForm.CreateEdit.FormValues>
                        validationSchema={PeerGroupForm.CreateEdit.ValidationSchema()}
                        initialValues={{
                            name: "",
                            coachId: 0,
                            coachName: "",
                        }}
                        onSubmit={(event) => {
                            handleCreatePeerGroup(event.name, event.coachId);
                        }}
                        component={(props: any) => (
                            <PeerGroupForm.CreateEdit.PeerGroupCreateOrEdit
                                submitTxt={"Submit"}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
