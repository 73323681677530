import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDiagramSuccessor,
    faPeopleGroup,
    faUser,
    faUserFriends,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./style.scss";
import { DashboardType } from "../../../DTO";
import { DashboardApi } from "../../../api";
import { Loading } from "../../../components";

export const Home = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<DashboardType>();

    const fetchDashboardHome = () => {
        setLoading(true);
        DashboardApi.DashboardHome(user)
            .then((data) => {
                setDashboard(data.dashboard);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchDashboardHome();
    }, []);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "#" },
    ];

    // const seriesLine = [
    //   {
    //     name: 'Sales',
    //     data: order && order.date,
    //   },
    // ]

    // const optionsLine: ApexOptions = {
    //   chart: {
    //     height: 350,
    //     type: 'line',
    //     toolbar: {
    //       show: true,
    //       offsetX: 0,
    //       offsetY: 0,
    //       tools: {
    //         download: false,
    //         selection: true,
    //         zoom: true,
    //         zoomin: true,
    //         zoomout: true,
    //         pan: true,
    //         customIcons: [],
    //       },
    //     },
    //   },
    //   stroke: {
    //     width: 5,
    //     curve: 'smooth',
    //   },
    //   xaxis: {
    //     type: 'datetime',
    //     categories: order && order.amount,
    //     tickAmount: 10,
    //     labels: {
    //       formatter: function (value, timestamp: number, opts) {
    //         return opts.dateFormatter(new Date(timestamp), 'dd MMM yyyy')
    //       },
    //     },
    //   },
    //   title: {
    //     text: 'Site Activity',
    //     align: 'left',
    //     style: {
    //       fontSize: '16px',
    //       color: '#666',
    //     },
    //   },
    //   fill: {
    //     type: 'gradient',
    //     gradient: {
    //       shade: 'dark',
    //       gradientToColors: ['#FDD835'],
    //       shadeIntensity: 1,
    //       type: 'horizontal',
    //       opacityFrom: 1,
    //       opacityTo: 1,
    //       stops: [0, 100, 100, 100],
    //     },
    //   },
    //   yaxis: {
    //     min: -10,
    //     max: 40,
    //   },
    // }

    return (
        <>
            <Helmet>
                <title>D2Type | Dashboard</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex row flex-wrap col-12 px-3">
                    <div className="col-12 col-md-6 col-lg-4 h-100 px-2">
                        <>
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="5x"
                                    icon={faUser}
                                />
                                <strong
                                    className={
                                        user.status === "active"
                                            ? "badge bg-success mt-2 align-items-center align-self-center"
                                            : "badge bg-danger mt-2 align-items-center align-self-center"
                                    }
                                >
                                    {user.status}
                                </strong>
                                <strong className="text-center mt-2">
                                    {user?.username}
                                </strong>
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-row gap-2">
                                        <span>Full Name:</span>
                                        <strong>
                                            {user?.first_name} {user?.last_name}
                                        </strong>
                                    </div>
                                    <hr />
                                    <div className="d-flex flex-row gap-2 flex-wrap ">
                                        <span>Role:</span>
                                        {user &&
                                            user.role &&
                                            user.role.length > 0 &&
                                            user.role.map((elementRole) => (
                                                <strong
                                                    key={elementRole.id}
                                                    className="text-primary text-capitalize text-center"
                                                >
                                                    {elementRole.name}
                                                </strong>
                                            ))}
                                    </div>
                                    <hr />
                                    <div className="d-flex flex-row gap-2">
                                        <span>Email:</span>
                                        <strong>{user?.email}</strong>
                                    </div>
                                    <div className="d-flex flex-row gap-2">
                                        <span>Phone Number:</span>
                                        <strong>{user?.phone}</strong>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                    <div className="d-flex flex-row flex-wrap rounded px-3 col-12 col-md-6 col-lg-8">
                        <div className="col-12 col-lg-6 p-2">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row align-items-center justify-content-between position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="3x"
                                    icon={faUsers}
                                />
                                <div>
                                    <span className="text-center m-2">
                                        Total User:
                                    </span>
                                    <span className="text-center m-2">
                                        {dashboard?.users}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 p-2">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="3x"
                                    icon={faUserFriends}
                                />
                                <div>
                                    <span className="text-center m-2">
                                        Total Coach:
                                    </span>
                                    <span className="text-center m-2">
                                        {dashboard?.coaches}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 p-2">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row  position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="3x"
                                    icon={faPeopleGroup}
                                />
                                <div>
                                    <span className="text-center m-2">
                                        Total Peer Groups:
                                    </span>
                                    <span className="text-center m-2">
                                        {dashboard?.peerGroups}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 p-2">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row  position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="3x"
                                    icon={faDiagramSuccessor}
                                />
                                <div>
                                    <span className="text-center m-2">
                                        Total Plan Order:
                                    </span>
                                    <span className="text-center m-2">
                                        {dashboard?.planOrders}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-12 col-lg-12 p-2 '>
          <div className='rounded p-3 shadow bg-custom-white h-100 d-flex flex-column flex-sm-row'>
            <div className=' w-100' style={{ direction: 'ltr' }}>
              <ApexCharts
                options={optionsLine}
                // @ts-ignore
                series={seriesLine}
                // @ts-ignore
                type='line'
                height={450}
              />
            </div>
          </div>
        </div> */}
            </div>
            {/* </div> */}
        </>
    );
};
