import { FC, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { ActivityLogType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectWeek } from "../../redux-config/entities/week";
import { selectUser } from "../../redux-config/entities/user";
import { AppUserApi } from "../../api";
import { convertTimeSecondsToHours, convertToPDTByLibrary } from "../../utils";
import { ReportActivityChart } from "../chart";

export const ActivityReport: FC<{ userId: number }> = ({ userId }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [activity, setActivity] = useState<ActivityLogType[]>([]);

    const week = useAppSelector(selectWeek);

    const user = useAppSelector(selectUser);

    const fetchProgressActivityReport = () => {
        setLoader(true);

        AppUserApi.ActivityLog(user, Number(userId), week.index)
            .then((data) => {
                setActivity(data.activity);
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchProgressActivityReport();
    }, [week]);

    return (
        <>
            {loader ? (
                <div className="d-flex flex-row justify-content-center align-items-center p-5 w-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className={"w-100 my-2 bg-custom-white p-2"}>
                    <Table hover responsive className={"text-center"}>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Total Step (per day)</th>
                                <th>Total Duration (per day)</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activity.length > 0 &&
                                activity.map((item, index) => (
                                    <tr key={item.id} className="align-middle">
                                        <td>{index + 1}</td>
                                        <td>{item.totalSteps}</td>
                                        <td>
                                            {Number(item.totalDurationTime) /
                                                60}{" "}
                                            min
                                        </td>
                                        <td>
                                            {item.createdAt && (
                                                <div
                                                    className={
                                                        "d-flex flex-column gap-2"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            "d-flex flex-row gap-2 justify-content-center"
                                                        }
                                                    >
                                                        <small>
                                                            {convertToPDTByLibrary(
                                                                item.createdAt,
                                                            ).slice(0, 10)}
                                                        </small>
                                                        <strong
                                                            className={
                                                                "text-danger"
                                                            }
                                                        >
                                                            {convertToPDTByLibrary(
                                                                item.createdAt,
                                                            ).slice(11, 19)}
                                                        </strong>
                                                        PDT
                                                    </div>
                                                    <div
                                                        className={
                                                            "d-flex flex-row gap-2 justify-content-center"
                                                        }
                                                    >
                                                        <small>
                                                            {item.createdAt.slice(
                                                                0,
                                                                10,
                                                            )}
                                                        </small>
                                                        <strong
                                                            className={
                                                                "text-danger"
                                                            }
                                                        >
                                                            {item.createdAt.slice(
                                                                11,
                                                                19,
                                                            )}
                                                        </strong>
                                                        UTC
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <ReportActivityChart activity={activity} />
                </div>
            )}
        </>
    );
};
