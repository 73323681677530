import { PaginationType, userType, RecipeType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToRecipeType } from "./create";

export const ListRecipe = (user: userType, currentPage: number) => {
    return new Promise<{ recipe: RecipeType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "recipe",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    resolve({
                        recipe: convertToRecipeTypeList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    });
                })
                .catch((err) => reject(err.data.message));
        },
    );
};

export const convertToRecipeTypeList = (data: any[]): RecipeType[] => {
    return data.map((element) => convertToRecipeType(element));
};
