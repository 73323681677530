import React from "react";

import "./style.scss";

export const Loading: React.FC = () => {
    return (
        <div
            className="position-absolute w-100 h-100 p-1  "
            style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 8,
            }}
        >
            <div className="w-100 h-100  d-flex flex-column justify-content-center align-items-center bg-custom-light">
                {/* <div className="spinner-box">
                    <div className="configure-border-1">
                        <div className="configure-core"></div>
                    </div>
                    <div className="configure-border-2">
                        <div className="configure-core"></div>
                    </div>
                </div> */}
                <div className="loading-wrapper">
                    <div className="loading-circle bg-custom-dark" />
                    <div className="loading-circle bg-custom-dark" />
                    <div className="loading-circle bg-custom-dark" />
                    <div className="loading-shadow bg-custom-black" />
                    <div className="loading-shadow bg-custom-black" />
                    <div className="loading-shadow bg-custom-black" />
                </div>
            </div>
        </div>
    );
};
