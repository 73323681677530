import { AdminUserType, userType } from "../../../DTO";
import { convertAdminUser } from "../../admin-user";
import { Api } from "../../api";

export const CoachPeerGroup = (user: userType, peerGroupId: number) => {
    return new Promise<{ coach: AdminUserType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `peer_group/${peerGroupId}/coach`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    coach: convertAdminUser(data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
