import { AdminUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertAdminUser } from "./list";

export const EditAdmin = (
    user: userType,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    userId: number,
    password?: string,
    confirmPassword?: string,
    // position: string,
) => {
    return new Promise<{ admin: AdminUserType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `auth/edit/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            body: {
                username,
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                password,
                password_confirmation: confirmPassword,
                status: "active",
                // position: position.length > 0 && position,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    admin: convertAdminUser(data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};
