import { permissionsType, userType } from "../../../DTO";
import { getLocalItems } from "../../hooks";
import { userStoreType } from "./type";

let user: userType = {
    id: 0,
    first_name: "",
    last_name: "",
    username: "",
    phone: "",
    email: "",
    phone_verified_at: "",
    email_verified_at: "",
    last_login: "",
    status: "",
    access_token: "",
    expires_in: 0,
    userId: 0,
    role: [],
};

if (
    JSON.parse(getLocalItems("user") + "") != null &&
    JSON.parse(getLocalItems("user") + "") != ""
) {
    user = JSON.parse(getLocalItems("user") + "");
}

let permissions: permissionsType[] = [];

if (
    JSON.parse(localStorage.getItem(`siteName-user-permissions`) + "") != null
) {
    permissions = JSON.parse(
        localStorage.getItem(`siteName-user-permissions`) + "",
    );
}

export const initialState: userStoreType = {
    value: { ...user, permissions: permissions },
    status: "idle",
};
