import { AppUserType, PaginationType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertAppUserList } from "../../app-user";
import { convertToPaginationType } from "../../help";

export const UserPeerGroup = (
    user: userType,
    peerGroupId: number,
    currentPage: number,
) => {
    return new Promise<{ user: AppUserType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `peer_group/${peerGroupId}/users`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) =>
                    resolve({
                        user: convertAppUserList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    }),
                )
                .catch((error) => reject(error.data.message));
        },
    );
};
