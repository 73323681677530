import { LessonType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToAttachmentsTypeList } from "../peer-group/post/create";

export const CreateLesson = (
    user: userType,
    title: string,
    description: string,
    attachments: number[],
    timeToRead: number,
) => {
    return new Promise<{ lesson: LessonType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "lesson",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                attachment_ids: attachments,
                time_to_read: timeToRead,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    lesson: convertToLessonType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToLessonType = (data: any): LessonType => {
    let out: LessonType = {
        createdAt: data?.created_at,
        description: data?.description,
        id: data?.id,
        status: data?.status,
        timeToRead: data?.time_to_read,
        title: data?.title,
        updatedAt: data?.updated_at,
        attachments: data?.attachments
            ? convertToAttachmentsTypeList(data?.attachments)
            : [],
    };

    return out;
};
