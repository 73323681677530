import React, { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Dropdown, Spinner } from "react-bootstrap";

import { PlanWeekType } from "../../DTO";
import { selectUser } from "../../redux-config/entities/user/selector";
import { useAppDispatch, useAppSelector } from "../../redux-config/hooks";
import { AppUserApi } from "../../api";
import { convertToPDTByLibrary } from "../../utils";
import { selectWeek, setWeek } from "../../redux-config/entities/week";

export const WeekList: FC<{ pageId: number }> = ({ pageId }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [weekList, setWeekList] = useState<PlanWeekType[]>([]);

    const user = useAppSelector(selectUser);

    const dispatch = useAppDispatch();

    const week = useAppSelector(selectWeek);

    const fetchPlanWeekList = () => {
        setLoader(true);

        AppUserApi.ListPlanUserWeek(user, Number(pageId), false)
            .then((data) => {
                setWeekList(data.plan);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchPlanWeekList();
    }, []);

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success">
                {loader ? (
                    <Spinner animation="border" size="sm" />
                ) : week && week.index > 0 ? (
                    `Week ${week.index} - ${week?.startDate.slice(
                        0,
                        10,
                    )} - ${week.endDate.slice(0, 10)}`
                ) : (
                    "Choose Week"
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu
                style={{ height: "20rem" }}
                className={"overflow-y-auto"}
            >
                {weekList.map((weekItem, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => {
                            dispatch(setWeek(weekItem));
                        }}
                    >
                        Week {weekItem.index} -{" "}
                        {weekItem.startDate.slice(0, 10)} -{" "}
                        {weekItem.endDate.slice(0, 10)}{" "}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
