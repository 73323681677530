import Chart from "react-apexcharts";

import { ApexOptions } from "apexcharts";
import React, { FC, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { UserSleepType } from "../../../DTO";
import { AppUserApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, Table } from "react-bootstrap";

export const UserProgressSleep: FC = () => {
    const { id, weekNumber } = useAppParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [sleep, setSleep] = useState<UserSleepType[]>([]);
    const [week, setWeek] = useState<number>(Number(weekNumber));

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user" },
        { name: "Sleep " + id, href: "#" },
    ];

    const fetchProgressReport = () => {
        setLoading(true);

        AppUserApi.UserSleep(user, Number(id), Number(weekNumber))
            .then((data) => {
                setSleep(data.sleep);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchProgressReport();
    }, [id, weekNumber]);

    return (
        <>
            <Helmet>
                <title>D2Type | Progress Sleep</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">Progress Sleep Table</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showWeekSearchInput"
                                className="text-white btn btn-info mx-1 text-nowrap"
                            >
                                <span>Search</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                        </div>
                    </div>
                    <input type="checkbox" id="showWeekSearchInput"></input>
                    <div className="weekSearchBox  p-2 flex-row justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        Week Number
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            setWeek(Number(e.target.value))
                                        }
                                        size="sm"
                                        type="number"
                                        placeholder="Please Enter Week Number"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button
                            className="text-white btn btn-sm btn-success mx-1 text-nowrap"
                            onClick={() =>
                                navigate(
                                    `/app-user/${Number(
                                        id,
                                    )}/progress/sleep/week/${week}`,
                                )
                            }
                        >
                            <span>Search</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sleep.length > 0 &&
                                    sleep.map((item, index) => (
                                        <tr
                                            key={item.id}
                                            className="align-middle"
                                        >
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.startDate.slice(0, 10)}{" "}
                                                {item.startDate.slice(11, 19)}
                                            </td>
                                            <td>
                                                {item.endDate.slice(0, 10)}{" "}
                                                {item.endDate.slice(11, 19)}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
