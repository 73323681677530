import moment from "moment-timezone";

export const calculateSleepTime = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDifference = end.getTime() - start.getTime();

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
};

export const convertTimeSecondsToHours = (timeSeconds: number): string => {
    const hours = Math.floor(timeSeconds / 3600);
    const minutes = Math.floor((timeSeconds % 3600) / 60);

    return `${hours} h : ${minutes} m`;
};

export const convertToPDT = (utcDateString: string) => {
    const utcDate = new Date(utcDateString);
    const utcOffset = utcDate.getTimezoneOffset();
    const pdtOffset = -7 * 60;
    const convertedDate = new Date(
        utcDate.getTime() + (pdtOffset - utcOffset) * 60000,
    );
    return convertedDate.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

export const convertToPDTByLibrary = (utcDateString: string) => {
    return moment
        .utc(utcDateString)
        .tz("America/Los_Angeles")
        .format("YYYY-MM-DD HH:mm:ss");
};

export const parseTime = (utcDate: string): number => {
    let hours = Number(convertToPDTByLibrary(utcDate).slice(11, 13));
    const minutes = Number(convertToPDTByLibrary(utcDate).slice(14, 16));

    if (hours === 0) {
        hours = 24; // Change 00:00 to 24:00
    }

    // Convert the time to a decimal number
    return Number(Number(hours + minutes / 60).toFixed(1));
};
