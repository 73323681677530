var applyNowElementsImMock = [
    {
        id: 1,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Hi, what you’d like to call you?",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 2,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "First name",
                hint: "Type your first name",
            },
        ],
    },
    {
        id: 3,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Email",
                hint: "Your email",
                textInputType: "emailAddress",
            },
        ],
    },
    {
        id: 4,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Your country",
        items: [
            { id: 1, label: "USA" },
            { id: 2, label: "Canada" },
        ],
    },
    {
        id: 5,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxTextButton",
        direction: "vertical",
        label: "",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                text: "I’ve read and agree to D2Type’s ",
                color: "0xFF01050E",
                url: "",
                texts: [
                    {
                        text: "Terms of Use ",
                        url: "https://terms.d2type.com/term-and-conditions/",
                        color: "0xFF5494B0",
                    },
                    { text: "and ", url: "", color: "0xFF01050E" },
                    {
                        text: "Privacy Policy.",
                        url: "https://terms.d2type.com/privacy-policy/",
                        color: "0xFF5494B0",
                    },
                ],
            },
            // {
            //   "id": 2,
            //   "text": "I’ve read and agree to the terms of ",
            //   "color": "0xFF01050E",
            //   "url": "",
            //   "texts": [
            //     {"text": "HIPAA Privacy Practices ", "url": "https://d2type.webnegah.com/hippa", "color": "0xFF5494B0"},
            //     {"text": "and ", "url": "", "color": "0xFF01050E"},
            //     {"text": "PIPEDA ", "url": "", "color": "0xFF01050E"},
            //     {"text": "in Canada.", "url": "", "color": "0xFF01050E"},
            //   ]
            // }
        ],
    },
    {
        id: 6,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Happy to see you!",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 7,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been diagnosed with Diabetes or Prediabetes?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 8,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
        ],
        width: 100,
        show: false,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "What was the result?",
        items: [
            { id: 1, label: "Prediabetes" },
            { id: 2, label: "Type 2 Diabetes" },
        ],
    },
    {
        id: 9,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
        ],
        width: 100,
        show: false,
        isRequired: false,
        widgetType: "file",
        direction: "horizontal",
        label: "Please upload your last test results",
        items: [
            {
                id: 1,
            },
            {
                id: 2,
            },
            {
                id: 3,
            },
        ],
    },
    {
        id: 10,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Let’s get to know you a little better!",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 11,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your primary reasons for being interested in D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Lose weight and keep it off" },
            { id: 2, label: "Getting control of blood sugar" },
            { id: 3, label: "Prevent diabetes and / or heart disease" },
            { id: 4, label: "Reverse diabetes" },
            { id: 5, label: "Increase my energy" },
            { id: 6, label: "Get strong / back in shape" },
            { id: 7, label: "Eat better / boost nutrition" },
            { id: 8, label: "Lower stress of diabetes" },
            { id: 9, label: "Other" },
        ],
    },
    {
        id: 12,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What can make it hard to focus on your health?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "I’m busy. Other priorities come first." },
            { id: 2, label: "I’m not sure where to start" },
            { id: 3, label: "I don't think anything will actually work." },
            { id: 4, label: "I get off to a fast start, then fade away." },
            { id: 5, label: "I can’t find / afford expert help" },
            { id: 6, label: "Eat better / boost nutrition" },
            { id: 7, label: "Other reasons" },
        ],
    },
    {
        id: 13,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "If losing weight is important to you, do you have a specific objective in mind?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 14,
        relation: [
            {
                elementId: 13,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Your ideal weight (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 144,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Select any items applicable to you",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 15,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 12 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                label: "Treated for an eating disorder other than binge eating disorder",
            },
        ],
    },
    {
        id: 16,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 6 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "An organ transplant (e.g. kidney or liver)?" },
            { id: 2, label: "Bariatric/gastric bypass surgery" },
            { id: 3, label: "Gastric sleeve surgery" },
            { id: 4, label: "Gastric balloon procedure" },
            {
                id: 5,
                label: "Intensive cancer treatment (bone marrow transplant, chemotherapy or radiation",
            },
        ],
    },
    {
        id: 17,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 3 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "A transient ischemic attack or stroke" },
            { id: 2, label: "A heart attack (myocardial infarction)" },
            { id: 3, label: "Hospital care for congestive heart failure" },
            {
                id: 4,
                label: "Cardiac surgery, such as coronary artery bypass grafting (CABG)",
            },
        ],
    },
    {
        id: 18,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "At the current time, I:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Am on dialysis treatment" },
            {
                id: 2,
                label: "Have an active alcohol or substance abuse/dependence",
            },
            {
                id: 3,
                label: "Am planning to undergo intensive cancer treatment",
            },
        ],
    },
    {
        id: 188,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: " ",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                label: "By selecting this box, you confirm that do you not have any of the above conditions.",
            },
        ],
    },
    {
        id: 19,
        relation: [],
        width: 100,
        show: true,
        widgetType: "image",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                width: 163.0,
                height: 104.0,
                path: "Assets.rejectImage",
            },
        ],
    },
    {
        id: 20,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                text: "Sorry, Can't Join the Program Right Now.",
                size: 15,
                color: "0xFFEB8A93",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 21,
        relation: [],
        width: 100,
        show: true,
        widgetType: "instruction",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Why?",
                description:
                    "Your current situation isn't suitable for online follow-ups. We recommend scheduling an in-person visit with your doctor",
                backgroundColor: "0xFFFDEDED",
                icon: "Assets.instructionImage",
            },
        ],
    },
    {
        id: 22,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                text: "Back to login page",
                size: 15,
                color: "0xFF5494B0",
                action: "backToLoginPage",
                link: "",
            },
        ],
    },
    {
        id: 23,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Tell us about your diabetes.",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 24,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How long have you lived with diabetes?",
        items: [
            {
                id: 1,
                label: "",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 25,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you on oral medication to manage blood sugar?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 26,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
            {
                elementId: 25,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Select all the oral medications that apply to you now.",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Metformin" },
            { id: 2, label: "Linagliptin" },
            { id: 3, label: "Exenatide" },
            { id: 4, label: "Jardiance- Empagliflozin" },
            { id: 5, label: "Acarbose" },
            { id: 6, label: "Juradice" },
            { id: 7, label: "Gliclazide" },
        ],
    },
    {
        id: 27,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you on Insulin to manage blood sugar?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 28,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
            {
                elementId: 27,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When did you start using Insulin?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 29,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When was the last time that you have been consulted by endocrinologist?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 30,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledButton",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What is your low fasting blood sugar?",
                hint: "0.0",
                textInputType: "number",
            },
        ],
    },
    {
        id: 31,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledButton",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What is you high fasting blood sugar?",
                hint: "0.0",
                textInputType: "number",
            },
        ],
    },
    {
        id: 32,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Please answer below questions:",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 33,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Age",
        items: [
            { id: 1, label: "44 years and earlier", point: 0 },
            { id: 2, label: "45–54 years", point: 7 },
            { id: 3, label: "55–64 years", point: 13 },
            { id: 4, label: "65–74 years", point: 15 },
        ],
    },
    {
        id: 34,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "What’s your gender, as listed on your insurance?",
        items: [
            { id: 1, label: "Female", point: 0 },
            { id: 2, label: "Male", point: 6 },
        ],
    },
    {
        id: 35,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "What’s your height?",
        items: [
            {
                id: 1,
                label: "ft",
                hint: "0.0 ft",
                selectedUnit: { label: "ft", unit: 1 },
                textInputType: "number",
            },
            {
                id: 2,
                label: "in",
                hint: "0.0 in",
                selectedUnit: { label: "in", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 36,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 70,
                label: "What’s your weight? (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 37,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "What is your Waist Circumference? (in)",
        items: [
            {
                id: 1,
                label: "in",
                hint: "0.0 in",
                selectedUnit: { label: "in", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 38,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you usually do some physical activity such as brisk walking for at least 30 minutes each day?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 1 },
        ],
    },
    {
        id: 39,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you eat vegetables or fruits every day?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 2 },
        ],
    },
    {
        id: 40,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been told by a doctor or nurse that you have high blood pressure OR have you ever taken high blood pressure pills?",
        items: [
            { id: 1, label: "Yes", point: 4 },
            { id: 2, label: "No/Don’t know", point: 0 },
        ],
    },
    {
        id: 41,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been found to have a high blood sugar either from a blood test, during an illness, or during pregnancy?",
        items: [
            { id: 1, label: "Yes", point: 14 },
            { id: 2, label: "No/Don’t know", point: 0 },
        ],
    },
    {
        id: 42,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "wrap",
        label: "Have you ever given birth to a large baby weighing 9 lbs (4.1 kg) or more?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No, don’t know, or not applicable", point: 0 },
        ],
    },
    {
        id: 43,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have any of your blood relatives ever been diagnosed with diabetes?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 44,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
            {
                elementId: 43,
                itemId: 1,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Which of your blood relatives ever been diagnosed with diabetes?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Mother", point: 2 },
            { id: 2, label: "Father", point: 2 },
            { id: 3, label: "Brothers/Sisters", point: 2 },
            { id: 4, label: "Children", point: 2 },
            { id: 5, label: "Other", point: 0 },
            { id: 6, label: "No/don’t know", point: 0 },
        ],
    },
    {
        id: 45,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Please check off which of the following ethnic groups your biological (blood) parents belong to:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "White (Caucasian)", point: 0 },
            {
                id: 2,
                label: "Aboriginal (First Nations Person, Métis, Inuit)",
                point: 3,
            },
            { id: 3, label: "Black (Afro-Caribbean)", point: 5 },
            {
                id: 4,
                label: "East Asian (Chinese, Vietnamese, Filipino, Korean, etc.)",
                point: 10,
            },
            {
                id: 5,
                label: "South Asian (East Indian, Pakistani, Sri Lankan, etc.)",
                point: 11,
            },
            {
                id: 6,
                label: "Other non-white (Latin American, Arab, West Asian)",
                point: 3,
            },
        ],
    },
    {
        id: 455,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What is the highest level of education that you have completed?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Some high school or less", point: 5 },
            { id: 2, label: "High school diploma", point: 1 },
            { id: 3, label: "Some college or university", point: 0 },
            { id: 4, label: "University or college degree", point: 0 },
        ],
    },
    {
        id: 46,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Please answer below questions:",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 47,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "How old are you?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Younger than 40 years", point: 0 },
            { id: 2, label: "40–49 years", point: 1 },
            { id: 3, label: "50–59 years", point: 2 },
            { id: 4, label: "60 years or older", point: 3 },
        ],
    },
    {
        id: 48,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you a man or a woman?",
        items: [
            { id: 1, label: "Man", point: 1 },
            { id: 2, label: "Woman", point: 0 },
        ],
    },
    {
        id: 49,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
            {
                elementId: 48,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "If you are a woman, have you ever been diagnosed with gestational diabetes?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 50,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a mother, father, sister, or brother with diabetes?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 51,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been diagnosed with high blood pressure?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 52,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you physically active?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 1 },
        ],
    },
    {
        id: 53,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "What’s your height?",
        items: [
            {
                id: 1,
                label: "ft",
                hint: "0.0 ft",
                selectedUnit: { label: "ft", unit: 1 },
                textInputType: "number",
            },
            {
                id: 2,
                label: "in",
                hint: "0.0 in",
                selectedUnit: { label: "in", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 54,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 110,
                label: "What’s your weight? (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 55,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Let’s check your insurance coverage",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 56,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "First name", hint: "Type your first name" }],
    },
    {
        id: 57,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "Last name", hint: "Type your last name" }],
    },
    {
        id: 58,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Mobile phone",
                hint: "e.g. 416-555-0184",
                textInputType: "text",
            },
        ],
    },
    {
        id: 59,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "datePicker",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "Date of birth", hint: "MM/DD/YYYY" }],
    },
    {
        id: 60,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "province",
        direction: "horizontal",
        label: "Province and City",
        items: [
            {
                id: 1,
                label: "Province and City",
            },
        ],
    },
    {
        id: 62,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Postal/Zip code",
                hint: "e.g. V1S 4L2",
                textInputType: "text",
            },
        ],
    },
    {
        id: 63,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Personal health member ID",
                hint: "Your member ID",
                textInputType: "text",
            },
        ],
    },
    {
        id: 64,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Finally, how did you hear about D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Email" },
            { id: 2, label: "Internal company website" },
            { id: 3, label: "Internal company announcement" },
            { id: 4, label: "Wellness/benefits fair" },
            { id: 5, label: "Doctor's office" },
            { id: 6, label: "Pharmacy" },
            { id: 7, label: "Facebook" },
            { id: 8, label: "Instagram" },
            { id: 9, label: "Internet search (eg Google, Yahoo, etc)" },
            { id: 10, label: "Friend, family or coworker" },
            { id: 11, label: "Other" },
        ],
    },
];

var applyNowElementsMetMock = [
    {
        id: 1,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Hi, what you’d like to call you?",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 2,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "First name",
                hint: "Type your first name",
            },
        ],
    },
    {
        id: 3,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Email",
                hint: "Your email",
                textInputType: "emailAddress",
            },
        ],
    },
    {
        id: 4,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Your country",
        items: [
            { id: 1, label: "USA" },
            { id: 2, label: "Canada" },
        ],
    },
    {
        id: 5,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxTextButton",
        direction: "vertical",
        label: "",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                text: "I’ve read and agree to D2Type’s ",
                color: "0xFF01050E",
                url: "",
                texts: [
                    {
                        text: "Terms of Use ",
                        url: "https://terms.d2type.com/term-and-conditions/",
                        color: "0xFF5494B0",
                    },
                    { text: "and ", url: "", color: "0xFF01050E" },
                    {
                        text: "Privacy Policy.",
                        url: "https://terms.d2type.com/privacy-policy/",
                        color: "0xFF5494B0",
                    },
                ],
            },
            // {
            //   "id": 2,
            //   "text": "I’ve read and agree to the terms of ",
            //   "color": "0xFF01050E",
            //   "url": "",
            //   "texts": [
            //     {"text": "HIPAA Privacy Practices ", "url": "https://d2type.webnegah.com/hippa", "color": "0xFF5494B0"},
            //     {"text": "and ", "url": "", "color": "0xFF01050E"},
            //     {"text": "PIPEDA ", "url": "", "color": "0xFF01050E"},
            //     {"text": "in Canada.", "url": "", "color": "0xFF01050E"},
            //   ]
            // }
        ],
    },
    {
        id: 6,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Happy to see you!",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 7,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been diagnosed with Diabetes or Prediabetes?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 8,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
        ],
        width: 100,
        show: false,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "What was the result?",
        items: [
            { id: 1, label: "Prediabetes" },
            { id: 2, label: "Type 2 Diabetes" },
        ],
    },
    {
        id: 9,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
        ],
        width: 100,
        show: false,
        isRequired: false,
        widgetType: "file",
        direction: "horizontal",
        label: "Please upload your last test results",
        items: [
            {
                id: 1,
            },
            {
                id: 2,
            },
            {
                id: 3,
            },
        ],
    },
    {
        id: 10,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Let’s get to know you a little better!",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 11,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your primary reasons for being interested in D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Lose weight and keep it off" },
            { id: 2, label: "Getting control of blood sugar" },
            { id: 3, label: "Prevent diabetes and / or heart disease" },
            { id: 4, label: "Reverse diabetes" },
            { id: 5, label: "Increase my energy" },
            { id: 6, label: "Get strong / back in shape" },
            { id: 7, label: "Eat better / boost nutrition" },
            { id: 8, label: "Lower stress of diabetes" },
            { id: 9, label: "Other" },
        ],
    },
    {
        id: 12,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What can make it hard to focus on your health?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "I’m busy. Other priorities come first." },
            { id: 2, label: "I’m not sure where to start" },
            { id: 3, label: "I don't think anything will actually work." },
            { id: 4, label: "I get off to a fast start, then fade away." },
            { id: 5, label: "I can’t find / afford expert help" },
            { id: 6, label: "Eat better / boost nutrition" },
            { id: 7, label: "Other reasons" },
        ],
    },
    {
        id: 13,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "If losing weight is important to you, do you have a specific objective in mind?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 14,
        relation: [
            {
                elementId: 13,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Your ideal weight (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 144,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Select any items applicable to you",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 15,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 12 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                label: "Treated for an eating disorder other than binge eating disorder",
            },
        ],
    },
    {
        id: 16,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 6 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "An organ transplant (e.g. kidney or liver)?" },
            { id: 2, label: "Bariatric/gastric bypass surgery" },
            { id: 3, label: "Gastric sleeve surgery" },
            { id: 4, label: "Gastric balloon procedure" },
            {
                id: 5,
                label: "Intensive cancer treatment (bone marrow transplant, chemotherapy or radiation",
            },
        ],
    },
    {
        id: 17,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "In the past 3 months, I’ve been:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "A transient ischemic attack or stroke" },
            { id: 2, label: "A heart attack (myocardial infarction)" },
            { id: 3, label: "Hospital care for congestive heart failure" },
            {
                id: 4,
                label: "Cardiac surgery, such as coronary artery bypass grafting (CABG)",
            },
        ],
    },
    {
        id: 18,
        relation: [],
        width: 100,
        show: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "At the current time, I:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Am on dialysis treatment" },
            {
                id: 2,
                label: "Have an active alcohol or substance abuse/dependence",
            },
            {
                id: 3,
                label: "Am planning to undergo intensive cancer treatment",
            },
        ],
    },
    {
        id: 188,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: " ",
        backgroundColor: "0xFFF8F7FA",
        items: [
            {
                id: 1,
                label: "By selecting this box, you confirm that do you not have any of the above conditions.",
            },
        ],
    },
    {
        id: 19,
        relation: [],
        width: 100,
        show: true,
        widgetType: "image",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                width: 163.0,
                height: 104.0,
                path: "Assets.rejectImage",
            },
        ],
    },
    {
        id: 20,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                text: "Sorry, Can't Join the Program Right Now.",
                size: 15,
                color: "0xFFEB8A93",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 21,
        relation: [],
        width: 100,
        show: true,
        widgetType: "instruction",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Why?",
                description:
                    "Your current situation isn't suitable for online follow-ups. We recommend scheduling an in-person visit with your doctor",
                backgroundColor: "0xFFFDEDED",
                icon: "Assets.instructionImage",
            },
        ],
    },
    {
        id: 22,
        relation: [],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "vertical",
        label: "",
        items: [
            {
                id: 1,
                text: "Back to login page",
                size: 15,
                color: "0xFF5494B0",
                action: "backToLoginPage",
                link: "",
            },
        ],
    },
    {
        id: 23,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Tell us about your diabetes.",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 24,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How long have you lived with diabetes?",
        items: [
            {
                id: 1,
                label: "",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 25,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you on oral medication to manage blood sugar?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 26,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
            {
                elementId: 25,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Select all the oral medications that apply to you now.",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Metformin" },
            { id: 2, label: "Linagliptin" },
            { id: 3, label: "Exenatide" },
            { id: 4, label: "Jardiance- Empagliflozin" },
            { id: 5, label: "Acarbose" },
            { id: 6, label: "Juradice" },
            { id: 7, label: "Gliclazide" },
        ],
    },
    {
        id: 27,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you on Insulin to manage blood sugar?",
        items: [
            { id: 1, label: "No" },
            { id: 2, label: "Yes" },
        ],
    },
    {
        id: 28,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
            {
                elementId: 27,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When did you start using Insulin?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 29,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When was the last time that you have been consulted by endocrinologist?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "date", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 30,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledButton",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What is your low fasting blood sugar?",
                hint: "0.0",
                textInputType: "number",
            },
        ],
    },
    {
        id: 31,
        relation: [
            {
                elementId: 7,
                itemId: 1,
            },
            {
                elementId: 8,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledButton",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What is you high fasting blood sugar?",
                hint: "0.0",
                textInputType: "text",
            },
        ],
    },
    {
        id: 32,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Please answer below questions:",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 33,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Age",
        items: [
            { id: 1, label: "44 years and earlier", point: 0 },
            { id: 2, label: "45–54 years", point: 7 },
            { id: 3, label: "55–64 years", point: 13 },
            { id: 4, label: "65–74 years", point: 15 },
        ],
    },
    {
        id: 34,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "What’s your gender, as listed on your insurance?",
        items: [
            { id: 1, label: "Female", point: 0 },
            { id: 2, label: "Male", point: 6 },
        ],
    },
    {
        id: 35,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your height? (cm)",
                hint: "0.0",
                selectedUnit: { label: "cm", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 36,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your weight? (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 37,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What is your Waist Circumference? (cm)",
                hint: "0.0",
                selectedUnit: { label: "cm", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 38,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you usually do some physical activity such as brisk walking for at least 30 minutes each day?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 1 },
        ],
    },
    {
        id: 39,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you eat vegetables or fruits every day?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 2 },
        ],
    },
    {
        id: 40,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been told by a doctor or nurse that you have high blood pressure OR have you ever taken high blood pressure pills?",
        items: [
            { id: 1, label: "Yes", point: 4 },
            { id: 2, label: "No/Don’t know", point: 0 },
        ],
    },
    {
        id: 41,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been found to have a high blood sugar either from a blood test, during an illness, or during pregnancy?",
        items: [
            { id: 1, label: "Yes", point: 14 },
            { id: 2, label: "No/Don’t know", point: 0 },
        ],
    },
    {
        id: 42,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "wrap",
        label: "Have you ever given birth to a large baby weighing 9 lbs (4.1 kg) or more?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No, don’t know, or not applicable ", point: 0 },
        ],
    },
    {
        id: 43,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have any of your blood relatives ever been diagnosed with diabetes?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 44,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
            {
                elementId: 43,
                itemId: 1,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Which of your blood relatives ever been diagnosed with diabetes?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Mother", point: 2 },
            { id: 2, label: "Father", point: 2 },
            { id: 3, label: "Brothers/Sisters", point: 2 },
            { id: 4, label: "Children", point: 2 },
            { id: 5, label: "Other", point: 0 },
            { id: 6, label: "No/don’t know", point: 0 },
        ],
    },
    {
        id: 45,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Please check off which of the following ethnic groups your biological (blood) parents belong to:",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "White (Caucasian)", point: 0 },
            {
                id: 2,
                label: "Aboriginal (First Nations Person, Métis, Inuit)",
                point: 3,
            },
            { id: 3, label: "Black (Afro-Caribbean)", point: 5 },
            {
                id: 4,
                label: "East Asian (Chinese, Vietnamese, Filipino, Korean, etc.)",
                point: 10,
            },
            {
                id: 5,
                label: "South Asian (East Indian, Pakistani, Sri Lankan, etc.)",
                point: 11,
            },
            {
                id: 6,
                label: "Other non-white (Latin American, Arab, West Asian)",
                point: 3,
            },
        ],
    },
    {
        id: 455,
        relation: [
            {
                elementId: 4,
                itemId: 2,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What is the highest level of education that you have completed?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Some high school or less", point: 5 },
            { id: 2, label: "High school diploma", point: 1 },
            { id: 3, label: "Some college or university", point: 0 },
            { id: 4, label: "University or college degree", point: 0 },
        ],
    },
    {
        id: 46,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Please answer below questions:",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 47,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "How old are you?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Younger than 40 years", point: 0 },
            { id: 2, label: "40–49 years", point: 1 },
            { id: 3, label: "50–59 years", point: 2 },
            { id: 4, label: "60 years or older", point: 3 },
        ],
    },
    {
        id: 48,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you a man or a woman?",
        items: [
            { id: 1, label: "Man", point: 1 },
            { id: 2, label: "Woman", point: 0 },
        ],
    },
    {
        id: 49,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
            {
                elementId: 48,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "If you are a woman, have you ever been diagnosed with gestational diabetes?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 50,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a mother, father, sister, or brother with diabetes?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 51,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you ever been diagnosed with high blood pressure?",
        items: [
            { id: 1, label: "Yes", point: 1 },
            { id: 2, label: "No", point: 0 },
        ],
    },
    {
        id: 52,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Are you physically active?",
        items: [
            { id: 1, label: "Yes", point: 0 },
            { id: 2, label: "No", point: 1 },
        ],
    },
    {
        id: 53,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your height? (cm)",
                hint: "0.0",
                selectedUnit: { label: "cm", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 54,
        relation: [
            {
                elementId: 4,
                itemId: 1,
            },
            {
                elementId: 7,
                itemId: 2,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 110,
                label: "What’s your weight? (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 55,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "Let’s check your insurance coverage",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 56,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "First name", hint: "Type your first name" }],
    },
    {
        id: 57,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "Last name", hint: "Type your last name" }],
    },
    {
        id: 58,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Mobile phone",
                hint: "e.g. 416-555-0184",
                textInputType: "text",
            },
        ],
    },
    {
        id: 59,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "datePicker",
        direction: "horizontal",
        label: "",
        items: [{ id: 1, label: "Date of birth", hint: "MM/DD/YYYY" }],
    },
    {
        id: 60,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "province",
        direction: "horizontal",
        label: "Province and City",
        items: [
            {
                id: 1,
                label: "Province and City",
            },
        ],
    },
    {
        id: 62,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Postal/Zip code",
                hint: "e.g. V1S 4L2",
                textInputType: "text",
            },
        ],
    },
    {
        id: 63,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiled",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "Personal health member ID",
                hint: "Your member ID",
                textInputType: "text",
            },
        ],
    },
    {
        id: 64,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "Finally, how did you hear about D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Email" },
            { id: 2, label: "Internal company website" },
            { id: 3, label: "Internal company announcement" },
            { id: 4, label: "Wellness/benefits fair" },
            { id: 5, label: "Doctor's office" },
            { id: 6, label: "Pharmacy" },
            { id: 7, label: "Facebook" },
            { id: 8, label: "Instagram" },
            { id: 9, label: "Internet search (eg Google, Yahoo, etc)" },
            { id: 10, label: "Friend, family or coworker" },
            { id: 11, label: "Other" },
        ],
    },
];
var questionnairesImMock = [
    {
        id: 1,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "To provide your personalized plan, please tell us a little about your health status and your goals.",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 2,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your primary reasons for being interested in D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Overweight" },
            { id: 2, label: "Prediabetes" },
            { id: 3, label: "High blood pressure" },
            { id: 4, label: "High blood lipids" },
            { id: 5, label: "Fatty liver" },
            { id: 6, label: "Belly fat" },
            { id: 7, label: "Hypothyroidism" },
            { id: 8, label: "PCOs (in women)" },
            { id: 9, label: "Depression" },
            { id: 10, label: "Anxiety" },
        ],
    },
    {
        id: 3,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your motivators to sign up to D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Lose weight and keep it off" },
            { id: 2, label: "Learn to eat healthier and stay healthy" },
            { id: 3, label: "Keep fitness and have better body shape" },
            { id: 4, label: "Be active" },
            { id: 5, label: "Feel more energized and improve confidence" },
            { id: 6, label: "Prevent diabetes and / or heart disease" },
            { id: 7, label: "Reduce the chance of higher medical bill" },
            { id: 8, label: "Take less medication" },
            { id: 9, label: "Other" },
        ],
    },
    {
        id: 4,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "What’s your height?",
        items: [
            {
                id: 1,
                label: "ft",
                hint: "0.0 ft",
                selectedUnit: { label: "ft", unit: 1 },
                textInputType: "number",
            },
            {
                id: 1,
                label: "in",
                hint: "0.0 in",
                selectedUnit: { label: "in", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 5,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your weight? (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 6,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Is losing weight one of your issue?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 7,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What your target weight? (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 8,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you had any history of dieting?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 9,
        relation: [
            {
                elementId: 8,
                itemId: 1,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When was your last diet?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "moreThanDate", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 10,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "How much did you lose on the last diet? (lb)",
                hint: "0.0",
                selectedUnit: { label: "lb", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 11,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        hasGuide: true,
        guideText:
            "Sedentary:\n   Definition: Limited physical activity, mostly sitting.\n   Example: Office work with minimal exercise.\n\nLightly Active:\n   Definition: Some daily movement, but not intense.\n   Example: Regular walks, light house chores.\n\nActive:\n   Definition: Regular moderate exercise or intense daily activities.\n   Example: Jogging, cycling, active job roles.\n\nVery Active:\n   Definition: High levels of intense physical activity.\n   Example: Athletes, heavy labor, rigorous training.",
        widgetType: "radioButton",
        direction: "wrap",
        label: "What is your activity level?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Sedentary" },
            { id: 2, label: "Lightly Active" },
            { id: 3, label: "Active" },
            { id: 4, label: "Very Active" },
        ],
    },
    {
        id: 12,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Did you or have you used tobacco or cigarettes regularly?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 13,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with Heart diseases?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 14,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with hypertension?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 15,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with high cholesterol?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 16,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When do you usually wake up in the morning?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 17,
        relation: [],
        width: 100,
        show: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When do you usually go to bed at night?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 18,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How many hours do you usually sleep each day?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", type: "12", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 19,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How many hours do you spend using technology (TV, Phone, computer,…)?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fifteenDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 20,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you usually eat while watching the TV or your mobile?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 21,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How much time do you spend on social media every day?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fifteenDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 22,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How long does it take you to eat each meal?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fiveDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 23,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        hasGuide: true,
        guideText:
            "Rarely: once a month or less\nSometimes: once a week or a few times a month.\nUsually: multiple times a week or more.",
        widgetType: "radioButton",
        direction: "wrap",
        label: "How often do you eat Fast Foods?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Never" },
            { id: 2, label: "Sometimes" },
            { id: 3, label: "Usually" },
            { id: 4, label: "Every day" },
        ],
    },
    {
        id: 24,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What kinds of fast food do you usually eat?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Fries" },
            { id: 2, label: "Hot Dogs" },
            { id: 3, label: "Burgers" },
            { id: 4, label: "Pizza" },
            { id: 5, label: "None" },
        ],
    },
    {
        id: 25,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 55,
                label: "In the last month, how often have you been upset because of something that happened unexpectedly?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 26,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 56,
                label: "In the last month, how often have you felt that you were unable to control the important things in your life?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 27,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 57,
                label: "In the last month, how often have you felt nervous and stressed?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 28,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 58,
                label: "In the last month, how often have you felt confident about your ability to handle your personal problems?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 29,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 59,
                label: "In the last month, how often have you felt that things were going your way?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 30,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 60,
                label: "In the last month, how often have you found that you could not cope with all the things that you had to do?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 31,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 61,
                label: "In the last month, how often have you been able to control irritations in your life?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 32,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 62,
                label: "In the last month, how often have you felt that you were on top of things?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 33,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 63,
                label: "In the last month, how often have you been angered because of things that happened that were outside of your control?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 34,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 64,
                label: "In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
];

var questionnairesMetMock = [
    {
        id: 1,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "text",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                text: "To provide your personalized plan, please tell us a little about your health status and your goals.",
                size: 14,
                color: "0xFF01050E",
                action: "",
                link: "",
            },
        ],
    },
    {
        id: 2,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your primary reasons for being interested in D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Overweight" },
            { id: 2, label: "Prediabetes" },
            { id: 3, label: "High blood pressure" },
            { id: 4, label: "High blood lipids" },
            { id: 5, label: "Fatty liver" },
            { id: 6, label: "Belly fat" },
            { id: 7, label: "Hypothyroidism" },
            { id: 8, label: "PCOs (in women)" },
            { id: 9, label: "Depression" },
            { id: 10, label: "Anxiety" },
        ],
    },
    {
        id: 3,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "vertical",
        label: "What are your motivators to sign up to D2Type?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Lose weight and keep it off" },
            { id: 2, label: "Learn to eat healthier and stay healthy" },
            { id: 3, label: "Keep fitness and have better body shape" },
            { id: 4, label: "Be active" },
            { id: 5, label: "Feel more energized and improve confidence" },
            { id: 6, label: "Prevent diabetes and / or heart disease" },
            { id: 7, label: "Reduce the chance of higher medical bill" },
            { id: 8, label: "Take less medication" },
            { id: 9, label: "Other" },
        ],
    },
    {
        id: 4,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your height? (cm)",
                hint: "0.0",
                selectedUnit: { label: "cm", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 5,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What’s your weight? (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 6,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Is losing weight one of your issue?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 7,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "What your target weight? (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 8,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Have you had any history of dieting?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 9,
        relation: [
            {
                elementId: 8,
                itemId: 1,
            },
        ],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When was your last diet?",
        items: [
            {
                id: 1,
                label: "Years/Months ago",
                hint: "yy-mm",
                selectedUnit: { label: "moreThanDate", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 10,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "How much did you lose on the last diet? (kg)",
                hint: "0.0",
                selectedUnit: { label: "kg", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 11,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        hasGuide: true,
        guideText:
            "Sedentary:\n   Definition: Limited physical activity, mostly sitting.\n   Example: Office work with minimal exercise.\n\nLightly Active:\n   Definition: Some daily movement, but not intense.\n   Example: Regular walks, light house chores.\n\nActive:\n   Definition: Regular moderate exercise or intense daily activities.\n   Example: Jogging, cycling, active job roles.\n\nVery Active:\n   Definition: High levels of intense physical activity.\n   Example: Athletes, heavy labor, rigorous training.",
        widgetType: "radioButton",
        direction: "wrap",
        label: "What is your activity level?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Sedentary" },
            { id: 2, label: "Lightly Active" },
            { id: 3, label: "Active" },
            { id: 4, label: "Very Active" },
        ],
    },
    {
        id: 12,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Did you or have you used tobacco or cigarettes regularly?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 13,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with Heart diseases?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 14,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with hypertension?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 15,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you have a parent or sibling with high cholesterol?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No/Don’t know" },
        ],
    },
    {
        id: 16,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When do you usually wake up in the morning?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 17,
        relation: [],
        width: 100,
        show: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "When do you usually go to bed at night?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 18,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How many hours do you usually sleep each day?",
        items: [
            {
                id: 1,
                label: "",
                hint: "hh:mm",
                selectedUnit: { label: "time", type: "12", unit: 1 },
                textInputType: "number",
            },
        ],
    },
    {
        id: 19,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How many hours do you spend using technology (TV, Phone, computer,…)?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fifteenDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 20,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "radioButton",
        direction: "horizontal",
        label: "Do you usually eat while watching the TV or your mobile?",
        items: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
        ],
    },
    {
        id: 21,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How much time do you spend on social media every day?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fifteenDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 22,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "textFiledUnit",
        direction: "horizontal",
        label: "How long does it take you to eat each meal?",
        items: [
            {
                id: 1,
                label: "Minutes",
                hint: "minutes",
                selectedUnit: {
                    label: "minute",
                    type: "fiveDivision",
                    unit: 1,
                },
                textInputType: "number",
            },
        ],
    },
    {
        id: 23,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        hasGuide: true,
        guideText:
            "Rarely: once a month or less\nSometimes: once a week or a few times a month.\nUsually: multiple times a week or more.",
        widgetType: "radioButton",
        direction: "wrap",
        label: "How often do you eat Fast Foods?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Never" },
            { id: 2, label: "Sometimes" },
            { id: 3, label: "Usually" },
            { id: 4, label: "Every day" },
        ],
    },
    {
        id: 24,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "checkBoxButton",
        direction: "wrap",
        label: "What kinds of fast food do you usually eat?",
        backgroundColor: "0xFFF8F7FA",
        items: [
            { id: 1, label: "Fries" },
            { id: 2, label: "Hot Dogs" },
            { id: 3, label: "Burgers" },
            { id: 4, label: "Pizza" },
            { id: 5, label: "None" },
        ],
    },
    {
        id: 25,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you been upset because of something that happened unexpectedly?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 26,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt that you were unable to control the important things in your life?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 27,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt nervous and stressed?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 28,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt confident about your ability to handle your personal problems?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 29,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt that things were going your way?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 30,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you found that you could not cope with all the things that you had to do?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 31,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you been able to control irritations in your life?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 32,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt that you were on top of things?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 33,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you been angered because of things that happened that were outside of your control?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
    {
        id: 34,
        relation: [],
        width: 100,
        show: true,
        isRequired: true,
        widgetType: "sliderString",
        direction: "horizontal",
        label: "",
        items: [
            {
                id: 1,
                label: "In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?",
                min: 0.0,
                max: 4.0,
                divisions: 4,
                values: [
                    "Never",
                    "Sometimes",
                    "Usually",
                    "Often",
                    "Very often",
                ],
            },
        ],
    },
];

export const findQuestionByAnswers = (
    type: "personal" | "questionnaires",
    unit: "Metric" | "US Standard",
    descriptionId: number,
) => {
    let questionTitle: {
        type: string;
        title: string;
        answer: { label: string | string[]; id: number }[];
    }[] = [];
    if (unit === "Metric") {
        (type === "personal"
            ? applyNowElementsMetMock
            : questionnairesMetMock
        ).map((mockItem) => {
            switch (mockItem.widgetType) {
                case "text":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "text",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "textFiled":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiled",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "radioButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "radioButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "file":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "file",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: "", id: element.id };
                            }),
                        })
                    );
                case "checkBoxButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "checkBoxButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "checkBoxTextButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "checkBoxTextButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.text, id: element.id };
                            }),
                        })
                    );
                case "textFiledUnit":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiledUnit",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "image":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "image",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: "", id: element.id };
                            }),
                        })
                    );
                case "instruction":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "instruction",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: `${element.label} -> ${element.description}`,
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "textFiledButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiledButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "datePicker":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "datePicker",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: element.label,
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "sliderString":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "sliderString",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: element.values.map((item: any) => {
                                        return item;
                                    }),
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "province":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "province",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: "",
                                    id: element.id,
                                };
                            }),
                        })
                    );
            }
        });
    } else if (unit === "US Standard") {
        (type === "personal"
            ? applyNowElementsImMock
            : questionnairesImMock
        ).map((mockItem) => {
            switch (mockItem.widgetType) {
                case "text":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "text",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "textFiled":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiled",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "radioButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "radioButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "file":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "file",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: "", id: element.id };
                            }),
                        })
                    );
                case "checkBoxButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "checkBoxButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "checkBoxTextButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "checkBoxTextButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.text, id: element.id };
                            }),
                        })
                    );
                case "textFiledUnit":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiledUnit",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "image":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "image",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: "", id: element.id };
                            }),
                        })
                    );
                case "instruction":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "instruction",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: `${element.label} -> ${element.description}`,
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "textFiledButton":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "textFiledButton",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return { label: element.label, id: element.id };
                            }),
                        })
                    );
                case "datePicker":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "datePicker",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: element.label,
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "sliderString":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "sliderString",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: element.values.map((item: any) => {
                                        return item;
                                    }),
                                    id: element.id,
                                };
                            }),
                        })
                    );
                case "province":
                    return (
                        mockItem.id === descriptionId &&
                        questionTitle.push({
                            type: "province",
                            title: mockItem.label,
                            answer: mockItem.items.map((element: any) => {
                                return {
                                    label: "",
                                    id: element.id,
                                };
                            }),
                        })
                    );
            }
        });
    }
    return questionTitle;
};
