import { AppUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertAppUser } from "./list";

export const ShowAppUser = (user: userType, id: number) => {
    return new Promise<{ user: AppUserType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `users/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    user: convertAppUser(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err.message);
            });
    });
};
