//@ts-nocheck
import React, { FC } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

import { UserSleepType } from "../../DTO";
import { convertToPDTByLibrary, parseTime } from "../../utils";
import { useAppSelector } from "../../redux-config/hooks";
import { selectDarkMode } from "../../redux-config/entities/dark-mode";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const ReportSleepChart: FC<{ sleep: UserSleepType[] }> = ({ sleep }) => {
    const darkMode = useAppSelector(selectDarkMode);

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const handleDifferentTime = (start: string, end: string): string => {
        const timestamp1 = new Date(start).getTime();
        const timestamp2 = new Date(end).getTime();

        const timeDifferenceMs = timestamp2 - timestamp1;

        const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
        const minutes = Math.floor(
            (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60),
        );

        return `${hours} h ${minutes} m`;
    };

    const chartData = sleep.map((sleepItem, index) => ({
        sleepTime: handleDifferentTime(sleepItem.startDate, sleepItem.endDate),
        endTime: convertToPDTByLibrary(sleepItem.endDate).slice(11, 19),
        startTime: convertToPDTByLibrary(sleepItem.startDate).slice(11, 19),
        label: daysOfWeek[new Date(sleepItem.startDate).getDay()],
        y: [parseTime(sleepItem.startDate), parseTime(sleepItem.endDate)],
    }));

    if (chartData.length > 0) {
        const options = {
            theme: darkMode ? "dark2" : "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "Weekly Average Sleep Chart",
            },
            axisX: {
                title: "Day of Week",
            },
            axisY: {
                title: "Average Sleep Duration (hours)",
                includeZero: true,
                // suffix: "h",
                interval: 5, // Interval of 1 hour
                valueFormatString: "0", // Format Y-axis values to one decimal place
                minimum: 1, // Set the minimum value to 0 hours
                maximum: 24,
            },
            data: [
                {
                    type: "rangeColumn",
                    indexLabel: "{y[#index]}",
                    // xValueFormatString: "DD MMM YYYY",
                    toolTipContent:
                        "<strong>{label}</strong> <br/> Start Time: {startTime} (PDT)</br> End Time: {endTime} (PDT)<br /> Duration Time: {sleepTime}",
                    dataPoints: chartData,
                },
            ],
        };
        return <CanvasJSChart options={options} />;
    }
};
