import { userType } from "../../DTO";
import { Api } from "../api";

export const ChangeUserActiveDeactivate = (user: userType, userID: string) => {
    return new Promise<{ data: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "patch",
            path: `users/${userID}/status`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                id: userID,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};
