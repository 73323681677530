import { PaginationType, PostType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";
import { convertToPostType } from "./create";

export const ListPost = (
    user: userType,
    peerGroupId: number,
    currentPage: number,
) => {
    return new Promise<{ posts: PostType; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `peer_group/${peerGroupId}/posts`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) =>
                    resolve({
                        posts: convertToPostType(data),
                        pagination: convertToPaginationType(data.data),
                    }),
                )
                .catch((error: any) => reject(error.data.message));
        },
    );
};
