import React, { useEffect, useMemo, useState } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { FormikProps } from "formik";
import _ from "lodash";

import { FormValues, PropType } from "./type";
import { Contents } from "../../../components/contents";
import { ContentType } from "../../../DTO";

export const PostCreate = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [contents, setContents] = useState<ContentType[]>(values.content);

    useEffect(() => {
        values.content = contents;
    }, [contents]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="peer-group-slide-title"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            aria-label="title"
                            className="h3"
                            type="string"
                            name="title"
                            placeholder={"Please Enter Title"}
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Text className="text-danger">
                            {errors.title}
                        </Form.Text>
                    </Form.Group>
                    <div className="col-12">
                        <Form.Group
                            controlId="peer-group-slide-context"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>Context</Form.Label>
                            <Form.Control
                                as={"textarea"}
                                rows={5}
                                cols={5}
                                aria-label="context"
                                className="h3"
                                type="string"
                                name="context"
                                placeholder={"Please Enter Context"}
                                onBlur={handleBlur}
                                value={values.context}
                                onChange={handleChange}
                                isInvalid={touched.context && !!errors.context}
                            />
                            <Form.Text className="text-danger">
                                {errors.context}
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <Contents
                        contentLabel="License Post Upload"
                        defaultValue={values.content}
                        formPrefix={"image-upload"}
                        setContents={setContents}
                        yupError={"Image is required!"}
                        inputValue={"image/png, image/jpeg, image/jpg"}
                    />
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={contents.length <= 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
