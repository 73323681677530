import { AdminUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertUserPlaneOrder } from "../app-user";
import { convertAdminUser } from "./list";

export const ShowAdmin = (user: userType, userId: number) => {
    return new Promise<{ admin: AdminUserType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `auth/index/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    admin: convertAdminUser(data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};
