import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { AdminForm } from "../../form";
import { useAppNavigate, useAppParams } from "../../Router/hook";
import { AdminApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import Swal from "sweetalert2";
import { Loading } from "../../components";
import { AdminUserType } from "../../DTO";

export const EditAdmin: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [admin, setAdmin] = useState<AdminUserType>();

    const { id } = useAppParams();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Admin list", href: "/admin-user/list" },
        { name: "Edit " + id, href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchSingleAdmin = () => {
        setLoading(true);

        AdminApi.ShowAdmin(user, Number(id))
            .then((data) => {
                setAdmin(data.admin);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleAdmin();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>D2Type | Admin Edit</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {admin && (
                        <Formik<AdminForm.FormValues>
                            validationSchema={AdminForm.ValidationSchema}
                            initialValues={{
                                username: admin.username,
                                firstName: admin.firstName,
                                lastName: admin.lastName,
                                email: admin.email,
                                password: "",
                                passwordConfirm: "",
                                phone: admin.phone,
                                // position:
                                //     admin.position && admin.position.length > 0
                                //         ? admin.position
                                //         : "",
                            }}
                            onSubmit={(event) => {
                                setLoading(true);

                                AdminApi.EditAdmin(
                                    user,
                                    event.username,
                                    event.firstName,
                                    event.lastName,
                                    event.email,
                                    event.phone,
                                    Number(id),
                                    event.password,
                                    event.passwordConfirm,
                                    // event.position,
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate("/admin-user/list");
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <AdminForm.AdminCreateEdit
                                    submitTxt={"Submit"}
                                    {...props}
                                    role={admin.roles}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
