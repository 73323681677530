import { ProgressDetail, userType } from "../../DTO";
import { Api } from "../api";

export const UserDetail = (
    user: userType,
    userId: number,
    weekNumber: number,
) => {
    return new Promise<{ detail: ProgressDetail[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `elementor/progress/detail/user/${userId}/week/${weekNumber}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    detail: convertDetailList(data.data),
                });
            })
            .catch((error) => {
                reject(error.data.message);
            });
    });
};

export const convertDetailList = (data: any[]): ProgressDetail[] => {
    return data.map((element) => convertDetail(element));
};

export const convertDetail = (data: any): ProgressDetail => {
    let out: ProgressDetail = {
        backgroundColor: data?.backgroundColor,
        id: data?.id,
        isEnough: data?.isEnough,
        label: data?.label,
        type: data?.type,
    };

    return out;
};
