import { userType } from "../../DTO";
import {
    ProgressReportType,
    ReportPagesBodyItemsType,
    ReportPagesBodyType,
    ReportPagesType,
} from "../../DTO/app-user/report_detail";
import { Api } from "../api";

export const UserReportDetail = (
    user: userType,
    userId: number,
    weekNumber: number,
    type:
        | "engagement_detail"
        | "food_detail"
        | "activity_detail"
        | "sleep_detail"
        | "progress_detail"
        | "all_time"
        | "stress_detail"
        | "weight_detail"
        | "blood_detail"
        | string,
) => {
    return new Promise<{ report: ProgressReportType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `elementor/progress/report/user/${userId}/week/${weekNumber}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                type,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    report: convertToReport(data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};

export const convertToReport = (data: any): ProgressReportType => {
    let out: ProgressReportType = {
        currentWeek: data?.currentWeek ? data?.currentWeek : "",
        period: data?.period ? data?.period : "1m",
        id: data?.id,
        name: data?.name,
        type: data?.type,

        pages: convertToReportPagesList(data?.pages),
    };

    return out;
};

export const convertToReportPagesList = (data: any[]): ReportPagesType[] => {
    return data.map((element) => convertToReportPages(element));
};

export const convertToReportPages = (data: any): ReportPagesType => {
    let out: ReportPagesType = {
        name: data?.name,
        type: data?.type,
        body:
            data?.body && data.body.length > 0
                ? convertToReportPagesBodyList(data?.body)
                : [],
    };

    return out;
};

export const convertToReportPagesBodyList = (
    data: any[],
): ReportPagesBodyType[] => {
    return data.map((element) => convertToReportPagesBody(element));
};

export const convertToReportPagesBody = (data: any): ReportPagesBodyType => {
    let out: ReportPagesBodyType = {
        backgroundColor: data?.backgroundColor,
        description: data?.description,
        title: data?.title,
        items: convertToReportPagesBodyItemsList(data?.items),
    };

    return out;
};

export const convertToReportPagesBodyItemsList = (
    data: any[],
): ReportPagesBodyItemsType[] => {
    return data.map((element) => convertToReportPagesBodyItems(element));
};

export const convertToReportPagesBodyItems = (
    data: any,
): ReportPagesBodyItemsType => {
    let out: ReportPagesBodyItemsType = {
        description: data?.description,
        descriptionColor: data?.descriptionColor,
        done: data?.done,
        id: data?.id,
        label: data?.label,
        value: data?.value,
        valueColor: data?.valueColor,
    };

    return out;
};
