import { Api } from "../api";

export const ChatGPT = (message: string) => {
    return new Promise<{ chat: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "",
            secondUrl: "https://api.openai.com/v1/chat/completions",
            header: {
                "Content-Type": "application/json",
                Authorization: `Bearer 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`,
            },
            body: JSON.stringify({
                messages: [
                    {
                        role: "system",
                        content: "You are a helpful assistant",
                    },
                    {
                        role: "user",
                        content: message,
                    },
                ],
                model: "gpt-3.5-turbo",
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    chat: data,
                });
            })
            .catch((error) => reject(error));
    });
};
