import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { useAppParams } from "../../../Router/hook";
import { LessonApi } from "../../../api";
import { Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { DataSlideType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import "./style.scss";
import { NotFound } from "../../../assets/images";

export const LessonSlideShow: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [slide, setSlide] = useState<DataSlideType>();

    const { id, slideId } = useAppParams();

    useEffect(() => {
        fetchSingleLessonSlide();
    }, [slideId, id]);

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Lesson list", href: "/lesson/list" },
        { name: "Slide list", href: `/lesson/info/${id}` },
        { name: "Info " + id, href: "#" },
    ];

    const fetchSingleLessonSlide = () => {
        setLoading(true);

        LessonApi.SlideApi.ShowSlide(user, Number(slideId))
            .then((data) => {
                setSlide(data.slide);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Slide Show</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column gap-3">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div
                    className="bg-custom-white p-3 rounded-5 align-self-center"
                    style={{ width: "780px" }}
                >
                    {slide && (
                        <div className="d-flex flex-column w-100 gap-3 px-3">
                            <div
                                className={
                                    "d-flex flex-row gap-2 align-items-center"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faCircleUser}
                                    className="fa-3x"
                                />
                            </div>
                            <div
                                className={
                                    "d-flex flex-row justify-content-between align-items-center"
                                }
                            >
                                <div
                                    className={
                                        "d-flex flex-row gap-2 align-items-center"
                                    }
                                >
                                    <span
                                        className={"text1"}
                                        style={{ fontSize: "1.4rem" }}
                                    >
                                        {slide.title}
                                    </span>
                                    <span className={"text5"}>
                                        {slide.createdAt.slice(0, 10)}{" "}
                                        {slide.createdAt.slice(11, 19)}
                                    </span>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <Carousel
                                    style={{ height: "300px" }}
                                    className="rounded-4"
                                >
                                    {slide?.attachments &&
                                    slide?.attachments?.length > 0 ? (
                                        slide?.attachments.map((mediaItem) => (
                                            <Carousel.Item>
                                                <img
                                                    className="d-block w-100"
                                                    src={mediaItem.path}
                                                    alt={
                                                        mediaItem.attachableType
                                                    }
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "300px",
                                                    }}
                                                />
                                            </Carousel.Item>
                                        ))
                                    ) : (
                                        <img
                                            className="d-block w-100"
                                            src={NotFound}
                                            alt={"not found"}
                                            style={{
                                                objectFit: "cover",
                                                height: "300px",
                                            }}
                                        />
                                    )}
                                </Carousel>
                            </div>
                            <span className="text-secondary text-break my-1">
                                {slide.description}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
