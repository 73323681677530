import { FC, useEffect, useState } from "react";
import { RecipeType, userType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { useAppParams } from "../../Router/hook";
import { RecipeAPi } from "../../api";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../components";
import { Carousel } from "react-bootstrap";

export const RecipeShow: FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recipe, setRecipe] = useState<RecipeType>();

    const user = useAppSelector(selectUser);

    const { id } = useAppParams();

    useEffect(() => {
        fetchSingleRecipe();
    }, [id]);

    const fetchSingleRecipe = () => {
        setLoading(true);

        RecipeAPi.ShowRecipe(user, Number(id))
            .then((data) => {
                setRecipe(data.recipe);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: `Recipe List `, href: "/recipe/list" },
        { name: `Info ${id}`, href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>D2Type | Recipe Info</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {recipe && (
                    <>
                        <div className="d-flex flex-row flex-wrap rounded px-3">
                            <div className="col-12  p-2">
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row position-relative">
                                    <div className="col-12 col-lg-4 h-100">
                                        <Carousel>
                                            {recipe.attachments.map(
                                                (recipeImage) => (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={
                                                                recipeImage.path
                                                            }
                                                            alt={
                                                                recipeImage.path
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ),
                                            )}
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-lg-8 d-flex flex-row">
                                        <div className="col-12 col-lg-6 d-flex h-100 flex-column gap-1 justify-content-center align-items-center ">
                                            <div className="d-flex flex-row gap-2">
                                                <span>Title:</span>
                                                <strong>{recipe.title}</strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>Status:</span>
                                                <strong
                                                    className={
                                                        recipe.status ===
                                                        "active"
                                                            ? "badge bg-success"
                                                            : "badge bg-danger"
                                                    }
                                                >
                                                    {recipe.status}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1 ">
                                            <div className="d-flex flex-column gap-1">
                                                <span>Description:</span>
                                                <strong className="text-break">
                                                    {recipe.description}
                                                </strong>
                                            </div>

                                            <hr />
                                            <div className="d-flex flex-column gap-1">
                                                <span>Tags:</span>
                                                <div className="d-flex flex-row gap-2 flex-wrap">
                                                    {recipe.tags.map(
                                                        (element, index) => (
                                                            <strong
                                                                className="bg-custom-light p-1 rounded-2"
                                                                key={index}
                                                            >
                                                                {element}
                                                            </strong>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
