import { userType } from "../../DTO";
import { Api } from "../api";

export const FetchChat = (user: userType, user_id: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "chat",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                user_id: Number(user_id),
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const PostChat = (user: userType, user_id: string, message: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "chat",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            body: {
                user_id: Number(user_id),
                message: message,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

// export function convertToChatType(item: any): any {
//   const returnUser = {
//     id: item.id,
//     first_name: item.first_name,
//     last_name: item.last_name,
//     username: item.username,
//     phone: item.phone,
//     email: item.email,
//     phone_verified_at: item.phone_verified_at,
//     email_verified_at: item.email_verified_at,
//     last_login: item.last_login,
//     status: item.status,
//     access_token: item.access_token,
//     expires_in: item.expires_in,
//     permissions: item.permissions.data,
//   };
//   return item;
// }
