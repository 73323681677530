import React, { useEffect, useState } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { FormikProps } from "formik";
import _ from "lodash";

import { FormValues, PropType } from "./type";
import { Contents } from "../../../components/contents";
import { ContentType } from "../../../DTO";

export const LessonSlideCreateOrEdit = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [contents, setContents] = useState<ContentType[]>(values.content);

    useEffect(() => {
        values.content = contents;
    }, [contents]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="lesson-slide-title"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            aria-label="title"
                            className="h3"
                            type="string"
                            name="title"
                            placeholder={"Please Enter Title"}
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Text className="text-danger">
                            {errors.title}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="lesson-slide-time-to-read"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Time To Read</Form.Label>
                        <Form.Control
                            aria-label="timeToRead"
                            className="h3"
                            type="string"
                            name="timeToRead"
                            placeholder={"Please Enter TimeTo Read Minutes..."}
                            onBlur={handleBlur}
                            value={values.timeToRead}
                            onChange={handleChange}
                            isInvalid={
                                touched.timeToRead && !!errors.timeToRead
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.timeToRead}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="peer-group-slide-context"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as={"textarea"}
                            rows={5}
                            cols={5}
                            aria-label="description"
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={"Please Enter Description..."}
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={
                                touched.description && !!errors.description
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.description}
                        </Form.Text>
                    </Form.Group>
                    <Contents
                        contentLabel="License Slide Upload"
                        defaultValue={values.content}
                        formPrefix={"image-upload"}
                        setContents={setContents}
                        yupError={"Image is required!"}
                        inputValue={"image/png, image/jpeg, image/jpg"}
                    />
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={contents.length <= 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
