import { AdminUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertAdminUser } from "./list";

export const CreateAdmin = (
    user: userType,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string,
    confirmPassword: string,
) => {
    return new Promise<{ admin: AdminUserType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "auth/register",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            body: {
                username,
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                password,
                password_confirmation: confirmPassword,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    admin: convertAdminUser(data.data),
                });
            })
            .catch((error) => reject(error.data.message));
    });
};
