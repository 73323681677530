import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";
import { Form, Table } from "react-bootstrap";
import {
    CustomPagination,
    Loading,
    SendNotification,
} from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { AppUserApi, NotificationApi } from "../../../api";
import { AppUserType, PaginationType } from "../../../DTO";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { useAppDispatch, useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { setWeek } from "../../../redux-config/entities/week";

export const AppUsersList = () => {
    const [loading, setLoading] = useState(false);
    const [appUser, setAppUser] = useState<AppUserType[]>([]);
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [registerState, setRegisterState] = useState<string>("");
    const [statusActive, setStatusActive] = useState<boolean>(false);
    const [statusInactive, setStatusInactive] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const dispatch = useAppDispatch();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "#" },
    ];

    const fetchAppUserList = (page: number) => {
        setLoading(true);
        AppUserApi.FetchUserAppList(
            user,
            page,
            firstName.length > 0 ? firstName : undefined,
            lastName.length > 0 ? lastName : undefined,
            email.length > 0 ? email : undefined,
            statusInactive && statusInactive
                ? undefined
                : statusActive
                  ? "active"
                  : statusInactive
                    ? "inactive"
                    : undefined,
            registerState.length > 0 && registerState === "all"
                ? undefined
                : registerState.length > 0
                  ? registerState
                  : undefined,
        )
            .then((data) => {
                setLoading(false);
                setAppUser(data.user);
                setPagination(data.pagination);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.message,
                });
            });
    };

    useEffect(() => {
        fetchAppUserList(pagination.current_page);
    }, []);

    const fetchPlanWeekList = (userId: number) => {
        setLoading(true);
        AppUserApi.ListPlanUserWeek(user, Number(userId), false)
            .then((data) => {
                let currentWeekFiltered = data.plan.filter(
                    (element) => element.isCurrentWeek === true,
                );

                if (currentWeekFiltered.length > 0) {
                    dispatch(setWeek(currentWeekFiltered[0]));
                } else {
                    dispatch(
                        setWeek({
                            index: 0,
                            endDate: "",
                            id: 0,
                            isCurrentWeek: false,
                            startDate: "",
                            title: "",
                        }),
                    );
                }
                navigate(`/app-user/${userId}/progress/detail`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        if (
            firstName.length === 0 &&
            lastName.length === 0 &&
            email.length === 0 &&
            !statusInactive &&
            !statusActive &&
            registerState === "all"
        ) {
            fetchAppUserList(1);
        }
    }, [
        firstName,
        lastName,
        email,
        statusInactive,
        statusActive,
        registerState,
    ]);

    const navigate = useAppNavigate();

    const handleRestState = async () => {
        await setFirstName("");
        await setLastName("");
        await setEmail("");
        await setStatusInactive(false);
        await setStatusActive(false);
        await setRegisterState("all");
    };

    const handleChangeStatusActivate = (userId: string) => {
        AppUserApi.ChangeUserActiveDeactivate(user, userId)
            .then(() => {
                Swal.fire(
                    "Status Change",
                    "Status changed successfully",
                    "success",
                );
                fetchAppUserList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    const handleDeleteUser = (userId: number) => {
        setLoading(true);

        AppUserApi.DeleteUser(user, userId)
            .then(() => {
                setLoading(false);
                fetchAppUserList(pagination.current_page);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>D2Type | User List</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">User Management</h4>
                            </div>
                            <div
                                className={
                                    "my-lg-3 rounded-3 border border-2 p-3"
                                }
                            >
                                <h5 className={"col-12"}>Search User</h5>
                                <form
                                    onSubmit={() => fetchAppUserList(1)}
                                    className={
                                        "d-flex flex-row justify-content-between"
                                    }
                                >
                                    <div className="d-flex flex-row flex-wrap gap-2 align-items-center ">
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter Email"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                value={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter First Name"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                value={lastName}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                size="sm"
                                                type="string"
                                                placeholder="Please Enter Last Name"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>
                                                Register State
                                            </Form.Label>
                                            <Form.Select
                                                value={registerState}
                                                onChange={(e) =>
                                                    setRegisterState(
                                                        e.target.value,
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <option
                                                    className={
                                                        "d-none text-center p-2"
                                                    }
                                                >
                                                    Please Choose Register State
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"pending"}
                                                >
                                                    Pending
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"accepted"}
                                                >
                                                    Accepted
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"finished"}
                                                >
                                                    Finished
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"questionnaires"}
                                                >
                                                    Questionnaires
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"plan_purchased"}
                                                >
                                                    Plan Purchased
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"preparing"}
                                                >
                                                    Preparing
                                                </option>
                                                <option
                                                    className={
                                                        "text-center p-2"
                                                    }
                                                    value={"all"}
                                                >
                                                    All
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group
                                            className={"align-self-end"}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                label="Status Active"
                                                checked={statusActive}
                                                onChange={(e) =>
                                                    setStatusActive(
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className={"align-self-end"}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                label="Status Inactive"
                                                checked={statusInactive}
                                                onChange={(e) =>
                                                    setStatusInactive(
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                    <div
                                        className={
                                            "d-flex flex-row gap-2 align-items-center"
                                        }
                                    >
                                        <button
                                            className="text-white btn btn-sm btn-success text-nowrap align-self-end"
                                            type="submit"
                                        >
                                            <span>Search</span>
                                            <FontAwesomeIcon
                                                className="mx-1"
                                                icon={faSearch}
                                            />
                                        </button>
                                        <button
                                            className="text-white btn btn-sm btn-danger text-nowrap align-self-end"
                                            onClick={handleRestState}
                                        >
                                            <span>Clear</span>
                                            <FontAwesomeIcon
                                                className="mx-1"
                                                icon={faTrash}
                                            />
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="p-4 text-center text-nowrap rounded p-3 bg-custom-white h-100">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Full Name</th>
                                            <th>Insurance Member Id</th>
                                            <th>Register State</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th>User Id</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appUser.length > 0 &&
                                            appUser.map((item, index) => (
                                                <tr
                                                    key={item.id}
                                                    className="align-middle"
                                                >
                                                    <td>
                                                        {index +
                                                            1 +
                                                            (pagination.current_page -
                                                                1) *
                                                                pagination.per_page}
                                                    </td>
                                                    <td>
                                                        {item.firstName &&
                                                        item.lastName
                                                            ? `${item.firstName} ${item.lastName}`
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {item.insuranceMemberId
                                                            ? item.insuranceMemberId
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        <strong
                                                            style={{
                                                                width: "120px",
                                                            }}
                                                            className={`badge ${
                                                                item.registerState ===
                                                                "pending"
                                                                    ? "bg-warning text-custom-black"
                                                                    : item.registerState ===
                                                                        "accepted"
                                                                      ? "bg-info"
                                                                      : item.registerState ===
                                                                          "finished"
                                                                        ? "bg-success"
                                                                        : item.registerState ===
                                                                            "questionnaires"
                                                                          ? "bg-primary"
                                                                          : item.registerState ===
                                                                              "plan_purchased"
                                                                            ? "bg-danger text-white"
                                                                            : item.registerState ===
                                                                                  "preparing" &&
                                                                              "bg-secondary text-custom-light"
                                                            }`}
                                                        >
                                                            {item.registerState ===
                                                            "pending"
                                                                ? "Pending"
                                                                : item.registerState ===
                                                                    "accepted"
                                                                  ? "Accepted"
                                                                  : item.registerState ===
                                                                      "finished"
                                                                    ? "Finished"
                                                                    : item.registerState ===
                                                                        "questionnaires"
                                                                      ? "Questionnaires"
                                                                      : item.registerState ===
                                                                          "plan_purchased"
                                                                        ? "Plan Purchased"
                                                                        : item.registerState ===
                                                                              "preparing" &&
                                                                          "Preparing"}
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {item.phone
                                                            ? item.phone
                                                            : "-"}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        <Form.Switch
                                                            checked={
                                                                item.status ===
                                                                "active"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e) => {
                                                                handleChangeStatusActivate(
                                                                    String(
                                                                        item.id,
                                                                    ),
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                            <button
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        text: `Are You Sure to Delete ${
                                                                            item.firstName &&
                                                                            item.lastName
                                                                                ? `${item.firstName} ${item.lastName}`
                                                                                : item.email
                                                                        }?`,
                                                                        icon: "warning",
                                                                        showCancelButton:
                                                                            true,
                                                                        confirmButtonColor:
                                                                            "#3085d6",
                                                                        cancelButtonColor:
                                                                            "#d33",
                                                                        confirmButtonText:
                                                                            "Yes",
                                                                        cancelButtonText:
                                                                            "No",
                                                                    }).then(
                                                                        (
                                                                            result,
                                                                        ) => {
                                                                            if (
                                                                                result.isConfirmed
                                                                            ) {
                                                                                handleDeleteUser(
                                                                                    item.id,
                                                                                );
                                                                            }
                                                                        },
                                                                    );
                                                                }}
                                                                className={
                                                                    "text-white btn btn-sm btn-danger"
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTrash
                                                                    }
                                                                />
                                                            </button>
                                                            <Link
                                                                to={`/app-user/info/${item.id}`}
                                                                className="text-white btn btn-info btn-sm"
                                                            >
                                                                info
                                                            </Link>
                                                            <Link
                                                                to={`/app-user/edit/${item.id}`}
                                                                className="text-dark btn btn-sm btn-warning"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                />
                                                            </Link>
                                                            <button
                                                                onClick={() => {
                                                                    fetchPlanWeekList(
                                                                        Number(
                                                                            item.id,
                                                                        ),
                                                                    );
                                                                }}
                                                                className="text-white btn btn-sm btn-secondary"
                                                            >
                                                                Progress Detail
                                                            </button>
                                                            <Link
                                                                to={`/app-user/${item.id}/progress/overview?type=engagement_detail&period=3m`}
                                                                className="text-white btn btn-sm btn-primary"
                                                            >
                                                                Progress
                                                                Overview
                                                            </Link>
                                                            <SendNotification
                                                                styleClassName="btn-sm"
                                                                fetchData={() =>
                                                                    fetchAppUserList(
                                                                        pagination.current_page,
                                                                    )
                                                                }
                                                                user={user}
                                                                userId={Number(
                                                                    item.id,
                                                                )}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                                <CustomPagination
                                    total={pagination.total}
                                    perPage={pagination.per_page}
                                    currentPage={pagination.current_page}
                                    changePage={(pageNum: number) => {
                                        navigate(
                                            `/app-user?current_page=${pageNum}`,
                                        );
                                        fetchAppUserList(pageNum);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
