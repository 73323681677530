import { DataPostType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToDataPostType, convertToPostType } from "./create";

export const ShowPost = (user: userType, postId: number) => {
    return new Promise<{ post: DataPostType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `peer_group/posts/${postId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    post: convertToDataPostType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
