import { CommentType, userType } from "../../../../DTO";
import { Api } from "../../../api";
import { convertAppUser } from "../../../app-user";

export const CreateComment = (
    user: userType,
    postId: number,
    title: string,
    context: string,
) => {
    return new Promise<{ comment: CommentType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `peer_group/post/${postId}/comment`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                context,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    comment: convertToCommentType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToCommentType = (data: any): CommentType => {
    let out: CommentType = {
        authorId: data?.author_id,
        context: data?.context,
        createdAt: data?.created_at,
        id: data?.id,
        postId: data?.post_id,
        title: data?.title,
        updatedAt: data?.updated_at,
        author: convertAppUser(data?.author),
        deletedAt: data?.deleted_at,
        status: data?.status,
    };
    return out;
};
