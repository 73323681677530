import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export const NotFound = () => {
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center w-100 bg-light"
            style={{ height: "100vh" }}
        >
            <h1>
                <FontAwesomeIcon icon={faExclamationTriangle} /> 404 Error
            </h1>
            <p>Sorry, the page you requested could not be found.</p>
            <a className="text-white btn btn-info shadow" href="/">
                Go back to homepage
            </a>
        </div>
    );
};
