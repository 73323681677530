import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { ChatApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { Loading } from "../../../components";
import { useAppNavigate } from "../../../Router/hook";
import { Logo } from "../../../assets/images";

export const TicketID = () => {
    const { id } = useParams();
    const user = useAppSelector(selectUser);
    const [data, setData] = useState<any[]>([]);
    const [message, setMessage] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [sendDataLoading, setSendDataLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageAI, setMessageAI] = useState<string>("");

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "message /" + id, href: "#" },
    ];

    useEffect(() => {
        setLoader(true);
        fetchData();
    }, [id]);

    const fetchData = () => {
        if (id) {
            ChatApi.FetchChat(user, id)
                .then((data) => {
                    setData(data.data.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    };

    const handleSendMessage = () => {
        if (id && message.length > 2) {
            setSendDataLoading(true);
            setMessage("");
            ChatApi.PostChat(user, id, message)
                .then((data) => {
                    fetchData();
                    setSendDataLoading(false);
                })
                .catch((err) => {
                    setSendDataLoading(false);
                });
        }
    };

    const handleMessageOpenAI = () => {
        setSendDataLoading(true);
        ChatApi.ChatGPT(messageAI)
            .then((data) => {
                setMessage(data.chat.choices[0].message.content);
                setSendDataLoading(false);
                setMessageAI("");
                setShowModal(false);
            })
            .catch((err) => {
                setShowModal(false);
                Swal.fire({ icon: "error", text: err.data.error.data.message });
            });
    };

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>D2Type | Chat</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <CustomBreadCrumb breadCrumbList={crumbs} />
            {loader && <Loading />}
            <div className="d-flex flex-row">
                <div className="px-2 pb-2 col-12">
                    <div
                        className="d-flex flex-column bg-custom-white rounded shadow  "
                        style={{ overflowX: "hidden" }}
                    >
                        <div className="d-flex flex-row col-12 justify-content-between">
                            <div
                                onClick={() =>
                                    navigate(
                                        `/app-user/info/${data[0]?.user?.id}`,
                                    )
                                }
                                className="border-bottom p-2 text-info col-6"
                                style={{ cursor: "pointer" }}
                            >
                                User Name: {data[0]?.user?.first_name}{" "}
                                {data[0]?.user?.last_name}
                            </div>
                            <div
                                onClick={() =>
                                    navigate(
                                        `/admin-user/info/${data[0].admin_id}`,
                                    )
                                }
                                className="col-6 align-self-end border-bottom p-2 text-success"
                                style={{ cursor: "pointer" }}
                            >
                                Coach: {data[0]?.admin?.first_name}{" "}
                                {data[0]?.admin?.last_name}
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column-reverse rounded  p-3 w-100"
                            style={{
                                overflowY: "auto",
                                maxHeight: "70vh",
                                minHeight: "400px",
                            }}
                        >
                            {Array.isArray(data) &&
                                data?.map((item: any, index: any) => {
                                    if (item?.is_operator) {
                                        return (
                                            <div
                                                style={{
                                                    maxWidth: "40%",
                                                    borderRadius:
                                                        "16px 16px 0 16px ",
                                                }}
                                                className="p-3 m-2 bg-custom-chat border ms-auto d-flex flex-column"
                                                key={index}
                                            >
                                                <span className="text-break mb-2">
                                                    {item?.message}
                                                </span>
                                                <small className="align-self-end">
                                                    {item?.date.slice(0, 10)}{" "}
                                                    {item?.date.slice(11, 19)}
                                                </small>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                style={{
                                                    maxWidth: "40%",
                                                    borderRadius:
                                                        "16px 16px 16px 0 ",
                                                }}
                                                className="p-3 m-2 bg-custom-light border me-auto d-flex flex-column position-relative"
                                            >
                                                <strong className="text-break mb-2">
                                                    {item?.message}
                                                </strong>
                                                <small className="align-self-end mb-2">
                                                    {item?.date.slice(0, 10)}{" "}
                                                    {item?.date.slice(11, 19)}
                                                </small>
                                                <img
                                                    src={Logo}
                                                    width={25}
                                                    height={25}
                                                    alt="chat-gpt"
                                                    className="position-absolute bg-custom-white p-1 rounded-2"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setMessageAI(
                                                            item?.message,
                                                        );
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        objectFit: "contain",
                                                        right: 0,
                                                        bottom: 0,
                                                        float: "right",
                                                        borderBottomRightRadius:
                                                            "50%",
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                        <div className="d-flex flex-row py-1">
                            <Form
                                className="w-100"
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                }}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        sendDataLoading === false
                                    ) {
                                        handleSendMessage();
                                    }
                                }}
                            >
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "100%" }}
                                >
                                    <Form.Control
                                        onChange={(e: any) => {
                                            setMessage(e?.target?.value);
                                        }}
                                        value={message}
                                        as={"textarea"}
                                        type="text"
                                        style={{ resize: "none" }}
                                        placeholder="..."
                                        disabled={sendDataLoading}
                                    />
                                </Form.Group>
                            </Form>
                            <Button
                                onClick={handleSendMessage}
                                variant="info"
                                className=" mx-3"
                                disabled={sendDataLoading}
                            >
                                {sendDataLoading ? (
                                    <Spinner size="sm" animation="border" />
                                ) : (
                                    "send"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setMessageAI("");
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Message to D2Type AI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        controlId="address-detail"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as={"textarea"}
                            rows={3}
                            aria-label="detail"
                            className="h3"
                            type="string"
                            name="detail"
                            placeholder={"Please Enter Message To D2Type AI"}
                            value={messageAI}
                            onChange={(e) => setMessageAI(e.target.value)}
                        />
                        <Form.Text className="text-danger">
                            {messageAI.length <= 0 && "Message is required!"}
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setMessageAI("");
                            setShowModal(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleMessageOpenAI}
                        variant="primary"
                        className=" mx-3"
                        disabled={sendDataLoading}
                    >
                        {sendDataLoading ? (
                            <Spinner size="sm" animation="border" />
                        ) : (
                            "Send D2Type AI"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
