import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { CustomBreadCrumb } from "../../../components";
import { PeerGroupForm } from "../../../form";
import { PeerGroupApi } from "../../../api";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { Loading } from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";

export const PeerGroupPostCreate: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const { id } = useAppParams();

    const handlePostCreate = (
        title: string,
        context: string,
        imageId: number[],
    ) => {
        setLoading(true);
        PeerGroupApi.PostApi.CreatePost(
            user,
            Number(id),
            title,
            context,
            imageId,
        )
            .then((data) => {
                navigate(`/peer-group/info/${id}`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "/peer-group/list" },
        { name: "New", href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>D2Type | Post New</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<PeerGroupForm.PostForm.FormValues>
                        validationSchema={
                            PeerGroupForm.PostForm.ValidationSchema
                        }
                        initialValues={{
                            title: "",
                            content: [],
                            context: "",
                        }}
                        onSubmit={(event) => {
                            const imagesId: number[] = event.content.map(
                                (element) => Number(element.id),
                            );
                            handlePostCreate(
                                event.title,
                                event.context,
                                imagesId,
                            );
                        }}
                        component={(props: any) => (
                            <PeerGroupForm.PostForm.PostCreate
                                submitTxt={"Submit"}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
