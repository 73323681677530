import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { ProgressReportType } from "../../../DTO";
import {
    Loading,
    CustomBreadCrumb,
    WeekList,
    UserDetail,
    DayDetail,
    FoodLog,
    SleepReport,
    StressMood,
    ActivityReport,
    SendNotification,
} from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    useAppNavigate,
    useAppParams,
    useAppQuery,
} from "../../../Router/hook";
import { AppUserApi } from "../../../api";
import { selectWeek } from "../../../redux-config/entities/week";

export const UserProgressReport: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [progressReport, setProgressReport] = useState<ProgressReportType>();
    const [foodType, setFoodType] = useState<"weekly" | "daily">("weekly");
    const [currentDate, setCurrentDate] = useState<string>("");

    const queryString = useAppQuery();

    const typeQuery = queryString.get("type");

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const weekSelected = useAppSelector(selectWeek);

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user" },
        { name: "User Detail", href: `/app-user/${id}/progress/detail` },
        { name: "Report " + id, href: "#" },
    ];

    const fetchProgressReport = () => {
        if (typeQuery) {
            setLoading(true);

            AppUserApi.UserReportDetail(
                user,
                Number(id),
                weekSelected.index,
                typeQuery,
            )
                .then((data) => {
                    setProgressReport(data.report);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: error,
                    });
                });
        }
    };

    useEffect(() => {
        if (weekSelected && weekSelected.index > 0) {
            fetchProgressReport();
        }
    }, [typeQuery, weekSelected]);

    return (
        <>
            <Helmet>
                <title>D2Type | Progress Report</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex flex-column bg-custom-white rounded p-3">
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">Progress Report</h4>
                                <div className="d-flex flex-row flex-wrap gap-3">
                                    <WeekList pageId={Number(id)} />
                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            typeQuery === "engagement_detail"
                                                ? "Engagement"
                                                : typeQuery === "sleep_detail"
                                                  ? "Sleep"
                                                  : typeQuery ===
                                                      "activity_detail"
                                                    ? "Activity"
                                                    : typeQuery ===
                                                        "food_detail"
                                                      ? "Food"
                                                      : typeQuery ===
                                                          "stress_detail"
                                                        ? "Stress"
                                                        : typeQuery ===
                                                            "weight_detail"
                                                          ? "Weight"
                                                          : typeQuery ===
                                                                "blood_detail" &&
                                                            "Blood"
                                        }
                                        variant="danger"
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=engagement_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                            }}
                                        >
                                            Engagement
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=sleep_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Sleep
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=activity_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Activity
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=food_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Food
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=stress_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Stress
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=weight_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Weight
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/report?type=blood_detail&week=${
                                                        weekSelected.index
                                                    }
                                                                `,
                                                );
                                                setFoodType("weekly");
                                            }}
                                        >
                                            Blood
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    <SendNotification
                                        fetchData={fetchProgressReport}
                                        user={user}
                                        userId={Number(id)}
                                    />
                                </div>
                            </div>
                            <UserDetail
                                pageId={Number(id)}
                                progressReport={progressReport}
                            />
                            {(typeQuery === "food_detail" ||
                                typeQuery === "stress_detail") && (
                                <div className={"d-flex flex-column gap-2 "}>
                                    <div
                                        className={"d-flex flex-row gap-2 px-2"}
                                    >
                                        <button
                                            className={`btn btn-secondary ${
                                                foodType === "weekly" &&
                                                " btn-info"
                                            }`}
                                            onClick={() =>
                                                setFoodType("weekly")
                                            }
                                        >
                                            Weekly
                                        </button>
                                        <button
                                            className={`btn btn-secondary ${
                                                foodType === "daily" &&
                                                " btn-info"
                                            }`}
                                            onClick={() => setFoodType("daily")}
                                        >
                                            Daily
                                        </button>
                                    </div>
                                    {foodType === "daily" && (
                                        <DayDetail
                                            userId={Number(id)}
                                            currentDay={currentDate}
                                            setCurrentDay={setCurrentDate}
                                        />
                                    )}
                                    {typeQuery === "food_detail" &&
                                        foodType === "daily" && (
                                            <>
                                                <FoodLog
                                                    userId={Number(id)}
                                                    currentDate={currentDate}
                                                />
                                            </>
                                        )}
                                    {typeQuery === "stress_detail" &&
                                        foodType === "daily" && (
                                            <StressMood
                                                userId={Number(id)}
                                                currentDate={currentDate}
                                            />
                                        )}
                                </div>
                            )}
                        </div>
                        {progressReport && foodType === "weekly" && (
                            <div className="d-flex flex-row flex-wrap rounded px-3 w-100">
                                <div className={" w-100 my-2"}>
                                    {progressReport.pages
                                        .filter(
                                            (reportItem) =>
                                                reportItem.body.length !== 0,
                                        )
                                        .map((reportItem, index) => (
                                            <>
                                                {reportItem.body.map(
                                                    (bodyItem) => (
                                                        <div className="d-flex flex-column gap-2 m-2">
                                                            <strong>
                                                                {bodyItem.title}
                                                            </strong>
                                                            <div className="p-4 text-center text-nowrap rounded p-3 bg-custom-white h-100">
                                                                <Table
                                                                    hover
                                                                    responsive
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                No.
                                                                            </th>
                                                                            <th>
                                                                                Label
                                                                            </th>
                                                                            <th>
                                                                                Value
                                                                            </th>
                                                                            {/*<th>Description</th>*/}
                                                                            {/*<th>Done</th>*/}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {bodyItem.items.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {index +
                                                                                            1}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.label
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.value
                                                                                        }
                                                                                    </td>
                                                                                    {/*<td>*/}
                                                                                    {/*    {*/}
                                                                                    {/*        item.description*/}
                                                                                    {/*    }*/}
                                                                                    {/*</td>*/}
                                                                                    {/*<td>*/}
                                                                                    {/*    <strong*/}
                                                                                    {/*        className={*/}
                                                                                    {/*            item.done ===*/}
                                                                                    {/*            false*/}
                                                                                    {/*                ? "badge bg-danger"*/}
                                                                                    {/*                : "badge bg-success"*/}
                                                                                    {/*        }*/}
                                                                                    {/*    >*/}
                                                                                    {/*        {String(*/}
                                                                                    {/*            item.done,*/}
                                                                                    {/*        )}*/}
                                                                                    {/*    </strong>*/}
                                                                                    {/*</td>*/}
                                                                                </tr>
                                                                            ),
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    ),
                                                )}
                                            </>
                                        ))}
                                </div>
                                <div className="w-100 my-2 bg-custom-white p-2"></div>
                                {typeQuery === "sleep_detail" && (
                                    <SleepReport userId={Number(id)} />
                                )}
                                {typeQuery === "activity_detail" && (
                                    <ActivityReport userId={Number(id)} />
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
