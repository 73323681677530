import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";
import { Form, Table } from "react-bootstrap";
import {
    CustomPagination,
    Loading,
    CustomBreadCrumb,
} from "../../../components";
import { AdminUserType, PaginationType } from "../../../DTO";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { AdminApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";

export const AdminUsersList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] =
        useState<PaginationType>(useAppPagination());
    const [admin, setAdmin] = useState<AdminUserType[]>([]);
    const [emailName, setEmailName] = useState<string>("");

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Admin list", href: "#" },
    ];

    const navigate = useAppNavigate();

    const fetchAdminUsers = (page: number) => {
        setLoading(true);

        AdminApi.ListAdmin(user, page, emailName)
            .then((data) => {
                setAdmin(data.admin);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchAdminUsers(pagination.current_page);
    }, []);

    return (
        <div>
            <Helmet>
                <title>D2Type | Admin List</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">Admin Table</h4>
                                <div className="d-flex flex-row ">
                                    <label
                                        htmlFor="showAdminSearchInput"
                                        className="text-white btn btn-primary mx-1 text-nowrap"
                                    >
                                        <span>Admin Search</span>
                                        <FontAwesomeIcon
                                            className={"mx-1"}
                                            icon={faSearch}
                                        />
                                    </label>
                                    <Link
                                        to={"/admin-user/new"}
                                        className="text-white btn btn-success mx-1 text-nowrap"
                                    >
                                        <span>Add admin</span>
                                        <FontAwesomeIcon
                                            className={"mx-1"}
                                            icon={faAdd}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <input
                                type="checkbox"
                                id="showAdminSearchInput"
                            ></input>
                            <div className="adminSearchBox  p-2 flex -row justify-content-between align-items-center">
                                <div>
                                    <Form className="d-flex flex-row flex-wrap">
                                        <Form.Group
                                            className="mx-2"
                                            style={{ minWidth: "230px" }}
                                        >
                                            <Form.Label className="mb-0">
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="email"
                                                placeholder="Enter email"
                                                value={emailName}
                                                onChange={(e) =>
                                                    setEmailName(e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <button
                                    className="text-white btn btn-sm btn-success mx-1 text-nowrap"
                                    onClick={() =>
                                        fetchAdminUsers(pagination.current_page)
                                    }
                                >
                                    <span>Search</span>
                                    <FontAwesomeIcon
                                        className="mx-1"
                                        icon={faSearch}
                                    />
                                </button>
                            </div>
                            <div className="p-4 text-center text-nowrap">
                                {admin.length > 0 && (
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Username</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="align-middle">
                                            {admin.map((item, index) => (
                                                <tr
                                                    key={item.id}
                                                    className="align-middle"
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{item.username}</td>
                                                    <td>
                                                        {item.firstName}{" "}
                                                        {item.lastName}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>
                                                        {item?.roles &&
                                                            item?.roles[0]
                                                                ?.name}
                                                    </td>
                                                    <td>
                                                        <strong
                                                            className={
                                                                item.status ===
                                                                "active"
                                                                    ? "badge bg-success"
                                                                    : "badge bg-danger"
                                                            }
                                                        >
                                                            {item.status}
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                            <button
                                                                className="text-white btn btn-sm btn-info"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/admin-user/info/${item.id}`,
                                                                    )
                                                                }
                                                            >
                                                                Info
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/admin-user/edit/${item.id}`,
                                                                    )
                                                                }
                                                                className="text-dark btn btn-sm btn-warning"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                                <CustomPagination
                                    total={pagination.total}
                                    perPage={pagination.per_page}
                                    currentPage={pagination.current_page}
                                    changePage={(pageNum: number) => {
                                        navigate(
                                            `/admin-user/list?current_page=${pageNum}`,
                                        );
                                        fetchAdminUsers(pageNum);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
