import { userType } from "../../../DTO";
import { Api } from "../../api";

export const EditSlide = (
    user: userType,
    slideId: number,
    title: string,
    description: string,
    timeToRead: number,
    attachments: number[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `lesson/slide/${slideId}/edit`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                time_to_read: timeToRead,
                attachment_ids: attachments,
            }),
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    data,
                }),
            )
            .catch((error: any) => reject(error.data.message));
    });
};
