import { PlanWeekType, userType } from "../../DTO";
import { Api } from "../api";

export const ListPlanUserWeek = (
    user: userType,
    userId: number,
    currentWeek: boolean,
) => {
    return new Promise<{ plan: PlanWeekType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `plan/user/${userId}/weeks`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                current_week: currentWeek,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    plan: convertToPlanUserWeekTypeList(data.data.data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const convertToPlanUserWeekTypeList = (data: any[]): PlanWeekType[] => {
    return data.map((element) => convertToPlanUserWeekType(element));
};

export const convertToPlanUserWeekType = (data: any): PlanWeekType => {
    let out: PlanWeekType = {
        id: data?.id,
        title: data?.title,
        endDate: data?.endDate,
        isCurrentWeek: data?.isCurrentWeek,
        index: data?.index,
        startDate: data?.startDate,
    };
    return out;
};
