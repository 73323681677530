import {
    PaginationType,
    permissionType,
    permissionsType,
    userType,
} from "../../DTO";
import { rolesType } from "../../DTO/roles";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const GetList = (user: userType, current_page: number) => {
    return new Promise<{ data: rolesType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "roles",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    per_page: 10,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToRolesListType(data.data),
                        pagination: convertToPaginationType(data.meta),
                    });
                })
                .catch((err) => {
                    return reject(err);
                });
        },
    );
};

export function convertToRolesListType(data: any): rolesType[] {
    const Roles = data.map((item: any) => {
        return {
            id: item.id,
            name: item.name,
            status: item.status,
            permissions: convertToPermissionsGroupType(item.permissions),
        };
    });
    return Roles;
}

export function convertToPermissionsGroupType(data: any): permissionsType[] {
    const permissions = data.map((item: any) => {
        return {
            group: item.group,
            permissions: convertToPermissionListType(item.permissions),
        };
    });
    return permissions;
}

export function convertToPermissionListType(data: any): permissionType[] {
    const permissions = data.map((item: any) => {
        return {
            id: item.id,
            name: item.name,
            description: item.description,
            group: item.group,
            guard_name: item.guard_name,
        };
    });

    return permissions;
}
