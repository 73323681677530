import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { useAppNavigate, useAppParams } from "../../Router/hook";
import { Loading } from "../../components";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { PeerGroupForm } from "../../form";
import { PeerGroupApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { AdminUserType, PostType } from "../../DTO";

export const PeerGroupEdit = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [coach, setCoach] = useState<AdminUserType>();
    const [peerGroup, setPeerGroup] = useState<PostType>();

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const { id } = useAppParams();

    useEffect(() => {
        fetchCoachPeerGroup();
        fetchSinglePeerGroup();
    }, [id]);

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "/peer-group/list" },
        { name: "New", href: "#" },
    ];

    const handleEditPeerGroup = (peerGroupName: string, coachId: number) => {
        setLoading(true);

        PeerGroupApi.EditPeerGroup(user, Number(id), peerGroupName, coachId)
            .then((data) => {
                navigate(`/peer-group/list`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchSinglePeerGroup = () => {
        setLoading(true);

        PeerGroupApi.PostApi.ListPost(user, Number(id), 1)
            .then((data) => {
                setPeerGroup(data.posts);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchCoachPeerGroup = () => {
        setLoading(true);

        PeerGroupApi.CoachPeerGroup(user, Number(id))
            .then((data) => {
                setCoach(data.coach);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>D2Type | Peer Group New</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {peerGroup && coach && (
                        <Formik<PeerGroupForm.CreateEdit.FormValues>
                            validationSchema={PeerGroupForm.CreateEdit.ValidationSchema()}
                            initialValues={{
                                name: peerGroup.peerGroup.name,
                                coachId: coach.id,
                                coachName: `${coach.firstName} ${coach.lastName} | ${coach.email}`,
                            }}
                            onSubmit={(event) => {
                                handleEditPeerGroup(event.name, event.coachId);
                            }}
                            component={(props: any) => (
                                <PeerGroupForm.CreateEdit.PeerGroupCreateOrEdit
                                    submitTxt={"Submit"}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
