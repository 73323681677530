import { userType } from "../../../DTO";
import { Api } from "../../api";

export const ActivatePost = (user: userType, postId: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "patch",
            path: `peer_group/post/${postId}/activate`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => resolve({ data }))
            .catch((error) => reject(error.data.message));
    });
};
