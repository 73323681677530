import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { Card, Carousel, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import {
    useAppNavigate,
    useAppPagination,
    useAppParams,
} from "../../Router/hook";
import { Loading, CustomBreadCrumb, CustomPagination } from "../../components";
import {
    AdminUserType,
    AppUserType,
    PaginationType,
    PeerGroupType,
    PostType,
} from "../../DTO";
import "./style.scss";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { PeerGroupApi } from "../../api";

export const PeerGroupShow: FC = () => {
    const [peerGroup, setPeerGroup] = useState<PostType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [postPagination, setPostPagination] =
        useState<PaginationType>(useAppPagination());
    const [userPagination, setUserPagination] =
        useState<PaginationType>(useAppPagination());
    const [coach, setCoach] = useState<AdminUserType>();
    const [peerGroupUsers, setPeerGroupUsers] = useState<AppUserType[]>([]);

    useEffect(() => {
        fetchSinglePeerGroup(postPagination.current_page);
        fetchCoachPeerGroup();
        fetchPeerGroupUsers(userPagination.current_page);
    }, []);

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Peer Group list", href: "/peer-group/list" },
        { name: "Info " + id, href: "#" },
    ];

    const fetchSinglePeerGroup = (page: number) => {
        setLoading(true);

        PeerGroupApi.PostApi.ListPost(user, Number(id), page)
            .then((data) => {
                setPeerGroup(data.posts);
                setPostPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchCoachPeerGroup = () => {
        setLoading(true);

        PeerGroupApi.CoachPeerGroup(user, Number(id))
            .then((data) => {
                setCoach(data.coach);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchPeerGroupUsers = (page: number) => {
        setLoading(true);

        PeerGroupApi.UserPeerGroup(user, Number(id), page)
            .then((data) => {
                setPeerGroupUsers(data.user);
                setUserPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    console.log(peerGroupUsers);

    return (
        <>
            <Helmet>
                <title>D2Type | Peer Group Show</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex my-2 flex-row flex-wrap rounded  px-md-3">
                    <div className="col-12 col-md-6 col-lg-4 my-1  px-md-2">
                        <div className="rounded-4 p-3 shadow bg-custom-white h-100 d-flex gap-1 flex-column position-relative">
                            <strong
                                style={{ top: 10, right: -10 }}
                                className={`badge position-absolute ${
                                    peerGroup?.peerGroup.status === "active"
                                        ? "bg-success"
                                        : "bg-danger"
                                }`}
                            >
                                {peerGroup?.peerGroup.status}
                            </strong>
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout align-self-center text-center"
                                size="5x"
                                icon={faPeopleGroup}
                            />
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <strong className="text-center mt-2">
                                    {peerGroup?.peerGroup?.name}
                                </strong>

                                <div className="d-flex flex-row">
                                    <span>Created At:</span>
                                    <strong className="mx-1">
                                        {peerGroup?.peerGroup.createdAt.slice(
                                            0,
                                            10,
                                        )}{" "}
                                        {peerGroup?.peerGroup.createdAt.slice(
                                            11,
                                            19,
                                        )}
                                    </strong>
                                </div>
                            </div>
                            <hr />
                            {coach && (
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div>
                                        <div className="d-flex flex-row">
                                            <span>Coach Full Name:</span>
                                            <strong className="mx-1">
                                                {coach?.firstName}{" "}
                                                {coach?.lastName}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>Coach Phone:</span>
                                            <strong className="mx-1">
                                                {coach?.phone}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>Coach Email:</span>
                                            <strong className="mx-1">
                                                {coach?.email}
                                            </strong>
                                        </div>
                                    </div>
                                    <Link
                                        className="text-white btn btn-info text-custom-white"
                                        to={`/admin-user/info/${coach?.id}`}
                                    >
                                        Info Coach
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-8  my-1 px-md-2">
                        <div className="p-4 shadow h-100 text-center text-nowrap rounded-4 shadow bg-custom-white w-100">
                            <h4 className="text-nowrap">User Table</h4>
                            <Table style={{ height: "15rem" }} hover responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Full Name</th>
                                        <th>Phone</th>
                                        <th>Insurance Member Id</th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="align-middle">
                                    {peerGroupUsers.length > 0 &&
                                        peerGroupUsers.map(
                                            (userItem, index) => (
                                                <tr
                                                    key={userItem.id}
                                                    className="align-middle"
                                                >
                                                    <td>
                                                        {index +
                                                            1 +
                                                            (userPagination.current_page -
                                                                1) *
                                                                userPagination.per_page}
                                                    </td>
                                                    <td>
                                                        {userItem.firstName}{" "}
                                                        {userItem.lastName}
                                                    </td>
                                                    <td>{userItem.phone}</td>
                                                    <td>
                                                        {
                                                            userItem.insuranceMemberId
                                                        }
                                                    </td>
                                                    <td>{userItem.email}</td>
                                                    <td>
                                                        <Link
                                                            className="text-white btn btn-sm btn-info text-custom-white"
                                                            to={`/app-user/info/${userItem.id}`}
                                                        >
                                                            Info
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                </tbody>
                            </Table>
                            <CustomPagination
                                total={userPagination.total}
                                perPage={userPagination.per_page}
                                currentPage={userPagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/peer-group/info/${id}?user_current_page=${pageNum}&&post_current_page=${postPagination.current_page}`,
                                    );
                                    fetchPeerGroupUsers(pageNum);
                                }}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="col-12 my-2 px-md-2 border-top border-3 p-1">
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex flex-row my-2 justify-content-between">
                                <h3 className="text-dark">Peer Group Posts</h3>
                                <Link
                                    to={`/peer-group/${id}/post/new`}
                                    className="text-white btn btn-lg btn-success"
                                >
                                    Create Post +
                                </Link>
                            </div>
                            <div className={"d-flex flex-row flex-wrap"}>
                                {peerGroup?.data &&
                                    peerGroup.data.length > 0 &&
                                    peerGroup.data.map(
                                        (item, index: number) => (
                                            <div
                                                key={index}
                                                className={
                                                    "col-12 col-md-6 col-lg-3 p-2"
                                                }
                                            >
                                                <Card
                                                    className={
                                                        "h-100 position-relative bg-custom-white shadow rounded-4 p-md-4"
                                                    }
                                                >
                                                    <strong
                                                        className={`${
                                                            item.status ===
                                                            "active"
                                                                ? "bg-success"
                                                                : "bg-danger"
                                                        } badge  position-absolute`}
                                                        style={{
                                                            top: 10,
                                                            right: -10,
                                                        }}
                                                    >
                                                        {item?.status}
                                                    </strong>
                                                    <Card.Body
                                                        className={
                                                            "d-flex flex-column gap-2"
                                                        }
                                                    >
                                                        <div
                                                            className={"col-12"}
                                                        >
                                                            <Carousel
                                                                style={{
                                                                    height: "200px",
                                                                }}
                                                            >
                                                                {item?.attachments &&
                                                                item
                                                                    ?.attachments
                                                                    ?.length >
                                                                    0 ? (
                                                                    item?.attachments.map(
                                                                        (
                                                                            mediaItem,
                                                                        ) => (
                                                                            <Carousel.Item>
                                                                                <img
                                                                                    className="d-block w-100"
                                                                                    src={
                                                                                        mediaItem.path
                                                                                    }
                                                                                    alt={
                                                                                        mediaItem.attachableType
                                                                                    }
                                                                                    style={{
                                                                                        objectFit:
                                                                                            "cover",
                                                                                        height: "200px",
                                                                                    }}
                                                                                />
                                                                            </Carousel.Item>
                                                                        ),
                                                                    )
                                                                ) : (
                                                                    <img
                                                                        className="d-block w-100"
                                                                        src={
                                                                            "/image/notfound.png"
                                                                        }
                                                                        alt={
                                                                            "not found"
                                                                        }
                                                                        style={{
                                                                            objectFit:
                                                                                "cover",
                                                                            height: "200px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </Carousel>
                                                        </div>
                                                        <div
                                                            className={`
                              : 'd-flex mt-2 flex-column gap-2 col-12'
                          `}
                                                        >
                                                            <div className="d-flex flex-row justify-content-between">
                                                                <strong className="text-primary fa-2x">
                                                                    {
                                                                        item?.title
                                                                    }
                                                                </strong>
                                                                <strong className="text-secondary">
                                                                    {
                                                                        item
                                                                            ?.author
                                                                            ?.firstName
                                                                    }{" "}
                                                                    {
                                                                        item
                                                                            ?.author
                                                                            ?.lastName
                                                                    }
                                                                </strong>
                                                            </div>
                                                            <p
                                                                className={
                                                                    "text-break text-custom-dark"
                                                                }
                                                            >
                                                                {item.context}
                                                            </p>
                                                        </div>
                                                    </Card.Body>
                                                    <Card.Footer
                                                        className={
                                                            "bg-custom-white"
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                "d-flex flex-row gap-2 justify-content-center align-items-center align-self-center"
                                                            }
                                                        >
                                                            <Link
                                                                to={`/peer-group/${id}/post/edit/${item.id}`}
                                                                className={`text-dark btn btn-warning`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                />
                                                            </Link>
                                                            <Link
                                                                to={`/peer-group/${id}/post/info/${item.id}`}
                                                                className={`text-white btn bg-info`}
                                                            >
                                                                Info
                                                            </Link>
                                                            <button
                                                                className={`text-white btn ${
                                                                    item.status ===
                                                                    "active"
                                                                        ? "btn-danger"
                                                                        : "btn-success"
                                                                }`}
                                                                onClick={() => {
                                                                    if (
                                                                        item.status ===
                                                                        "active"
                                                                    ) {
                                                                        PeerGroupApi.PostApi.DeactivatePost(
                                                                            user,
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                        )
                                                                            .then(
                                                                                () => {
                                                                                    Swal.fire(
                                                                                        "Deacitvate!",
                                                                                        "Post has been deactivate.",
                                                                                        "success",
                                                                                    );
                                                                                    fetchSinglePeerGroup(
                                                                                        postPagination.current_page,
                                                                                    );
                                                                                },
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    err,
                                                                                ) =>
                                                                                    Swal.fire(
                                                                                        {
                                                                                            icon: "error",
                                                                                            text: err,
                                                                                        },
                                                                                    ),
                                                                            );
                                                                    } else {
                                                                        PeerGroupApi.PostApi.ActivatePost(
                                                                            user,
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                        )
                                                                            .then(
                                                                                () => {
                                                                                    Swal.fire(
                                                                                        "Acitvate!",
                                                                                        "Post has been activate.",
                                                                                        "success",
                                                                                    );
                                                                                    fetchSinglePeerGroup(
                                                                                        postPagination.current_page,
                                                                                    );
                                                                                },
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    err,
                                                                                ) =>
                                                                                    Swal.fire(
                                                                                        {
                                                                                            icon: "error",
                                                                                            text: err,
                                                                                        },
                                                                                    ),
                                                                            );
                                                                    }
                                                                }}
                                                            >
                                                                {item.status ===
                                                                "active"
                                                                    ? "Deactivate"
                                                                    : "Activate"}
                                                            </button>
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            </div>
                                        ),
                                    )}
                            </div>
                            <CustomPagination
                                total={postPagination.total}
                                perPage={postPagination.per_page}
                                currentPage={postPagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/peer-group/info/${id}?user_current_page=${userPagination.current_page}&&post_current_page=${pageNum}`,
                                    );
                                    fetchSinglePeerGroup(pageNum);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
