import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { UserForm } from "../../../form";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { AppUserApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import Swal from "sweetalert2";
import { Loading } from "../../../components";
import { AppUserType } from "../../../DTO";

export const EditAppUser: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [appUser, setAppUser] = useState<AppUserType>();
    const [birthday, setBirthday] = useState<string>("");

    const { id } = useAppParams();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user/list" },
        { name: "Edit " + id, href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchSingleUser = () => {
        setLoading(true);

        AppUserApi.ShowAppUser(user, Number(id))
            .then((data) => {
                setAppUser(data.user);
                setLoading(false);
                setBirthday(
                    data?.user?.birthday
                        ? data?.user?.birthday.slice(0, 10)
                        : "",
                );
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleUser();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>D2Type | User Edit</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {appUser && (
                        <Formik<UserForm.FormValues>
                            validationSchema={UserForm.ValidationSchema}
                            initialValues={{
                                email: appUser.email ? appUser.email : "",
                                // username: appUser.username
                                //     ? appUser.username
                                //     : "",
                                firstName: appUser.firstName
                                    ? appUser.firstName
                                    : "",
                                lastName: appUser.lastName
                                    ? appUser.lastName
                                    : "",
                                phone: appUser.phone ? appUser.phone : "",
                                postalCode: appUser.postalCode
                                    ? appUser.postalCode
                                    : "",
                                insuranceMemberId: appUser.insuranceMemberId
                                    ? appUser.insuranceMemberId
                                    : "",
                            }}
                            onSubmit={(event) => {
                                setLoading(true);

                                AppUserApi.EditUser(
                                    user,
                                    // event.username,
                                    event.firstName,
                                    event.lastName,
                                    event.phone,
                                    event.postalCode,
                                    event.insuranceMemberId,
                                    birthday,
                                    event.email,
                                    Number(id),
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate("/app-user");
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <UserForm.UserEdit
                                    birthday={birthday}
                                    setBirthDay={setBirthday}
                                    submitTxt={"Submit"}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
