import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        // username: Yup.string().required("Username is required!").min(1),
        firstName: Yup.string()
            .required("First Name is required")
            .min(1)
            .max(128),
        lastName: Yup.string()
            .required("Last Name is required")
            .min(1)
            .max(128),
        postalCode: Yup.string().required("Postal Code is required"),
        // birthday: Yup.string()
        //     .required("Birthday is required"),
        insuranceMemberId: Yup.string().required(
            "Insurance Member Id is required",
        ),
        email: Yup.string()
            .required("Email is required")
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Please Enter Valid Email!",
            ),
        phone: Yup.string().required("Phone Number is required!"),
        // password: Yup.string()
        //     .required("Password id required!")
        //     .min(8, "Password should not be less than 8 characters")
        //     .matches(
        //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        //         "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character",
        //     ),
    });
