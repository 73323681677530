import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Card, Carousel, Spinner } from "react-bootstrap";

import { AppUserApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { FoodLogType } from "../../DTO";

export const FoodLog: FC<{ userId: number; currentDate: string }> = ({
    userId,
    currentDate,
}) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [foodLog, setFoodLog] = useState<FoodLogType[]>([]);

    const user = useAppSelector(selectUser);

    const fetchFoodLogList = () => {
        setLoader(true);

        AppUserApi.ListFoodLog(user, Number(userId), currentDate)
            .then((data) => {
                setFoodLog(data.food);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        if (currentDate.length > 0) {
            fetchFoodLogList();
        }
    }, [currentDate]);

    return (
        <>
            {loader ? (
                <div className="d-flex flex-row justify-content-center align-items-center p-5 w-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    <div className="d-flex flex-row flex-wrap">
                        {foodLog.length > 0 ? (
                            foodLog.map((item, index) => (
                                <div className="p-2 col-12 col-md-6 col-lg-4">
                                    <Card
                                        key={index}
                                        className={
                                            "h-100 position-relative bg-custom-white shadow rounded-4"
                                        }
                                    >
                                        <Card.Body
                                            className={
                                                "d-flex flex-column gap-2"
                                            }
                                        >
                                            <div className={"col-12"}>
                                                <Carousel>
                                                    {item.attachments &&
                                                    item.attachments.length >
                                                        0 ? (
                                                        item.attachments.map(
                                                            (mediaItem) => (
                                                                <Carousel.Item>
                                                                    <img
                                                                        className="d-block w-100 rounded-3"
                                                                        src={
                                                                            mediaItem.path
                                                                        }
                                                                        alt={
                                                                            mediaItem.attachableType
                                                                        }
                                                                        style={{
                                                                            objectFit:
                                                                                "cover",
                                                                            height: "300px",
                                                                        }}
                                                                    />
                                                                </Carousel.Item>
                                                            ),
                                                        )
                                                    ) : (
                                                        <Carousel.Item>
                                                            {/* <img
                                                className="d-block w-100 rounded-3"
                                                src={NotFoundImage}
                                                alt={'not-found'}
                                                style={{
                                                  objectFit: 'cover',
                                                  height: '300px',
                                                }}
                                              /> */}
                                                        </Carousel.Item>
                                                    )}
                                                </Carousel>
                                            </div>
                                            <div
                                                className={
                                                    "d-flex mt-2 flex-row flex-wrap col-12"
                                                }
                                            >
                                                <div className="d-flex flex-row gap-2 col-6">
                                                    <span>Type:</span>
                                                    <span>{item.type}</span>
                                                </div>
                                                <div className="d-flex flex-row gap-2 col-6">
                                                    <span>Duration Time:</span>
                                                    <span>
                                                        {item.duration_time} min
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-row gap-2 col-6">
                                                    <span>Healthiness:</span>
                                                    <span>
                                                        {item.healthiness}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-row gap-2 col-6">
                                                    <span>Portion:</span>
                                                    <span>
                                                        {String(item.portion)}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        ) : (
                            <strong className="text-danger">
                                Food Log is Empty!
                            </strong>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
