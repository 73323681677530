import { ExperimentAttachmentType, ExperimentType, userType } from "../../DTO";
import { Api } from "../api";

export const UserExperiment = (user: userType, userId: number) => {
    return new Promise<{ experiment: ExperimentType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `experiment`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                user_id: userId,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    experiment: convertToExperimentTypeList(data.data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const convertToExperimentTypeList = (data: any[]): ExperimentType[] => {
    return data.map((element) => convertToExperimentType(element));
};

export const convertToExperimentType = (data: any): ExperimentType => {
    let out: ExperimentType = {
        title: data?.title,
        attachments: convertExperimentAttachmentsTypeList(data?.attachments),
        id: data?.id,
    };

    return out;
};

export const convertExperimentAttachmentsTypeList = (
    data: any[],
): ExperimentAttachmentType[] => {
    return data.map((element) => convertExperimentAttachmentsType(element));
};

export const convertExperimentAttachmentsType = (
    data: any,
): ExperimentAttachmentType => {
    let out: ExperimentAttachmentType = {
        path: `${String(process.env.REACT_APP_API_IMAGE_URL)}${data?.path}`,
    };

    return out;
};
