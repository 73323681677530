import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { ProgressReportType } from "../../../DTO";
import {
    Loading,
    CustomBreadCrumb,
    UserDetail,
    SendNotification,
} from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    useAppNavigate,
    useAppParams,
    useAppQuery,
} from "../../../Router/hook";
import { AppUserApi } from "../../../api";

export const UserProgressOverview: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [progressOverView, setProgressOverview] =
        useState<ProgressReportType>();

    const queryString = useAppQuery();

    const timeQuery = queryString.get("period");

    const typeQuery = queryString.get("type");

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "User list", href: "/app-user" },
        { name: "Overview " + id, href: "#" },
    ];

    const fetchProgressReport = () => {
        if (typeQuery && timeQuery) {
            setLoading(true);

            AppUserApi.UserOverview(user, Number(id), typeQuery, timeQuery)
                .then((data) => {
                    setProgressOverview(data.overview);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: error,
                    });
                });
        }
    };

    useEffect(() => {
        fetchProgressReport();
    }, [typeQuery, timeQuery]);

    return (
        <>
            <Helmet>
                <title>D2Type | Progress Overview</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex flex-column bg-custom-white rounded p-3">
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                                <h4 className="text-nowrap">
                                    Progress OverView
                                </h4>
                                <div className="d-flex flex-row flex-wrap gap-3">
                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            timeQuery === "3m"
                                                ? "3 Month"
                                                : timeQuery === "6m"
                                                  ? "6 Month"
                                                  : timeQuery === "1y" &&
                                                    "1 Year"
                                        }
                                        variant="success"
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=${typeQuery}&period=3m
                                                                `,
                                                );
                                            }}
                                        >
                                            3 Month
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=${typeQuery}&period=6m
                                                                `,
                                                );
                                            }}
                                        >
                                            6 Month
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=${typeQuery}&period=1y
                                                                `,
                                                );
                                            }}
                                        >
                                            1 Year
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            typeQuery === "engagement_detail"
                                                ? "Engagement"
                                                : typeQuery === "sleep_detail"
                                                  ? "Sleep"
                                                  : typeQuery ===
                                                      "activity_detail"
                                                    ? "Activity"
                                                    : typeQuery ===
                                                        "food_detail"
                                                      ? "Food"
                                                      : typeQuery ===
                                                          "stress_detail"
                                                        ? "Stress"
                                                        : typeQuery ===
                                                            "weight_detail"
                                                          ? "Weight"
                                                          : typeQuery ===
                                                                "blood_detail" &&
                                                            "Blood"
                                        }
                                        variant="danger"
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=engagement_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Engagement
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=sleep_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Sleep
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=activity_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Activity
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=food_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Food
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=stress_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Stress
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=weight_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Weight
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(
                                                    `/app-user/${Number(
                                                        id,
                                                    )}/progress/overview?type=blood_detail&period=${timeQuery}
                                                                `,
                                                );
                                            }}
                                        >
                                            Blood
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    <SendNotification
                                        fetchData={fetchProgressReport}
                                        user={user}
                                        userId={Number(id)}
                                    />
                                </div>
                            </div>
                            <UserDetail pageId={Number(id)} />
                        </div>
                        {progressOverView && (
                            <div className="d-flex flex-row flex-wrap rounded px-3 w-100">
                                <div className={" w-100 my-2"}>
                                    {progressOverView.pages
                                        .filter(
                                            (reportItem) =>
                                                reportItem.body.length !== 0,
                                        )
                                        .map((reportItem, index) => (
                                            <>
                                                {reportItem.body.map(
                                                    (bodyItem) => (
                                                        <div className="d-flex flex-column gap-2 m-2">
                                                            <strong>
                                                                {bodyItem.title}
                                                            </strong>
                                                            <div className="p-4 text-center text-nowrap rounded p-3 bg-custom-white h-100">
                                                                <Table
                                                                    hover
                                                                    responsive
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                No.
                                                                            </th>
                                                                            <th>
                                                                                Label
                                                                            </th>
                                                                            <th>
                                                                                Value
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {bodyItem.items.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {index +
                                                                                            1}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.label
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.value
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ),
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    ),
                                                )}
                                            </>
                                        ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
