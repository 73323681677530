import { permissionsType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPermissionsGroupType } from "./list";

export const GetPermissionList = (user: userType) => {
    return new Promise<{ data: permissionsType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "permission",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToPermissionsGroupType(data.data),
                });
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
