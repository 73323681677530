import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import * as Screen from "../screens";
import { SiteLayout } from "../components";
import { useAppNavigate } from "./hook";
import { useAppSelector } from "../redux-config/hooks";
import { selectUser } from "../redux-config/entities/user";

export const RouterPage = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Screen.NotFound />}></Route>
                <Route path="/login" element={<Screen.Login />} />
                <Route path="" element={<Auth />}>
                    <Route
                        path=""
                        element={
                            <SiteLayout>
                                <Screen.Home />
                            </SiteLayout>
                        }
                    ></Route>
                    <Route path="admin-user">
                        <Route
                            path="list"
                            element={
                                <SiteLayout>
                                    <Screen.AdminUsersList />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="new"
                            element={
                                <SiteLayout>
                                    <Screen.NewAdmin />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="info/:id"
                            element={
                                <SiteLayout>
                                    <Screen.InfoAdmin />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="edit/:id"
                            element={
                                <SiteLayout>
                                    <Screen.EditAdmin />
                                </SiteLayout>
                            }
                        />
                    </Route>
                    <Route path="app-user">
                        <Route
                            path=""
                            element={
                                <SiteLayout>
                                    <Screen.AppUsersList />
                                </SiteLayout>
                            }
                        />

                        <Route
                            path="edit/:id"
                            element={
                                <SiteLayout>
                                    <Screen.EditAppUser />
                                </SiteLayout>
                            }
                        />
                        <Route path=":id/progress">
                            <Route
                                path="detail"
                                element={
                                    <SiteLayout>
                                        <Screen.UserProgressDetail />
                                    </SiteLayout>
                                }
                            />
                            <Route
                                path="sleep/week/:weekNumber"
                                element={
                                    <SiteLayout>
                                        <Screen.UserProgressSleep />
                                    </SiteLayout>
                                }
                            />
                            <Route
                                path="report"
                                element={
                                    <SiteLayout>
                                        <Screen.UserProgressReport />
                                    </SiteLayout>
                                }
                            />
                            <Route
                                path="overview"
                                element={
                                    <SiteLayout>
                                        <Screen.UserProgressOverview />
                                    </SiteLayout>
                                }
                            />
                        </Route>
                        <Route path="info/:id">
                            <Route
                                path=""
                                element={
                                    <SiteLayout>
                                        <Screen.InfoUser />
                                    </SiteLayout>
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="UserAccess">
                        <Route
                            path=""
                            element={
                                <SiteLayout>
                                    <Screen.UserAccessList />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="edit/:id"
                            element={
                                <SiteLayout>
                                    <Screen.EditRole />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="new"
                            element={
                                <SiteLayout>
                                    <Screen.NewRole />
                                </SiteLayout>
                            }
                        />
                    </Route>
                    <Route path="Message">
                        <Route
                            path=""
                            element={
                                <SiteLayout>
                                    <Screen.TicketList />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path=":id"
                            element={
                                <SiteLayout>
                                    <Screen.TicketID />
                                </SiteLayout>
                            }
                        />
                    </Route>
                    <Route path="recipe">
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.RecipeList />
                                </SiteLayout>
                            }
                            path="list"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.RecipeCreate />
                                </SiteLayout>
                            }
                            path="new"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.RecipeEdit />
                                </SiteLayout>
                            }
                            path="edit/:id"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.RecipeShow />
                                </SiteLayout>
                            }
                            path="info/:id"
                        />
                    </Route>
                    <Route path="peer-group">
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.PeerGroupList />
                                </SiteLayout>
                            }
                            path="list"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.PeerGroupShow />
                                </SiteLayout>
                            }
                            path="info/:id"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.PeerGroupEdit />
                                </SiteLayout>
                            }
                            path="edit/:id"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.PeerGroupCreate />
                                </SiteLayout>
                            }
                            path="new"
                        />
                        <Route path=":id">
                            <Route path="post">
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.PeerGroupPostCreate />
                                        </SiteLayout>
                                    }
                                    path="new"
                                />
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.PostShow />
                                        </SiteLayout>
                                    }
                                    path="info/:postId"
                                />
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.PeerGroupPostEdit />
                                        </SiteLayout>
                                    }
                                    path="edit/:postId"
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="lesson">
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.LessonList />
                                </SiteLayout>
                            }
                            path="list"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.LessonShow />
                                </SiteLayout>
                            }
                            path="info/:id"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.LessonEdit />
                                </SiteLayout>
                            }
                            path="edit/:id"
                        />
                        <Route
                            element={
                                <SiteLayout>
                                    <Screen.LessonCreate />
                                </SiteLayout>
                            }
                            path="new"
                        />
                        <Route path=":id">
                            <Route path="slide">
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.LessonSlideCreate />
                                        </SiteLayout>
                                    }
                                    path="new"
                                />
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.LessonSlideShow />
                                        </SiteLayout>
                                    }
                                    path="info/:slideId"
                                />
                                <Route
                                    element={
                                        <SiteLayout>
                                            <Screen.LessonSlideEdit />
                                        </SiteLayout>
                                    }
                                    path="edit/:slideId"
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const Auth = () => {
    const navigate = useAppNavigate();
    const token = useAppSelector(selectUser);

    React.useEffect(() => {
        if (
            token === undefined ||
            token?.access_token === undefined ||
            token?.access_token == ""
        ) {
            navigate("/login", { replace: false });
        }
    });
    return <Outlet />;
};
