import {
    faBars,
    faChartLine,
    faDoorClosed,
    faDoorOpen,
    faMessage,
    faMoon,
    faPeopleGroup,
    faPersonChalkboard,
    faReceipt,
    faSun,
    faUser,
    faUserCircle,
    faUserCog,
    faUserFriends,
    faUserTie,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import "./style.scss";
import {
    eraseCookie,
    useAppDispatch,
    useAppSelector,
} from "../../../redux-config/hooks";
import {
    selectDarkMode,
    setDarkMode,
} from "../../../redux-config/entities/dark-mode";
import { selectUser } from "../../../redux-config/entities/user";

import { SideBarPart } from "../../sideBarPart";
import "../../../config/bootstrap/custom.scss";

export const SiteLayout: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const dispatch = useAppDispatch();
    const darkMode = useAppSelector(selectDarkMode);
    const user = useAppSelector(selectUser);
    // const language = useAppSelector(selectLanguage)
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

    // const { i18n, t } = useTranslation('layout')

    // useEffect(() => {
    //   i18n.changeLanguage(language)
    // }, [])

    return (
        <div
            className="d-flex flex-column position-relative"
            style={{ height: "100vh" }}
            // dir={language == 'en' ? 'ltr' : 'rtl'}
        >
            <div className="d-flex flex-row w-100 shadow bg-third text-white p-2 justify-content-between align-items-center ">
                <div>
                    <strong>D2Type Admin</strong>
                    <label
                        htmlFor="sidebarCheck"
                        className="sidebarLeftArrow  "
                        style={{ cursor: "pointer", zIndex: 10 }}
                    >
                        <FontAwesomeIcon
                            className="mx-3 text-white"
                            icon={faBars}
                        />
                    </label>
                </div>
                <div className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-row gap-2">
                        <span>Role:</span>
                        <strong>
                            {user && user?.role && user?.role[0]?.name}
                        </strong>
                    </div>
                    <span
                        className="mx-3"
                        onClick={() => {
                            dispatch(setDarkMode(!darkMode));
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {darkMode ? (
                            <FontAwesomeIcon icon={faMoon} />
                        ) : (
                            <FontAwesomeIcon icon={faSun} />
                        )}
                    </span>

                    {/* <select
            onChange={(e) => {
              dispatch(setLanguage(e.target.value == '0' ? 'en' : 'ar'))
              window.location.reload()
            }}
            defaultValue={language == 'en' ? 0 : 1}
            className='p-1 rounded bg-custom-white text-custom-dark'
          >
            <option value={0}>{t('english')}</option>
            <option value={1}>{t('arabic')}</option>
          </select> */}
                </div>
            </div>
            <div
                className="d-flex flex-row h-100 overflow-hidden  border-top "
                data-bs-theme={darkMode ? "dark" : "light"}
            >
                <input
                    id={"sidebarCheck"}
                    type="checkbox"
                    className="sidebarCheck d-none"
                    onChange={() => {
                        setIsSideBarOpen(!isSideBarOpen);
                    }}
                ></input>
                <div
                    className="d-flex flex-column gap-2 bg-third pt-4 text-white sidebar h-100 pb-5 pb-md-1"
                    style={{ overflowY: "auto" }}
                >
                    <FontAwesomeIcon
                        className="mx-2 userImageSiteLayout"
                        size="3x"
                        icon={faUserCircle}
                    />
                    <strong className=" text-center userNameSiteLayout ">
                        {user?.first_name + " " + user?.last_name}
                    </strong>
                    <strong className=" text-center userNameSiteLayout ">
                        {user?.phone}
                    </strong>
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faChartLine,
                                title: "Dashboard",
                                link: "/",
                            },
                        }}
                    />
                    {/* <SideBarPart
            data={{
              isSideBarOpen: isSideBarOpen,
              children: [],
              main: { icon: faUser, title: 'Profile', link: '/Profile' },
            }}
          /> */}
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faUserFriends,
                                title: "Admin Users",
                                link: "/admin-user/list",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faUsers,
                                title: "App Users",
                                link: "/app-user",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faUserCog,
                                title: "Users access",
                                link: "/UserAccess",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faPeopleGroup,
                                title: "Peer Groups",
                                link: "/peer-group/list",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faPersonChalkboard,
                                title: "Lessons",
                                link: "/lesson/list",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faReceipt,
                                title: "Recipes",
                                link: "/recipe/list",
                            },
                        }}
                    />
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faMessage,
                                title: "Message",
                                link: "/Message",
                            },
                        }}
                    />
                    <div
                        onClick={() => {
                            Swal.fire({
                                text: "Are you sure you want to log out?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, log out!",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    eraseCookie("user");
                                    localStorage.clear();
                                    window.location.replace("/login");
                                }
                            });
                        }}
                        className="d-flex flex-row align-items-center sidePart p-2 exitPart"
                    >
                        <FontAwesomeIcon
                            icon={faDoorClosed}
                            className="mx-2 doorClose"
                        />
                        <FontAwesomeIcon
                            icon={faDoorOpen}
                            className="mx-2 doorOpen"
                        />
                        <span>Exit</span>
                    </div>
                </div>
                <div className="w-100 p-2 overflow-y-auto bg-custom-light position-relative">
                    {children}
                </div>
                <a
                    className="position-absolute bottom-0 m-1"
                    style={{ right: 0 }}
                    href="https://webnegah.com/"
                    target="_blank"
                >
                    <small>Developed by WEBNEGAH company</small>
                </a>
            </div>
        </div>
    );
};
