import { userType, RecipeType } from "../../DTO";
import { Api } from "../api";
import { convertToRecipeType } from "./create";

export const ShowRecipe = (user: userType, recipeId: number) => {
    return new Promise<{ recipe: RecipeType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `recipe/${recipeId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                resolve({
                    recipe: convertToRecipeType(data.data),
                });
            })
            .catch((err) => {
                reject(err.data.message);
            });
    });
};
