import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Card, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

export type SideBarType = {
    main: {
        title: string;
        icon: IconDefinition;
        link?: string;
    };
    children: { title: string; link: string }[];
    isSideBarOpen: boolean;
};

const CustomToggle: React.FC<{
    children: React.ReactNode;
    eventKey: string;
}> = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    return (
        <div className="sidePart w-100 p-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
};

export const SideBarPart: React.FC<{
    data: SideBarType;
}> = ({ data }) => {
    if (data.children.length > 0) {
        return (
            <>
                <div className="d-none d-md-flex flex-column ">
                    <div className="d-flex flex-row align-items-center ">
                        {data?.isSideBarOpen ? (
                            <Accordion className="w-100 g-cursor-pointer">
                                <CustomToggle eventKey={data.main.title}>
                                    <FontAwesomeIcon
                                        icon={data?.main?.icon}
                                        className="mx-2"
                                    />
                                    <span>{data.main.title}</span>
                                </CustomToggle>
                                <Accordion.Collapse eventKey={data.main.title}>
                                    <div className="d-flex flex-column w-100">
                                        {data?.isSideBarOpen &&
                                            data.children.map(
                                                (item, index: number) => (
                                                    <Link
                                                        to={item.link}
                                                        className="sidePart px-2 py-1"
                                                        key={index}
                                                    >
                                                        <span className="w-100 ps-3">
                                                            {item.title}
                                                        </span>
                                                    </Link>
                                                ),
                                            )}
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        ) : (
                            <NavDropdown
                                key={"end"}
                                id={`dropdown-button-drop-${"end"}`}
                                drop={"end"}
                                title={
                                    <FontAwesomeIcon
                                        icon={data?.main?.icon}
                                        style={{
                                            padding: "4px 17px 4px 17px",
                                            marginTop: "4px",
                                        }}
                                        className=" "
                                    />
                                }
                            >
                                {data.children.map((item, index: number) => (
                                    <NavDropdown.Item
                                        href={item.link}
                                        eventKey={index}
                                    >
                                        {item.title}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-column d-md-none ">
                    <Accordion className="w-100">
                        <CustomToggle eventKey={data.main.title}>
                            <FontAwesomeIcon
                                icon={data?.main?.icon}
                                className="mx-2"
                            />
                            <span>{data.main.title}</span>
                        </CustomToggle>
                        <Accordion.Collapse eventKey={data.main.title}>
                            <div className="d-flex flex-column w-100">
                                {data.children.map((item, index: number) => (
                                    <Link
                                        to={item.link}
                                        className="sidePart px-2 py-1"
                                        key={index}
                                    >
                                        <span className="w-100 ps-3">
                                            {item.title}
                                        </span>
                                    </Link>
                                ))}
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </>
        );
    } else {
        return (
            <Link to={data?.main?.link + ""}>
                <div className="d-flex flex-row align-items-center sidePart p-2">
                    <FontAwesomeIcon icon={data?.main?.icon} className="mx-2" />
                    <span>{data.main.title}</span>
                </div>
            </Link>
        );
    }
};
